.token_sec {

    .inner {
        position: relative;
        z-index: 1;
        height: 620px;

        &::after {
            content: "";
            position: absolute;
            bottom: 130px;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: url("../new_images/token_bg.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            z-index: -1;
            opacity: .7;

        }

        .info_about_it {
            text-align: center;
            padding-top: 140px;
            width: 70%;
            margin: auto;
            color: var(--txt-black);

            @media ($breakpoint_MD){
                width: 100%;
            }

            h1 {
                font-size: 64px;
                font-weight: 600;
                line-height: 68px;
                font-family: $font_heading;


                @media ($breakpoint_LG) {
                    font-size: 48px;
                }

                @media ($breakpoint_MD) {
                    font-size: 42px;
                    line-height: 40px;
                    margin-bottom: 20px;
                }

                @media ($breakpoint_SM) {
                    font-size: 34px;
                    line-height: 34px;
                }

                @media ($breakpoint_450) {
                    font-size: 28px;
                    line-height: 34px;
                }

                @media ($breakpoint_ESM) {
                    font-size: 24px;
                    line-height: 34px;
                }


                span {
                    color: transparent;
                    background: var(--txt-black);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            p {
                font-size: 18px;
                font-weight: 500;
                font-family: $font_pragph;


                @media ($breakpoint_450) {
                    font-size: 16px;
                    line-height: 34px;
                }

                @media ($breakpoint_ESM) {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }

    .main_sec_token {

        .forth-sec {
            padding: 50px 0;

            @media ($breakpoint_SM) {
                padding-top: 0 !important;
            }

            .inner-content {
                color: var(--txt-black);

                .for_driction {
                    @media ($breakpoint_MD) {
                        flex-direction: column-reverse;
                    }
                }

                p {
                    font-size: 14px;
                    font-weight: 300;
                    margin: 0;
                    color: var(--primary-color);

                    span {
                        margin-right: 8px;
                    }
                }

                h1 {
                    text-transform: capitalize;
                    padding-bottom: 10px;
                    font-weight: 700;
                    margin: 0;
                    font-family: $font_heading;
                }

                h5 {
                    font-family: $font_pragph;
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 30px;
                }

                .progress_bar {
                    margin-bottom: 20px;

                    .info {
                        margin-bottom: 8px;

                        h5 {
                            font-size: 14px;
                            font-weight: 300;
                        }

                        p {
                            font-size: 14px;
                            font-weight: 300;
                        }
                    }

                    .progress-bar {
                        height: 3px;
                        width: 100%;
                        position: relative;

                        overflow: visible;
                        background-color: #e8cc562e;

                        &::after {
                            position: absolute;
                            z-index: -1;
                            content: "";
                            height: 100%;
                            width: 100%;
                            background-color: #ed184424;
                            top: 0;
                            right: 0;
                        }

                        .progress {
                            // position: relative;
                            height: 100%;
                            background-color: var(--primary-color);
                            transition: width 0.5s ease-out;
                            border-radius: 0px 2px 2px 0px;
                            overflow: visible;
                        }

                        .glow {
                            width: 100%;
                            height: 100%;
                            position: relative;
                        }

                        .glow::after {
                            content: "";
                            width: 60px;
                            max-width: 100%;
                            height: 5px;
                            float: right;
                            position: absolute;
                            top: -1px;
                            right: 0;
                            box-shadow: 8px 3px 25px #ffe266;
                            background: linear-gradient(273deg, #fff0b1, transparent);
                            border-radius: 31px;

                        }


                    }

                    .label {
                        color: var(--txt-black);
                        text-shadow: 0px 0px 2px #cfd6dd;
                        font-size: 24px;
                    }

                    .fill-1 {
                        animation: fill-1 0.5s ease-out .5s;
                        animation-fill-mode: forwards;
                        width: 0%;
                    }

                    .fill-2 {
                        animation: fill-2 0.5s ease-out 1s;
                        animation-fill-mode: forwards;
                        width: 0%;
                    }

                    .fill-3 {
                        animation: fill-3 0.5s ease-out 1.5s;
                        animation-fill-mode: forwards;
                        width: 0%;
                    }

                    .fill-4 {
                        animation: fill-4 0.5s ease-out 2s;
                        animation-fill-mode: forwards;
                        width: 0%;
                    }

                    .fill-5 {
                        animation: fill-5 0.5s ease-out 2.5s;
                        animation-fill-mode: forwards;
                        width: 0%;
                    }

                    .fill-6 {
                        animation: fill-6 0.5s ease-out 3s;
                        animation-fill-mode: forwards;
                        width: 0%;
                    }

                    @keyframes fill-1 {
                        0% {
                            width: 0%;
                        }

                        100% {
                            width: 80.3%;
                        }
                    }

                    @keyframes fill-2 {
                        0% {
                            width: 0%;
                        }

                        100% {
                            width: 8%;
                        }
                    }

                    @keyframes fill-3 {
                        0% {
                            width: 0%;
                        }

                        100% {
                            width: 7%;
                        }
                    }

                    @keyframes fill-4 {
                        0% {
                            width: 0%;
                        }

                        100% {
                            width: 2%;
                        }
                    }

                    @keyframes fill-5 {
                        0% {
                            width: 0%;
                        }

                        100% {
                            width: 1.7%;
                        }
                    }

                    @keyframes fill-6 {
                        0% {
                            width: 0%;
                        }

                        100% {
                            width: 1%;
                        }
                    }
                }

                .right {
                    height: 500px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: radial-gradient(50% 50% at 50% 50%, #e8cc5647 0%, rgba(14, 234, 174, 0) 100%);

                    position: relative;
                    z-index: 1;

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        background-image: url("../new_images/token_logo_bg.png");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        z-index: -1;
                        opacity: .7;
                    }

                    @media ($breakpoint_450) {
                        height: 334px;
                    }

                    img {
                        width: 350px;
                        height: auto;

                        @media ($breakpoint_SM) {
                            width: 250px;
                        }
                    }
                }
            }


            .label.one {
                animation: label 0.5s ease-out 0s;
                animation-fill-mode: forwards;
                opacity: 0;
            }

            .label.two {
                animation: label 0.5s ease-out 0.5s;
                animation-fill-mode: forwards;
                opacity: 0;
            }

            .label.three {
                animation: label 0.5s ease-out 1.0s;
                animation-fill-mode: forwards;
                opacity: 0;
            }

            @keyframes label {
                0% {
                    opacity: 0;
                    transform: translateY(10px);
                }

                100% {
                    opacity: 0.9;
                    transform: translateY(0);
                }
            }
        }
    }
}