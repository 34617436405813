.Virtual_Assets_sec {
    padding-bottom: 70px;

    .inner {
        border-radius: var(--commonRadius);
        width: 90%;
        margin: auto;
        position: relative;
        z-index: 1;
        overflow: hidden;
        padding: 50px 100px;
        color: white;
        text-align: center;

        @media ($breakpoint_LG) {
            padding: 100px;
        }

        @media ($breakpoint_MD) {
            padding: 50px 25px;
        }

        @media ($breakpoint_ESM) {
            padding: 44px 10px;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: var(--zs-totiya);
            z-index: -1;
            opacity: .7;
        }

        h1 {
            font-size: 42px;
            font-family: $font_heading;
            font-weight: 700;

            @media ($breakpoint_MD) {
                font-size: 26px;
            }
        }

        .input_field {
            font-family: $font_heading;
            border-radius: 60px;
            background-color: #ffffff7d;
            border: 1px solid #ffffff66;
            backdrop-filter: blur(7px);
            width: 64%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 7px 5px 18px;
            margin: auto;
            margin-top: 36px;

            @media ($breakpoint_LG) {
                width: 100%;
            }


            input {
                padding: 8px 5px;
                font-size: 18px;
                background-color: transparent;
                border: none;
                color: var(--txt-black);
                width: 75%;
                font-size: 14px;

                @media ($breakpoint_SM) {
                    width: 66%;
                }

                @media ($breakpoint_450) {
                    width: 62%;
                }

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }

            a {
                padding: 9px 16px;
                background: var(--primary-color);
                border-radius: 25px;
                text-decoration: none;
                color: black;

                @media ($breakpoint_450) {
                    font-size: 12px;
                }

                @media ($breakpoint_ESM) {
                    padding: 4px 9px;
                }
            }
        }
    }
}