.__dash {
    margin-top: 10px;
    font-family: $font_heading;

    .dash-row {
        row-gap: 25px;

        @media ($breakpoint_SM) {
            row-gap: 15px;
        }
    }

    .welcome-card {
        background-color: var(--card);
        border: 1px solid var(--card-border);
        border-radius: var(--card-radius);
        color: var(--txt-white);
        padding: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        overflow: hidden;

        @media ($breakpoint_MD) {
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
        }

        h5 {
            font-size: 17px;
            margin-bottom: 0;
            white-space: nowrap;

            span {
                color: var(--primary-color);
            }

            i {
                margin-right: 10px;
                display: inline-block;
            }
        }

        .marquee {
            width: 100%;
            margin-bottom: 0;

            @media ($breakpoint_MD) {
                margin-top: 10px;
            }

            .marquuqqq {
                width: auto;

                p {
                    margin-bottom: 0;
                    font-size: 15px;
                    opacity: 0.8;
                }
            }
        }

    }

    .vartical__card {
        color: var(--txt-white);
        background: var(--card);
        border: 1px solid var(--card-border);
        border-radius: var(--card-radius);
        overflow: hidden;
        height: 100%;
        position: relative;
        // background: linear-gradient(to right, #3b8d99, #6b6b83, #e8cc569c) !important;
        background-repeat: no-repeat;
        padding: 20px;

        .__icon {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(20%, -50%);
            opacity: 0.1;

            svg {
                font-size: 100px;
                color: var(--primary-color);
            }

        }

        .vartical__img {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: var(--primary-color);

            h4 {
                font-size: 33px;
                margin-bottom: 0;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
            }

            span {
                font-size: 14px;
                align-self: flex-end;
                opacity: 0.8;
                margin-left: 3px;
            }
        }

        .card-body {
            padding: 0;

            h3 {
                font-size: 15px;
                margin-top: 10px;
                margin-bottom: 0;
                width: 100%;
                opacity: 0.7;
            }

            .btn {
                background-color: var(--primary-color);
                border-radius: var(--card-radius);
                border: 1px solid var(--card-border);
                transition: all 0.3s linear;
                font-size: 12px;
                padding: 7px 20px;


                &:hover {
                    background-color: transparent;
                    color: var(--txt-white);
                }
            }
        }


    }

    .account-card {
        background-color: var(--card);
        padding: 20px;
        border-radius: var(--card-radius);
        color: var(--txt-white);
        font-family: $font_heading;
        border: 1px solid var(--card-border);

        @media ($breakpoint_MD){
            padding: 20px 10px;
        }

        
        .__tablesec {
            padding: 15px;
            border: 0;
            background: var(--card);
            color: var(--txt-white);
            border-radius: var(--card-radius);

            @media ($breakpoint_SM){
                padding: 5px;
                margin-bottom: 20px;
            }

            .card-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 0 10px;
                background-color: transparent;
                margin-bottom: 20px;
                border-bottom: 1px solid #e1e5e940;


                p {
                    font-size: 18px;
                    width: 100%;
                    margin-bottom: 0;
                    text-transform: capitalize;
                }

                a {
                    text-decoration: none;
                    color: var(--primary-color);
                }
            }

            .card-body {

                .input--holder {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    align-items: flex-end;
                    justify-content: flex-start;
                    width: 100%;

                    @media ($breakpoint_LG) {
                        flex-direction: column;
                        align-items: flex-start;

                    }

                    label {
                        color: var(--primary-color);
                        display: block;
                        font-size: 15px;
                        font-weight: 500;
                        margin-bottom: .7142857143rem;
                        position: relative;
                        width: 100%;
                        z-index: 2;
                    }
                }

                .react-tabs {
                    width: 100%;


                    .react-tabs__tab-list {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        border-bottom: 0 !important;
                        gap: 20px;
                        margin-bottom: 20px;

                        @media ($breakpoint_SM){
                            gap: 5px;
                        }

                        .react-tabs__tab {
                            bottom: 0;
                            background-color: #25252c !important;
                            padding: 7px 20px;
                            background-color: transparent;
                            transition: all 0.1s linear;
                            border-radius: 20px !important;
                            font-size: 14px;
                            text-align: center;


                            &::after {
                                display: none;
                            }
                        }

                        .react-tabs__tab--selected {
                            background-color: var(--primary-color) !important;
                        }
                    }
                }


                .input--grid {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    border: 1px solid var(--border-color);
                    border-radius: 20px;
                    // padding-right: 5px;
                    overflow: hidden;

                    @media ($breakpoint_LG) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border: 1px solid var(--border-color);
                        padding: 5px;
                        border-radius: 20px;

                    }

                    .income_a {
                        padding: 10px;
                        justify-content: flex-start;
                        position: relative;
                        z-index: 1;
                        overflow: hidden;

                        .__timer-outer {
                            width: 100%;

                            h4 {
                                opacity: 0.5;
                                font-size: 14px;
                            }

                            .times_sec {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                gap: 20px;
                                font-size: 28px;


                                @media ($breakpoint_SM){
                                    font-size: 18px;
                                    gap: 5px;
                                }


                                .time-box {
                                    background-color: #27272c;
                                    padding: 10px 8px 8px;
                                    text-transform: uppercase;
                                    text-align: center;
                                    width: 100%;
                                    border-radius: 4px;

                                    @media ($breakpoint_SM){

                                    }

                                    span {
                                        display: block;
                                        font-size: 13px;

                                        @media ($breakpoint_SM){
                                            font-size: 12px;
                                            margin-top: 10px;
                                        
                                        }
                                    }
                                }


                            }
                        }

                      




                        h4 {
                            font-size: 17px;

                            @media ($breakpoint_SM){
                                font-size: 14px;
                            }
                        }

                        h3 {
                            font-size: 22px;
                            margin-bottom: 10px;
                        }

                        h4 {
                            margin-bottom: 10px;
                        }

                    }


                    .achived-section{
                        padding: 10px 14px;
                        // overflow: hidden;
                        position: relative;
                        z-index: 1;
                        width: 100%;
                        display: block;

                            
                        h2{
                            margin-bottom: 0;
                            font-size: 20px;
                            display: inline-flex;
                            align-items: center;
                            width: 100%;
                            justify-content: flex-start;
                            gap: 5px;
                            text-shadow: 0px 1px 20px var(--primary-color);

                            svg{
                                color: var(--primary-color);
                                font-size: 21px;
                            }
                            
                            
                            &::after{
                                content: "";
                                position: absolute;
                                top: -60px;
                                right: 0;
                                width: 60px;
                                height: 200px;
                                background-color: var(--primary-color);
                                z-index: -1;
                                transform: skew(312deg, 41deg);

                                @media ($breakpoint_SM){
                                    opacity: 0.9;
                                }
                            }

                            &::before{
                                content: "";
                                position: absolute;
                                top: -60px;
                                right: 110px;
                                width: 11px;
                                height: 200px;
                                background-color: var(--primary-color);
                                z-index: -1;
                                transform: skew(312deg, 41deg);


                                @media ($breakpoint_SM){
                                    opacity: 0.9;
                                }
                            }
                        }
                    }




                    .default-button {
                        padding: 6px 10px;
                        font-size: 14px;
                        color: black;

                        @media ($breakpoint_LG) {
                            margin-top: 0;
                            padding: 5px 20px;
                        }

                        canvas {
                            width: 170% !important;
                            height: 110% !important;
                            object-fit: contain;
                        }
                    }
                }

                .__qr_area {
                    canvas {
                        height: 148px !important;
                        object-fit: contain;
                        width: 150px !important;
                        background-color: #fff;
                        padding: 10px;


                        @media ($breakpoint_LG) {
                            height: 130px !important;
                            width: 137px !important;
                        }
                    }
                }

                .default-input {
                    width: 100%;
                    align-items: center;
                    display: flex;
                    font-weight: 300;
                    justify-content: space-between;
                    overflow: hidden;
                    position: relative;
                    background: transparent;
                    color: var(--txt-white);
                    font-weight: 500;
                    width: 100%;
                    font-size: 14px;
                    padding: 0 12px;
                    transition: .2s;
                    line-height: 100%;
                    height: 46px;


                    @media ($breakpoint_LG) {
                        border: 0 !important;

                    }


                    input {
                        background-color: transparent;
                        border: none;
                        width: 100%;
                        font-size: 14px;
                        color: var(--txt-white);
                    }
                }

                .default-button {
                    align-items: center;
                    border: none;
                    background-color: transparent;
                    border-radius: var(--card-radius);
                    color: white;
                    opacity: 0.7;
                    display: flex;
                    font-family: $font_heading;
                    font-size: 16px;
                    padding: 10px 30px;
                    position: relative;
                    text-decoration: none !important;
                    transition: all 0.3s linear;
                    white-space: nowrap;

                    svg {
                        color: var(--txt-white);
                    }

                    &:hover {
                        background-color: transparent;
                        border-color: var(--primary-color);
                        color: var(--primary-color);
                    }


                }


            }
        }

        .___card-info {
            padding: 20px 30px 10px;
            background: #ffffff14;
            color: var(--txt-white);
            border-radius: var(--card-radius);
            height: 100%;

            @media ($breakpoint_SM){
                padding: 15px;

            }

            .profile-sec {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 20px;
                margin-bottom: 5px;

                @media ($breakpoint_SM){
                    flex-direction: column;

                }

                .profile-img {
                    border-radius: var(--card-radius);
                    height: 70px;
                    background: var(--dashboard_bg);
                    min-width: 70px;
                    position: relative;
                    display: flex;
                    align-items: baseline;
                    justify-content: center;
                    padding: 10px;

                    @media ($breakpoint_ESM) {
                        width: 66px;
                        height: 66px;
                        min-width: auto;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }

                }

                .info_img {

                    h4 {
                        margin-bottom: 10px;
                        font-size: 18px;
                        overflow-wrap: break-word;
                        word-wrap: break-word;
                    }

                    .userName {
                        letter-spacing: 1px;
                    }

                    h5 {
                        margin-bottom: 10px;


                        span {
                            display: inline-block;
                            font-size: 13px;
                            font-weight: normal;
                            border-radius: 10px;
                            color: var(--txt-white);
                            width: max-content;
                        }
                    }

                }

                .copy-btn {
                    background-color: transparent;
                    border: 0;
                    box-shadow: unset;
                    color: var(--txt-white);
                    margin-left: 5px;

                    svg{
                        opacity: 0.8;
                        font-size: 15px;

                    }
                }
            }

            .__items {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;

                @media ($breakpoint_XL) {
                    flex-direction: column;
                    gap: 0;

                }


                .sposaner__name {
                    width: 100%;
                    background-color: #15151a !important;
                }
            }

            .sposaner__name {
                text-transform: capitalize;
                width: 100%;
                padding: 10px 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: transparent;
                border: 1px solid var(--card-border);
                border-radius: 15px;
                margin-top: 10px;


                p {
                    margin-bottom: 0;
                    opacity: 0.8;
                    font-size: 15px;
                }
            }

            .__btnd {
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                .btn {
                    background-color: var(--primary-color);
                    border-radius: var(--card-radius);
                    border: 1px solid var(--card-border);
                    transition: all 0.3s linear;
                    padding: 10px 30px;
                    transition: all 0.3s linear;
                    font-size: 14px;

                    &:hover {
                        background-color: transparent;
                        border-color: var(--primary-color);
                        color: var(--primary-color);
                    }
                }

            }
        }

    }




}

.main_inner_sec {


    @media ($breakpoint_MD) {
        .header-left {
            display: none;
        }

    }


    ._v1_row {
        row-gap: 15px;
        height: 100%;
        font-family: $font_heading;

        .__v1_card {
            border: 1px solid var(--card-border);
            background: var(--card);
            color: var(--txt-white);
            border-radius: var(--card-radius);
            padding: 20px 25px;
            overflow: hidden;
            position: relative;
            z-index: 1;
            height: 100%;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                background-image: url(https://html.phoenixcoded.net/light-able/bootstrap/assets/images/widget/img-status-7.svg);
                background-position: right;
                background-repeat: no-repeat;
                background-size: 40% 340%;
                opacity: 0.7;
                display: none;
            }

            .card-body {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0;
                flex: none !important;



                .__left_v1 {
                    display: flex;
                    flex-direction: column-reverse;

                    h5 {
                        opacity: 0.7;
                        font-size: 15px;
                        margin-bottom: 0;
                    }

                    h4 {
                        font-size: 22px;
                        color: var(--primary-color);
                        margin-bottom: 10px;
                    }
                }


                .right_v1 {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translate(14%, -50%);
                    opacity: 0.1;
                    margin-bottom: 0;

                    p {
                        margin-bottom: 0;
                    }

                    svg {
                        font-size: 90px;
                        color: var(--primary-color);
                    }

                }
            }

        }
    }





    .rank_royality {
        margin-top: 50px;
        padding-top: 40px;
        border-top: 1px solid #ffffff43;
        font-family: $font_heading;

        .rank_royality__row {
            row-gap: 20px;
        }

        .__v2_card {
            border: var(--card-border);
            background: var(--card);
            color: var(--txt-white);
            border-radius: var(--card-radius);
            padding: 20px;
            height: 100%;
            position: relative;
            z-index: 1;
            overflow: hidden;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url(https://html.phoenixcoded.net/light-able/bootstrap/assets/images/widget/img-status-9.svg);
                background-position: right;
                background-repeat: no-repeat;
                background-size: 40% 340%;
                z-index: -1;

            }

            .row {
                row-gap: 20px;
                // height: 100%;
            }

            .__v2_in_card {
                border: 1px solid #ffffff30;
                border-radius: var(--card-radius);
                padding: 10px 20px;
                background-color: var(--card);
                color: var(--txt-white);

                .card-body {
                    padding: 0;

                    svg {
                        font-size: 33px;
                        margin-bottom: 10px;
                    }
                }
            }

        }

    }



    .tk-trading__card {
        height: 100%;
        font-family: $font_heading;
        border: 1px solid transparent;
        background: var(--card);
        color: var(--txt-white);
        border-radius: var(--card-radius);
        padding: 20px;
        border: 1px solid var(--card-border);

        @media ($breakpoint_ESM) {
            padding: 22px 18px;
        }

        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 20px;
            background-color: transparent;
            margin-bottom: 20px;
            border-bottom: 0;
            border-radius: var(--card-radius);
            background-color: var(--card-in);


            p {
                font-size: 15px;
                overflow: hidden;
                margin-bottom: 0;
            }

            a {
                text-decoration: none;
                color: var(--primary-color);
            }
        }

        .table_responsive {
            height: 400px;
            overflow-y: scroll;

            @media ($breakpoint_LG) {
                height: auto;
                overflow: visible;
            }

            .all_tables {
                width: 100% !important;
            }

            thead {
                position: sticky;
                top: 0;
                left: 0;
            }

            th,
            td {
                &:nth-child(2) {
                    width: 90px;
                    padding: 10px 0;
                }
            }
        }

        .table_responsive::-webkit-scrollbar {
            width: 3px;
        }

        .table_responsive::-webkit-scrollbar-thumb {
            background-color: var(--primary-color);
            border-radius: 20px;
        }

    }
}


.default-block-stack {
    grid-gap: 1.5714285714rem;
    display: grid;
    gap: 1.5714285714rem;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 50px;

    @media ($breakpoint_MD) {
        display: flex;
        flex-direction: column;
    }

    .default-block {
        &:first-child {
            z-index: 2;
        }

        &:last-child {
            .default-block__bottom {
                border-radius: 0;
                margin-left: -2.8571428571rem;
                padding: 1.2142857143rem 2.8571428571rem 2.1428571429rem 5rem;
            }

            background: transparent;
            border-radius: 0;
            box-shadow: none;
            overflow: visible;
        }
    }

    .default-block__inset {
        padding: 22px 2.8571428571rem 0.7142857143rem 2.1428571429rem;

        @media ($breakpoint_LG) {
            padding: 22px 18px;
        }

        .chart___mad__card {
            font-family: $font_heading;
            border: none;
            background: transparent;
            color: var(--txt-white);

            @media ($breakpoint_LG) {
                margin-bottom: 20px;
            }

            .card-body {
                padding: 0;
            }

            .card-header {
                background-color: transparent;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                padding: 10px 15px;


                p {
                    font-size: 22px;
                    margin-bottom: 0;
                }
            }



            .graph__data {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                height: 392px;
                position: relative;
                z-index: 1;

                @media ($breakpoint_SM) {
                    width: 100%;
                    height: 300px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 50px;
                    left: 0;
                    width: 101%;
                    height: 5px;
                    background-color: var(--border-color);
                }

                .inner_data_values {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .item-sec {
                        height: 86%;
                        background-color: var(--txt-white);
                        border-radius: 10px 10px 0 0;
                        width: 60px;
                        padding: 10px;
                        position: relative;
                        z-index: 1;
                        overflow: hidden;

                        @media ($breakpoint_50) {
                            width: 100%;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 60%;
                            background-color: var(--primary-color);
                            z-index: -1;
                            border-radius: 10px 10px 0 0;

                        }


                        p {
                            color: black;
                            margin-bottom: 0;
                            text-align: center;
                            font-size: 14px;
                        }

                    }

                    .item-sec_down {

                        &::after {
                            height: 40% !important;
                        }

                    }

                    .item-sec_80 {
                        &::after {
                            height: 80% !important;
                        }
                    }

                    .bottom__label {
                        margin-top: 16px;
                        height: 10%;
                        text-align: center;
                        color: black;


                        h6 {
                            font-size: 14px;

                            @media ($breakpoint_MD) {
                                font-size: 13px;
                            }

                            @media ($breakpoint_SM) {
                                font-size: 12px;
                            }

                            span {
                                opacity: 0.8;

                                @media ($breakpoint_SM) {
                                    font-size: 10px;
                                }
                            }
                        }
                    }

                }
            }


        }
    }
}






.__inneCard98 {
    font-family: $font_heading;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    gap: 20px;

    ._smallCard {
        background-color: #fff;
        padding: 10px 15px;
        border-radius: 20px;
        width: 100%;
        height: 100%;
        box-shadow: var(--kt_card-shadow);
    }

    p {
        margin-bottom: 0;
    }

    a {
        text-decoration: none;
        color: var(--primary-color);
        display: flex;
        align-items: center;
        font-size: 16px;
        justify-content: space-between;

        img {
            margin-right: 10px;
            width: 35px;
        }
    }
}




.qr_scan_modal {
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    padding: 20px !important;
    background-color: var(--sidebar-bg) !important;

    .scan_modal {
        width: 200px;

        border-radius: 5px;
    }

    .react-responsive-modal-closeButton {
        display: none;
    }
}

.homepage_modal {
    border-radius: 10px;
    border: 1px solid var(--border-color) !important;
    box-shadow: var(--box-shadow) !important;
    background: var(--sidebar-bg) !important;

    .banner_img {
        width: 100%;
        border-radius: 10px;
    }

    .react-responsive-modal-closeButton {
        display: none;
    }


}

.welcome__alert {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    width: 100%;
    z-index: 99;
    max-width: 700px;
    margin: auto;
    transition: all 0.4s linear !important;
    font-family: $font_heading;

    .alert-heading {
        font-size: 20px;
    }

    p {
        font-size: 15px;
    }
}

.fade.welcome__alert.alert.alert-primary.alert-dismissible.show {
    transform: translate(-50%, -15%) !important;

}