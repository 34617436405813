.ticket_view {
     margin-top: 20px;


     .__ticket-card {
          margin-bottom: 30px;

          .__ticket {
               padding: 15px 20px;
               overflow: hidden;
               border-radius: var(--card-radius);
               border: 1px solid var(--card-border);
               font-family: "Titillium Web", sans-serif;
               background: var(--card);
               color: var(--txt-white);
               position: relative;
               z-index: 1;

               .__heading{
                    margin-bottom: 15px;
                    font-size: 17px;
                    opacity: 0.6;
               }

               h5{
                    background-color: #2b2b2b;
                    padding: 6px 10px;
                    border-radius: var(--card-radius);
                    color: var(--primary-color);
                    font-size: 18px;

               }
          }
     }


     .ticket_inner {
          padding: 30px 20px;
          overflow: hidden;
          border-radius: var(--card-radius);
          border: 1px solid var(--card-border);
          font-family: $font_heading;
          background: var(--card);
          color: var(--txt-white);

          h4 {
               background: var(--light-blue);
               padding: 15px;
               border-radius: 10px;
               color: var(--primary-color);
               margin: 0;
          }

          @include table_styles;
     }
}

.image_modal {
     width: 400px;
     border-radius: 10px;
     border: 1px solid var(--sky-blue);
     background: var(--sidebar-bg) !important;

     img {
          width: 100%;
          max-width: 400px;
     }

     @media ($breakpoint_SM) {
          width: auto;
     }

     .react-responsive-modal-closeButton {
          background: var(--light-blue);
          border-radius: 10px;
          padding: 3px 5px;

          &:focus {
               outline: none;
          }

          svg {
               fill: var(--primary-color);
          }

     }
}