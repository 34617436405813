.login_card {
    width: 75%;
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 10px;
    border-radius: 25px;
    background: #e8cc561a;
    margin-bottom: 80px;
    backdrop-filter: blur(18px);

    .welcome_note {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .card-img {
            width: 100%;
            display: flex;
            justify-content: center;

            img {
                max-width: 200px;
                animation: animate 2s infinite;
            }

            @keyframes animate {
                0% {
                    transform: translateY(0);
                }

                50% {
                    transform: translateY(-20px);
                }

                100% {
                    transform: translateY(0);
                }
            }
        }

        h2 {
            font-size: 57px;
            font-weight: 600;
        }
    }

    .login_form {
        width: 45%;
        padding: 65px 25px;

        .card-body {
            text-align: start;
            color: white;
            text-align: center;

            h5 {
                font-size: 22px;
                font-weight: 600;
                margin-left: 16px;
            }

            p {
                font-size: 14px;
                margin-left: 16px;
            }

            .Input_area {

                input:not([type="checkbox"]) {
                    background-color: transparent;
                    margin-bottom: 15px;
                    width: 100%;
                    border: 1px solid #e8cc5638 !important;
                    border-radius: var(--authradius) !important;
                    box-shadow: unset;
                    padding: 10px 15px;
                    outline: none;
                    font-size: 14px;
                    color: white !important;
                }

                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    color: white !important;
                    -webkit-text-fill-color: white !important;
                    -webkit-box-shadow: 0 0 0 1000px var(--autofill_bg) inset !important;
                    -webkit-background-clip: text !important;
                    background-clip: text !important;
                }

                .for_responsive {

                    @media ($breakpoint_SM) {
                        width: 91% !important;
                        margin: auto;
                        padding: 0px 20px 0px 60px !important;
                        margin-bottom: 6px !important;
                    }

                    @media ($breakpoint_450) {
                        padding: 0px 20px 0px 70px !important;
                    }


                }

                .flag-dropdown {
                    @media ($breakpoint_SM) {
                        left: 23px !important;
                    }
                }

                .hide-pass {
                    position: relative;
                    z-index: 1;
                    width: 100%;

                    .input_pass {
                        padding-right: 43px;
                    }

                    input {
                        width: 100%;
                    }

                    .btn__sc {
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        transform: translate(2%, -70%);
                        padding: 6px;
                        box-shadow: unset !important;
                        outline: none;
                        border: 0 !important;
                        font-size: 7px;
                        background-color: transparent;
                        color: #8c8c8c;

                        svg {
                            font-size: 18px;
                        }

                    }

                }

                .login-remfor {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 22px;

                    p {
                        margin: 0;
                    }

                    a {
                        text-decoration: none;
                        color: var(--primary-color);
                    }
                }

                .btn__s {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;

                    @media ($breakpoint_SM) {
                        flex-direction: column-reverse;
                        gap: 20px;
                    }


                    .log-btn {
                        margin: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        text-align: center;
                        padding: 11px 20px;
                        background-color: #e8cc56;
                        color: #000000;
                        border: 1px solid transparent;
                        font-size: 14px;
                        font-weight: 600;
                        border-radius: 30px;
                        position: relative;
                        z-index: 1;
                        overflow: hidden;
                        transition: all 0.4s linear;
                        text-transform: capitalize;
                        white-space: nowrap;
                        margin-top: 22px;

                        .btnText {
                            display: block;
                            padding: 0 50px;
                        }

                        &:hover,
                        &:focus {
                            color: var(--primary-color);
                            box-shadow: unset;
                            background-color: transparent;
                            border-color: var(--primary-color);
                        }

                    }


                }

                .form-start-flex {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 10px;

                    @media ($breakpoint_SM) {
                        flex-direction: column;
                        justify-content: flex-start;
                        width: 100%;
                    }
                }

                .react-tel-input {
                    width: 110%;

                    .form-control {
                        &:focus {
                            border: 1px solid var(--primary-color) !important;
                        }
                    }


                    .flag-dropdown {
                        border: 0 !important;
                        border-right: 1px solid var(--border-color) !important;
                        background-color: transparent !important;
                    }

                    .selected-flag {
                        background-color: transparent !important;
                    }

                    .country-list {
                        width: 300px !important;
                        border-radius: 10px !important;
                        padding: 0 10px 10px !important;
                        background-color: #19170a !important;
                        border: 1px solid #ebeaea5e !important;

                        .search-class {
                            display: flex;
                            align-items: stretch;
                            gap: 10px;
                            justify-content: space-between;
                            background-color: #19170a !important;

                            padding: 10px;
                            border-bottom: 1px solid var(--border-color);
                            margin-bottom: 4px;

                            input {
                                all: unset !important;
                                width: 100% !important;
                                background-color: transparent !important;
                                font-size: 14px !important;
                                text-transform: capitalize !important;
                                cursor: text !important;

                                &::placeholder {
                                    text-transform: capitalize !important;
                                }


                            }

                        }


                        @media ($breakpoint_SM) {
                            width: 300px !important;
                        }

                        .country {
                            margin: 3px 0;

                            &:hover {
                                background-color: #e8cc561c;
                                border-radius: 6px;
                            }

                            .highlight {
                                background-color: #e8cc561c;
                                border-radius: 6px;
                            }
                        }
                    }
                }


                .css-b62m3t-container {
                    margin-bottom: 15px;
                }

                .css-t3ipsp-control:focus {
                    box-sizing: 0 !important;
                    border-color: var(--border-color) !important;
                    background-color: transparent;
                }

                .css-166bipr-Input,
                .css-qbdosj-Input {
                    input {
                        margin-bottom: 0 !important;
                        border: 0 !important;

                    }

                }

                .css-13cymwt-control {
                    padding: 4px 10px;
                    z-index: 1;
                    background-color: transparent;
                    border: 1px solid #ebeaea5e !important;
                    border-radius: var(--authradius) !important;
                    font-family: $font_heading;
                    outline: none !important;
                    box-shadow: unset !important;
                    color: white;

                }

                .css-166bipr-Input,
                .css-1jqq78o-placeholder,
                .css-qbdosj-Input,
                .css-1dimb5e-singleValue {
                    color: white !important;
                }

                .css-qbdosj-Input {
                    input {
                        box-shadow: unset !important;
                    }
                }

                .css-1n6sfyn-MenuList {
                    padding: 10px 10px !important;
                }

                .css-t3ipsp-control {
                    padding: 4px 10px;
                    border-radius: var(--authradius) !important;
                    background-color: transparent;
                    border: 1px solid #ebeaea5e !important;
                    z-index: 11;
                    outline: none !important;
                    box-shadow: unset !important;
                }

                .css-1nmdiq5-menu {
                    border: 1px solid var(--primary-color) !important;
                    border-radius: 6px !important;
                    background-color: #19170a !important;
                    z-index: 11 !important;
                    outline: none !important;
                    box-shadow: unset !important;
                    padding: 0 5px !important;
                }

                .css-fnd29f-MenuList,
                .css-1n6sfyn-MenuList {
                    &>div {
                        transition: all 0.3s linear;
                        cursor: pointer;
                        padding: 0 10px;

                        &:hover {
                            background-color: rgba(209, 209, 209, 0.591);
                        }
                    }
                }

                .css-1u9des2-indicatorSeparator {
                    display: none !important;
                }


            }
        }

        // h4 {
        //     font-size: 30px;
        //     font-weight: 600;
        // }

        // p {
        //     margin-bottom: 25px;
        // }

        // .input_button {
        //     width: 100%;
        //     border-style: solid;
        //     border-radius: 33px;
        //     padding: 5px 10px;
        //     display: flex;
        //     justify-content: space-between;
        //     gap: 5px;
        //     border-color: var(--primary-color);

        //     input {
        //         border: none;
        //         background: transparent;
        //         text-decoration: none;
        //         width: 100%;
        //         margin-left: 11px;
        //         color: white;
        //         outline: 0;
        //     }

        //     button {
        //         background-color: var(--primary-color);
        //         border: none;
        //         border-radius: 19px;
        //         padding: 8px 22px;
        //         font-weight: 600;
        //         color: white;
        //     }
        // }
    }
}

.register_card {
    width: 75%;
    background-color: #e8cc56f0;
    border-radius: 21px;
    padding: 24px;
    display: flex;
    align-items: center;

    .register_text {
        width: 100%;

        h3 {
            font-size: 37px;
            font-weight: 700;
            margin-bottom: 21px;
            ;
        }

        p {
            font-weight: 600;
            margin-bottom: 34px;
        }

        .link_button {
            border: none;
            background: black;
            color: white;
            padding: 10px 18px;
            border-radius: 31px;
            text-decoration: none;
        }
    }

    .card-img {
        position: relative;
        width: 20%;

        &::after {
            background-color: #00000052;
            border-radius: 50%;
            bottom: -18px;
            filter: blur(7px);
            height: 20px;
            position: absolute;
            right: 31px;
            width: 148px;
            z-index: 11;
            content: "";

        }

        img {
            max-width: 130px;
            animation: animate 1.5s infinite;

        }

        @keyframes animate {
            0% {
                transform: translateY(0);
            }

            50% {
                transform: translateY(-20px);
            }

            100% {
                transform: translateY(0);
            }
        }




    }

}

.heading {
    opacity: 1;
    text-align: center;
    color: white;

    h5 {
        font-size: 41px;
        margin-bottom: 16px;
    }

    .heading_paragraph {
        margin-bottom: 35px;
    }

    .activity_col {
        display: flex;
        width: 75%;
        margin: auto;
        gap: 30px;

        .col_1 {
            text-align: start;
            width: 50%;
            background-color: black;
            padding: 20px;
            border-radius: 18px;
            border-style: solid;
            border-color: #ffffff42;

            .inner_div {
                h4 {
                    font-size: 24px;
                    color: transparent;
                    background: var(--text_color);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 600;
                    font-family: $font_heading;
                }

                p {
                    svg {
                        color: #2cff4e;
                    }
                }
            }
        }

    }
}