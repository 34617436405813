.radmap_page {
    background: black;
}

.roadmap_header {
    text-align: center;
    padding: 130px 0px;
    position: relative;
    height: 500px;
    z-index: 1;

    &::after {
        content: "";
        position: absolute;
        bottom: 130px;
        right: 0;
        width: 100%;
        height: 100%;
        background-image: url(../new_images/ecological-restoration-roadmap.png);
        background-repeat: no-repeat;
        background-position: 0% 49%;
        background-size: cover;
        z-index: -1;
        opacity: 0.9;
    }

    @media ($breakpoint_MD) {
        padding: 100px 0px;
    }

    h1 {
        font-size: 60px;
        font-family: $font_heading;
        font-weight: 600;
        color: var(--txt-white);

        @media ($breakpoint_SM) {
            font-size: 40px;
        }
    }
}

.faq_section {
    background: black;
    padding: 0px 15px;
    padding-bottom: 100px;

    .faq_inner_div {
        width: 70%;
        margin: auto;

        @media ($breakpoint_LG) {
            width: 100%;

        }

        h3 {
            color: white;
            margin-bottom: 40px;
            font-size: 35px;
            font-family: $font_heading;
            text-align: center;
        }
    }
}

.ans_para {
    font-family: $font_pragph;
}