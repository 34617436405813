.MMX_sec {
    .inner {

        .heading {
            text-align: center;
            color: white;

            h1 {
                font-size: 78px;
                font-weight: 600;
                line-height: 68px;
                font-family: $font_heading;

                @media ($breakpoint_LG) {
                    font-size: 48px;
                }

                @media ($breakpoint_MD) {
                    font-size: 42px;
                    line-height: 40px;
                    margin-bottom: 20px;
                }

                @media ($breakpoint_SM) {
                    font-size: 34px;
                    line-height: 34px;
                }

                @media ($breakpoint_450) {
                    font-size: 28px;
                    line-height: 34px;
                }

                @media ($breakpoint_ESM) {
                    font-size: 24px;
                    line-height: 34px;
                }

                span {
                    color: transparent;
                    background: var(--text_color);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            p {
                font-size: 18px;
                font-weight: 500;
                font-family: $font_pragph;

                @media ($breakpoint_450) {
                    font-size: 16px;
                    line-height: 34px;
                }

                @media ($breakpoint_ESM) {
                    font-size: 14px;
                    line-height: 24px;
                }
            }

        }

        .features {
            color: white;
            margin-top: 20px;

            .inner_info {
                margin-bottom: 26px;

                @media ($breakpoint_MD) {
                    padding-left: 24px;
                }

                h3 {
                    font-size: 18px;
                    font-weight: 800;
                    position: relative;
                    font-family: $font_pragph;

                    svg {
                        position: absolute;
                        top: 0;
                        left: -28px;
                        color: var(--primary-color);
                        font-size: 22px;
                    }
                }

                p {
                    font-size: 13px;
                    font-weight: 300;
                    font-family: $font_pragph;

                    span {
                        font-weight: 600;
                        font-size: 15px;
                        border-bottom: 1px solid #e8cc56a3;
                    }
                }
            }
        }

        .feature_chart {
            height: 100%;
            width: 100%;
            background: radial-gradient(50% 50% at 50% 50%, #e8cc5647 0%, rgba(14, 234, 174, 0) 100%);

            .left {
                height: 400px;
                width: 100%;
                position: relative;
                z-index: 1;

                @media ($breakpoint_SM) {
                    height: 340px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 50%;
                    right: 50%;
                    transform: translate(50%, 50%);
                    width: 330px;
                    height: 330px;
                    background-image: url("../new_images/logo_3d.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    z-index: -1;

                    @media ($breakpoint_SM) {
                        width: 296px;
                        height: 296px;
                    }

                    @media ($breakpoint_450) {
                        width: 100%;
                        height: 100%;
                    }
                }

            }
        }
    }
}