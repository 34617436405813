.deposit-section {

  margin-top: 22px;
  border-radius: 10px;
  position: relative;
  z-index: 1;

  .Main__row{
    row-gap: 20px;
  }

  .card_heading__df {
    border: none;
    overflow: hidden;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $font_heading;
    border: var(--dashboard_border);
    box-shadow: var(--dashboard_shadow);
    background: var(--dashboard_bg);
    color: var(--txt-black);
    border-radius: var(--dashboard_border_radius);



    p {
        margin-bottom: 0;
        font-size: 22px;
        color: var(--txt-black);

        span{
          color: var(--primary-color);
        }

        @media($breakpoint_SM){
            font-size: 16px;
        }

        svg {
            margin-right: 10px;
        }
    }

    .btn{
      text-decoration: none;
      color: var(--txt-white);
      background-color: var(--primary-color);
      padding: 10px 20px;
      border-radius: 30px;
      display: flex;
      gap: 10px;
    }
}

  .deposit__card {
    width: 100%;
    padding: 30px;
    font-family: $font_heading;
    border: var(--dashboard_border);
    box-shadow: var(--dashboard_shadow);
    background: var(--dashboard_bg);
    color: var(--txt-black);
    border-radius: var(--dashboard_border_radius);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;

    @media ($breakpoint_MD){
      flex-direction: column;
    }


    .__left__qr{
      margin-right: 30px;
      text-align: center;

      @media ($breakpoint_MD){
        margin-right: 0;
        width: 100%;
      }

      .qr-code{
        height: 200px !important;
        width: 200px !important;
      }

    }

    h5 {
      margin-bottom: 5px;
    }

    .sub__heading_dkjs {
      font-size: 14px;
      opacity: 0.7;
    }

    .scanner-pic {

      .scanner-pic__datss {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;

        .qr-code {
          height: 150px !important;
          width: 150px !important;
          background: var(--txt-white);
          border-radius: 10px;
          box-shadow: var(--box-shadow);
          padding: 10px;
        }

        .qr__address_as {
          margin-left: 10px;
          margin-bottom: 0;
          color: var(--primary-color);
        }


      }


      .link-copy {
        display: flex;
        align-items: center;
        margin: 4px 0 0;
        background: #f4f5fb;
        border: 1px solid var(--border-color);
        padding: 4px 10px;
        border-radius: 10px;
        margin-left: 10px;




        input {
          font-size: 14px;
          margin-bottom: 0px;
          border: 0 !important;
          padding: 10px;
          width: 100%;
          color: var(--txt-white);
          background-color: transparent;

          @media (max-width: 576px) {
            width: 100%;
          }
        }

        .icon-copy {
          padding: 5px 11px;
          border-left: none;
          cursor: pointer;

          svg {
            font-size: 32px;
            color: var(--txt-white);
          }
        }
      }
    }

    .copybtn {
      display: inline-block;
      cursor: pointer;
      color: var(--txt-white);
      border-radius: 0;
    }

    .deposit-links {
      display: block;

      a {
        display: inline-block;
      }
    }

  }

  .deposit_table__card {
    width: 100%;
    padding: 25px 15px;
    height: 100%;
    font-family: $font_heading;
    border: var(--dashboard_border);
    box-shadow: var(--dashboard_shadow);
    background: var(--dashboard_bg);
    color: var(--txt-black);
    border-radius: var(--dashboard_border_radius);



  }

}