.Markets_sec {
    padding: 80px 0;
    font-family: $font_heading;

    .inner {


        .heading {
            text-align: center;
            color: white;

            h1 {
                font-size: 78px;
                font-weight: 600;
                line-height: 68px;
                font-family: $font_heading;

                @media ($breakpoint_LG) {
                    font-size: 48px;
                }

                @media ($breakpoint_MD) {
                    font-size: 42px;
                    line-height: 40px;
                    margin-bottom: 20px;
                }

                @media ($breakpoint_SM) {
                    font-size: 34px;
                    line-height: 34px;
                }

                @media ($breakpoint_450) {
                    font-size: 28px;
                    line-height: 34px;
                }

                @media ($breakpoint_ESM) {
                    font-size: 24px;
                    line-height: 34px;
                }

                span {
                    color: transparent;
                    background: var(--text_color);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

            }

            p {
                font-size: 18px;
                font-weight: 500;
                font-family: $font_pragph;

                @media ($breakpoint_450) {
                    font-size: 16px;
                    line-height: 34px;
                }

                @media ($breakpoint_ESM) {
                    font-size: 14px;
                    line-height: 24px;
                }
            }

        }

        .table_sec {
            width: 100%;
            margin: auto;
            text-align: center;

            .inner_info {
                margin-top: 20px;

                .inner_table {
                    .row_table {
                        display: flex;
                        align-self: center;
                        justify-content: space-between;
                        padding: 24px 16px;
                        background-color: black;
                        color: white;
                        border-radius: var(--commonRadius);
                        margin-bottom: 18px;

                        .info_coin {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 6px;

                            img {
                                height: 40px;
                                width: auto;
                            }

                            .name {
                                // display: flex;
                                // align-items: center;
                                // justify-content: flex-start;
                                // flex-direction: column;


                                h3 {
                                    font-size: 16px;
                                    font-weight: 500;
                                    margin: 0;
                                    text-align: start;
                                }

                                p {
                                    font-size: 14px;
                                    font-weight: 200;
                                    margin: 0;
                                }
                            }
                        }

                        .price_coin {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            p {
                                margin: 0;
                                font-size: 17px;
                                font-weight: 300;

                                span {
                                    font-size: 10px;
                                    font-weight: 300;
                                }
                            }
                        }

                        .rating_coin {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            p {
                                margin: 0;
                                font-size: 16px;
                                font-weight: 300;
                            }

                            .green {
                                color: rgb(4, 165, 68);
                            }

                            .red {
                                color: rgb(236, 69, 111);
                            }
                        }

                        .trade_coin {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .trade_button {
                                border: 1px solid var(--primary-color);
                                background-color: transparent;
                                padding: 9px 26px;
                                font-size: 12px;
                                color: var(--primary-color);
                                border-radius: 30px;
                                text-decoration: none;

                                svg {
                                    font-size: 18px;
                                }
                            }
                        }

                    }

                    .all_market {
                        .trade_button {
                            border: 1px solid var(--primary-color);
                            background-color: transparent;
                            padding: 9px 26px;
                            font-size: 12px;
                            color: var(--primary-color);
                            border-radius: 30px;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}