.main-overview {
    margin-top: 20px;

    .row {
        row-gap: 20px;
    }

    .overview-card{
        padding: 20px;
        overflow: hidden;
        background-color: var(--card);
        border-radius: var(--card-radius);
        border: 1px solid var(--card-border);
        color: var(--txt-white);
        font-family: $font_heading;
        height: 100%;

        ul{
            padding: 0;
            list-style-type: none;
            margin: 0;

            li{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 15px;
                background-color: var(--card-in);
                border-radius: var(--card-radius);
                margin-bottom: 13px;

                &:last-child{
                    margin-bottom: 0 !important ;
                }

                .txt{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 10px;

                    p{
                        margin-bottom: 0;

                        svg{
                            color: var(--primary-color);
                        }
                    }

                    h5{
                        font-size: 16px;
                        margin-bottom: 0;
                    }
                }

                h4{
                    margin-bottom: 0;   
                    font-size: 16px;
                }

            }
        }
    }


}