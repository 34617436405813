// @import 'bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap/scss/bootstrap';
@import './_theme.scss'; // Only css variable ( color acc useing__ )...
@import './Common.scss'; // Common scss ( breakpoints , mixin, common button , common list, common Heading size and much more )
@import './sidebar.scss';
@import './profile1.scss';
@import './deposit.scss';
@import './trading.scss';
@import './team.scss';
@import './overview.scss';
@import './login.scss';
@import './header.scss';
@import './footer.scss';
@import './dashboard.scss';
@import './buyinfra.scss';
@import './unilevel.scss';
@import './presentation.scss';
@import './support.scss';
@import './ticket.scss';
@import './payoutrequest.scss';
@import './modal.scss';


// ? Please Login and signUp more page ( New create new scss file then import_)


// including Home's header and footer scss
@import './index.scss';
@import './banner.scss';
@import './Investments.scss';
@import './Crypto_Assets.scss';
@import './smart_trading.scss';
@import './market_sec.scss';
@import './mmx.scss';
@import './Edge.scss';
@import './Virtual_Assets_sec.scss';
@import './token.scss';




@import './main-overview.scss';


@import './about.scss';


@import './VerificationLayout.scss';


@import './allrepor.scss';

@import './raodmap.scss';

@import './logincard.scss';


@import './SocialMedia.scss';

@import './BinaryTree.scss';

@import './uploadFile.scss';



.bg-chart-card {
    background-color: var(--sidebar-bg) !important;
}

.chart_style {
    svg {
        rect {
            background-color: transparent !important;
            fill: transparent !important;
        }

        g {

            text {
                fill: var(--txt-black) !important;
            }
        }
    }
}

.top_section_unilevel {
    display: flex;
    align-items: center;
    justify-content: space-between;


    .form-left-overview {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    .form-right-overview {
        background-color: var(--sky-blue);
        padding: 10px 20px;
        border-radius: 10px;
        color: var(--txt-black);

        p {
            margin-bottom: 0;
        }
    }
}


.table_data_description {
    width: 300px;
    text-wrap: wrap;
}

.icons_im {
    .scan_modal__indf {
        width: 40px !important;
        height: 40px !important;
        border: 1px solid #fff;
        border-radius: 4px;
    }
}