.buy_section {
    margin-top: 10px;


    .buy_top_sec {
        display: flex;
        justify-content: space-between;
        background: var(--light-blue);
        padding: 10px;
        align-items: center;
        border-radius: 10px;

        @media ($breakpoint_450) {
            display: block;
        }

        .inner_data {
            display: flex;
            // gap: 20px;
            align-items: center;

            .border_right {

                border-right: 1px solid var(--primary-color);
            }
        }

        p {
            margin: 0px;
            background: transparent;
            padding: 8px;
            border-radius: 0px;
            // border-right: 1px solid var(--primary-color);
            // box-shadow: var(--box-shadow);
            color: var(--txt-black);

            @media ($breakpoint_450) {
                margin-top: 15px;
            }

            span {
                color: var(--sky-blue);
            }
        }

        h4 {
            margin: 0px;
            color: var(--primary-color);
        }

        .top_btn {
            display: flex;
            gap: 10px;

            @media ($breakpoint_450) {
                margin-top: 10px;
            }
        }
    }

    .card_heading__df {
        overflow: hidden;
        padding: 15px 20px;
        font-family: $font_heading;
        border: 1px solid var(--card-border);
        background: var(--card);
        color: var(--txt-white);
        border-radius: var(--card-radius);

        p {
            margin-bottom: 0;
            font-size: 20px;
            color: var(--primary-color);

            @media($breakpoint_SM) {
                font-size: 16px;
            }

            svg {
                margin-right: 10px;
            }
        }
    }


    .christmas_card {
        overflow: hidden;
        padding: 15px 20px;
        font-family: $font_heading;
        border: 1px solid var(--card-border);
        background: var(--card);
        color: var(--txt-white);
        border-radius: var(--card-radius);

        .card__header {
            margin-bottom: 20px;
            width: 70px;
            height: 70px;

            img {
                width: 100%;
            }
        }

        .card-body {
            position: relative;
            z-index: 1;
            padding: 0;

            .star {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 15px 0 10px;

                svg {
                    font-size: 55px;
                    color: gold;
                }
            }

            .advancedPackage__star {
                opacity: 0.5;
            }

            .premiumPackage_star {
                opacity: 0.7;
            }

            .professoinalPackage_star {
                opacity: 1;
            }

            h4 {
                text-align: center;
                color: var(--txt-black);
                margin: 20px 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .valeues__a {
                text-align: center;

                p {
                    color: var(--primary-color);
                    font-size: 22px;

                    sub {
                        color: var(--txt-black);
                        font-size: 14px;
                    }
                }
            }

            .card_bottom_Sec {
                padding: 15px;
                border-radius: var(--card-radius);
                background-color: var(--card-in);

                .select_amount {


                    label {
                        color: var(--txt-white);
                        opacity: 0.6;
                    }

                    input,
                    select {
                        width: 100%;
                        border: 1px solid var(--border-color) !important;
                        height: auto;
                        padding: 11px 10px;
                        border-radius: var(--card-radius);
                        color: var(--txt-white);
                        font-size: 14px;
                        background-color: transparent;
                        box-shadow: unset !important;
                        outline: 0;



                        &::placeholder {
                            text-transform: capitalize;
                            color: var(--txt-white);
                            opacity: 0.7;
                        }


                        &:focus {
                            outline: none;
                            box-shadow: unset !important;
                        }
                    }

                    .text-success {
                        text-align: left;
                        font-size: 12px;
                        display: block;
                    }
                }

            }

            .btn {
                border-radius: 20px;
            }


        }
    }

}

.buy_modal {
    padding: 20px !important;
    max-width: 500px;
    border: none;
    box-shadow: var(--box-shadow);
    border-radius: 16px;
    background: var(--sidebar-bg) !important;

    .modal_data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--light-blue);
        padding: 10px;
        border-radius: 10px;
        margin: 0px;
        color: var(--txt-black);
    }

    .load_btn {
        text-decoration: none;
    }

    .need {
        color: var(--sky-blue);
    }

    p {
        color: var(--txt-black);
    }

    .check_input {
        margin-top: 20px;

        label {
            color: var(--txt-light);
        }



        input {
            margin-right: 10px;


        }
    }
}