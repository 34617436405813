

.List__bottom_bar{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 100px;
    background-color: #ddd;
    display: none;


    @media ($breakpoint_MD){
        padding: 0 15px;
    }

    @media (max-width:320px){
        padding: 0 10px;
    }


    ul{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 !important;
        margin: 0 !important;
        font-family: $font_heading;
        white-space: nowrap;
        flex-wrap: nowrap;

       

        .nav-item{

            .nav-link{
                display: inline-block;
                text-transform: capitalize;
                padding: 10px 20px;
                color: #000;

                @media ($breakpoint_SM){
                    font-size: 12px;
                    padding: 10px 0;
                }

                @media (max-width:320px){
                    font-size: 11px;
                    padding: 10px 0;
                }
            }


        }

    }
}



.data_as {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    background-image: url(https://storage.googleapis.com/theme-vessel-items/checking-sites/oddo-2-html/HTML/main/assets/img/img-31.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media ($breakpoint_MD){
        height: 100%;
        display: inline-block;
        padding: 0;
        // background-image: none;
    }

    .data___outer {
        max-width: 700px;
        width: 100%;
        margin: auto;
        text-align: center;
        font-family: "Titillium Web", sans-serif;

            @media ($breakpoint_MD){
                padding: 20px;
            }

        h2 {
            font-family: "Titillium Web", sans-serif;
            margin-bottom: 20px;
            font-size: 25px;
            font-weight: 600;

            @media ($breakpoint_ESM){
                font-size: 7vw;
            }
        }

        p {
            font-family: "DM Sans", sans-serif;
        }

        h4{
            margin-bottom: 20px;
            span{
                color: var(--primary-color);
                letter-spacing: 5px;
                margin-left: 10px;
            }
        }

        .name___user,
        .tq__sec{
            font-weight: 600;
        }

        .data__email_img{
            margin-bottom: 20px;
        }

        .form__ssd {
            background-color: #fff;
            box-shadow: 0px 0px 10px 0px #7771714d;
            border-radius: 10px;
            padding: 20px 40px 40px;
            max-width: 600px;
            margin: 0 auto;
            width: 100%;

            @media ($breakpoint_MD){
                // background-color: transparent;
                // box-shadow: none;
                padding: 20px;
            }

        }

        .input__passcode {
            display: flex;
            align-items: center;
            background-color: #f0f2f6;
            justify-content: space-between;
            border: 1px solid #e4e5e7;
            border-radius: 10px;
            overflow: hidden;


            input{
                background-color: transparent;
                border-color: transparent;
            }

            .otp__bttons-sx {
                outline: none;
                border: 0;
                background-color: transparent;
                padding: 10px;
            }

        }

        .form-label {
            text-align: left;
            display: flex;
            padding-left: 10px;
        }

        .btn__submit {
            display: inline-block;
            padding: 10px 20px;
            border: 1px solid var(--primary-color);
            margin-top: 20px;
            transition: all 0.4s linear;

            .timer_sx {
                letter-spacing: 5px;
                margin-left: 10px;
            }

            &:hover {
                background-color: var(--primary-color);
                color: var(--txt-white);


            }
        }

    }


}



.phone__view{
    background-color: #fff;
}


.successful__page {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    position: relative;
    z-index: 1;
    overflow: hidden;

    @media ($breakpoint_MD){
        height: 100%;
        display: inline-block;
        
        padding: 0;
    }

    &::after{
        content: "";
        position: absolute;
        top: -30%;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../homepage/hero-bg.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;

    }

    canvas{
        width: 100% !important;
        height: 100vh !important;
    }



    .data___outer {
        max-width: 700px;
        width: 100%;
        margin: auto;
        text-align: center;
        font-family: "Titillium Web", sans-serif;

            @media ($breakpoint_MD){
                padding: 20px;
            }

        h2 {
            font-family: "Titillium Web", sans-serif;
            margin-bottom: 20px;
            font-size: 25px;
            font-weight: 600;

            @media ($breakpoint_ESM){
                font-size: 7vw;
            }
        }

        p {
            font-family: "DM Sans", sans-serif;
        }

        h4{
            margin-bottom: 20px;
            span{
                color: var(--primary-color);
                letter-spacing: 5px;
                margin-left: 10px;
            }
        }

        .name___user,
        .tq__sec{
            font-weight: 600;
        }

        .data__email_img{
            margin-bottom: 20px;
        }

        .form__ssd {
            background-color: #fff;
            box-shadow: 0px 0px 10px 0px #7771714d;
            border-radius: 10px;
            padding: 20px 40px 40px;
            max-width: 600px;
            margin: 0 auto;
            width: 100%;
            position: relative;
            z-index: 4;

            @media ($breakpoint_MD){
                // background-color: transparent;
                // box-shadow: none;
                padding: 20px;
            }

        }

        .input__passcode {
            display: flex;
            align-items: center;
            background-color: #f0f2f6;
            justify-content: space-between;
            border: 1px solid #e4e5e7;
            border-radius: 10px;
            overflow: hidden;


            input{
                background-color: transparent;
                border-color: transparent;
            }

            .otp__bttons-sx {
                outline: none;
                border: 0;
                background-color: transparent;
                padding: 10px;
            }

        }

        .form-label {
            text-align: left;
            display: flex;
            padding-left: 10px;
        }

        .btn__submit {
            display: inline-block;
            padding: 10px 20px;
            border: 1px solid var(--primary-color);
            margin-top: 20px;
            transition: all 0.4s linear;

            .timer_sx {
                letter-spacing: 5px;
                margin-left: 10px;
            }

            &:hover {
                background-color: var(--primary-color);
                color: var(--txt-white);


            }
        }

    }

    .__userData{
    

        ul{
            padding: 10px 100px;
            margin: 0;
            list-style-type: none;
            font-family: $font_heading;
           
            @media ($breakpoint_MD){
                padding: 10px 0;
            }
    
            li{
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: left;
                gap: 10px;
                border-top: 1px solid var(--border-color);
                p{
                    width: 100%;
                    margin-bottom: 0;
                    font-size: 14px;
                    padding: 10px 0;
    
                    &:last-child{
                        color: var(--primary-color2);
                    }
                }
            }
        }
    
        .__btns{
            margin-top: 10px;
    
            .btn{
                background-color: var(--primary-color2);
                border: 1px solid transparent;
                border-radius: 20px;
                padding: 10px 30px;
                font-family: $font_heading;
                color: var(--txt-white);
                font-size: 14px;
                transition: all 0.4s linear;
    
                &:hover,&:focus{
                    color: var(--primary-color2);
                    background-color: transparent;
                    border-color: currentColor;
                }
            }
        }
    }

}
