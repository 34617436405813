.Home_header {
     padding: 10px;
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     background-color: transparent;
     z-index: 99;

     .Home_header_cs {
          max-width: 1200px;
          margin: auto;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 100%;

          .Menu__bar_icon {
               padding: 10px;
               background-color: transparent;
               color: #000;
               border: none;
               padding: 10px 20px;

               svg {
                    font-size: 30px;
               }
          }
     }
}

.offcanvas-header {
     .offcanvas-title {
          font-size: 18px;
          font-family: $font_heading;
          font-weight: 600;
     }
}

.ul__as {
     .nav {
          li {
               a {
                    display: block;
                    width: 100%;
                    background-color: #dddddd5f;
                    border-radius: 10px;
                    margin-bottom: 10px;
                    color: #000;
                    font-size: 14px;
                    font-family: "Lato", sans-serif;
                    padding: 10px 15px;
                    text-transform: capitalize;
               }
          }
     }
}

.about-sx {
     color: var(--txt-black);

     .banner_s {
          // background: url(https://orinostudio.com/rakon-theme/assets/img/inner/about.jpg);
          height: 400px;
          width: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-family: "Lato", sans-serif;

          &::after {
               content: "";
               position: absolute;
               top: 0;
               left: 0;
               width: 100%;
               height: 100%;
               background-color: rgb(0 0 0 / 63%);
               z-index: -1;
          }

          .banner_s_innner {
               max-width: 800px;
               width: 100%;
               margin: auto;

               @media ($breakpoint_MD) {
                    padding: 0;
               }
          }

          h4 {
               font-size: 33px;
               margin-bottom: 10px;
               font-family: $font_heading;
               font-weight: 600;
          }

          p {
               font-family: $font_pragph;
               font-size: 15px;
               margin-top: 20px;
          }

          .btn {
               background-color: var(--primary-color);
               padding: 10px 20px;
               color: #fff;
               border: 1px solid #dddddda9;
               transition: all 0.4s linear;
               display: inline-block;
               transition: all 0.4s linear;

               &:hover {
                    background-color: transparent;
               }
          }
     }

     .about-sx_card {
          width: 100%;
          padding: 100px 0;
          text-align: center;

          .heading-sec {
               margin-bottom: 80px;

               h3 {
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 2.5vw;
                    font-family: $font_heading;
                    margin-bottom: 20px;

                    @media ($breakpoint_MD) {
                         font-size: 40px;
                    }
               }

               p {
                    font-family: $font_pragph;
                    max-width: 730px;
                    margin: auto;
                    width: 100%;
               }
          }

          .row {
               align-items: stretch;
               display: flex;

               @media ($breakpoint_LG) {
                    gap: 15px;
               }
          }

          .sec {
               position: relative;
               padding: 20px;
               height: 100%;
               border-radius: 30px;
               height: 100%;
               background: transparent;
               box-shadow: 0px 0px 10px 0px var(--zs-totiya);

               img {
                    width: 100px;
                    margin: 10px auto 30px;
               }

               .stap-sec {
                    background-color: var(--zs-totiya);
                    box-shadow: 0px 0px 10px 0px #eae8e84d;
                    border-radius: 10px;
                    padding: 10px;
                    height: 60px;
                    width: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto 30px;
                    color: #fff;
                    font-size: 28px;
               }

               h2 {
                    font-size: 22px;
                    margin-bottom: 10px;
                    font-family: $font_heading;
                    font-weight: 600;
               }

               p {
                    font-family: $font_pragph;
                    font-size: 15px;
                    margin-top: 20px;
               }

               .glowing-div {
                    position: absolute;
                    bottom: 0px;
                    right: 0;
                    width: 35%;
                    height: 35%;
                    border-radius: 50%;
                    background: radial-gradient(circle,
                              #e8ca5463 0%,
                              rgba(255, 0, 0, 0) 60%);
               }
          }
     }

     .about__sx_last {
          background-color: rgb(159 148 119 / 9%) !important;
          padding: 100px 0 0;
     }
}

.Privacy__sc {
     .section-banner {
          height: 500px !important;
     }

     .LIst__sx {
          max-width: 1000px;
          width: 100%;
          margin: auto;
          padding: 50px 15px;

          .LIst__sx__ul {
               list-style-type: none;

               @media ($breakpoint_MD) {
                    padding: 0;
               }

               &>li {
                    margin-bottom: 24px;
               }
          }

          .Outer_heading {
               text-align: center;
               margin-bottom: 15px;
               font-size: 33px;
               font-family: $font_heading;
               font-weight: 600;
          }

          .main_heading {
               font-weight: 600;
               font-size: 22px;
               font-family: $font_heading;
          }

          .sub_heanding_sec {
               margin: 10px 0;

               h5 {
                    font-weight: 600;
                    font-size: 22px;
                    font-family: $font_heading;
               }

               p {
                    font-family: "Lato", sans-serif;
               }
          }

          .ul_inner_column {
               li {
                    font-family: "Lato", sans-serif;
                    margin-bottom: 10px;

                    a {
                         color: var(--primary-color);
                    }
               }
          }
     }
}

.contact_us {
     .contact__banner {
          background-color: #f8fafc;
          height: 400px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          @media ($breakpoint_MD) {
               padding: 0 20px;
          }

          h1 {
               font-size: 55px;
               margin-bottom: 10px;
               font-family: $font_heading;
               font-weight: 600;
               margin-bottom: 40px;
          }

          .banner___links {
               a {
                    display: inline-block;
                    color: var(--primary-color);
                    margin-right: 10px;
                    text-decoration: none;
                    font-family: $font_pragph;
                    font-size: 15px;
               }

               p {
                    font-family: $font_pragph;
                    font-size: 15px;
                    display: inline-block;
               }
          }
     }

     .contact__about {
          padding: 100px 0;

          @media ($breakpoint_MD) {
               padding: 100px 20px;
          }

          .container-banner {
               max-width: 1000px;
               width: 100%;
               margin: auto;
          }

          .card__img {
               img {
                    width: 100%;
                    height: 100%;

                    @media ($breakpoint_MD) {
                         width: 80%;
                    }
               }
          }

          .card__txt {
               padding-left: 50px;

               @media ($breakpoint_MD) {
                    padding-left: 0;
                    margin-top: 20px;
               }

               h3 {
                    font-size: 33px;
                    margin-bottom: 10px;
                    font-family: $font_heading;
                    font-weight: 600;
                    margin-bottom: 40px;
               }

               p {
                    font-family: $font_pragph;
                    font-size: 18px;
                    display: inline-block;
               }

               .btn {
                    background-color: var(--primary-color);
                    padding: 8px 25px;
                    display: inline-block;
                    margin-top: 20px;
                    color: #fff;
                    border: 1px solid transparent;
                    transition: all 0.4s linear;

                    &:hover {
                         color: var(--primary-color);
                         border-color: currentColor;
                         background-color: transparent;
                    }
               }
          }
     }

     .form__sec {
          background-color: #fff;
          padding: 100px 0;

          @media ($breakpoint_MD) {
               padding: 100px 20px;
          }

          .card__map {
               height: 100%;

               iframe {
                    width: 100% !important;
                    height: 100% !important;
               }
          }

          .form__sec__sds {
               max-width: 1200px;
               width: 100%;
               margin: auto;
          }

          h3 {
               font-size: 33px;
               margin-bottom: 20px;
               font-family: $font_heading;
               font-weight: 600;
               margin-bottom: 40px;
               text-align: center;
          }

          .form__card_a {
               padding: 20px;
               margin-left: 20px;

               @media ($breakpoint_MD) {
                    margin-left: 0;
                    margin-top: 20px;
               }

               .form-label {
                    padding-left: 20px;
               }

               .form-control {
                    padding: 10px 20px;
                    color: var(--primary-color);
                    border-radius: 30px;

                    &:focus,
                    &:hover {
                         border-color: var(--primary-color);
                    }
               }

               .btn__scsss {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 20px 0 0;

                    .subMIt__btn {
                         display: inline-block;
                         margin: auto;
                         padding: 10px 40px !important;
                         color: #fff;
                         background-color: var(--primary-color);
                    }
               }
          }
     }
}

.about-header-section {
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 110px 0 160px 0;
     color: var(--txt-black);
     position: relative;
     z-index: 1;

     &::after {
          content: "";
          position: absolute;
          top: -130px;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to bottom, var(--zs-totiya), #fff 75%);
          z-index: -1;
     }

     .about-head {
          max-width: 1000px;
     }

     p {
          font-size: 30px;
          line-height: 40px;
          margin-bottom: 10px;
          font-family: $font_pragph;
          animation: About-Para 1s linear;
          position: relative;
     }

     @keyframes About-Para {
          from {
               right: 70px;
          }

          to {
               right: 0px;
          }
     }

     h1 {
          font-size: 5.5rem;
          line-height: 80px;
          font-family: $font_heading;
          font-weight: 600;
          animation: About-Heading 1s linear;
          position: relative;

          span {
               color: #e8ca54e4;
          }
     }

     @keyframes About-Heading {
          from {
               left: 70px;
          }

          to {
               left: 0px;
          }
     }

     @media ($breakpoint_XL) {
          padding: 110px 20px 160px 20px;

          p {
               font-size: 30px;
               line-height: 40px;
               margin-bottom: 10px;
          }

          h1 {
               font-size: 4.5rem;
          }
     }

     @media ($breakpoint_MD) {
          padding: 90px 20px 140px 20px;

          .about-head {
               text-align: center;
          }

          p {
               font-size: 26px;
               line-height: 40px;
               margin-bottom: 22px;
          }

          h1 {
               font-size: 3.5rem;
               line-height: 60px;
          }
     }

     @media ($breakpoint_SM) {
          padding: 73px 20px 94px 20px;

          p {
               font-size: 20px;
               line-height: 32px;
          }

          h1 {
               font-size: 2.5rem;
               line-height: 50px;
          }
     }

     @media ($breakpoint_50) {
          padding: 35px 10px;

          p {
               font-size: 15px;
               line-height: 20px;
               margin-bottom: 20px;
          }

          h1 {
               font-size: 1.5rem;
               line-height: 30px;
          }
     }
}

.about-information {
     position: relative;
     background: linear-gradient(to bottom, #fff , var(--zs-totiya), #fff 100%);


     &::after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          // background-image: url(../new_images/smart_trading.png);
          background-color: #21d75b40;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          z-index: -1;
          opacity: 0.5;
     }

     .about_section {
          padding: 100px 0;
          display: flex;
          justify-content: center;
          justify-items: center;
          align-items: center;
          margin: auto;
          gap: 40px;
          width: 85%;

          @media ($breakpoint_LG) {
               width: 100%;
               padding: 100px 20px;
          }

          @media ($breakpoint_MD) {
               width: 100%;
               padding: 60px 15px;
               flex-direction: column;
          }

          h3 {
               margin-bottom: 10px;
               width: 60%;
               font-family: $font_heading;
               position: relative;
               animation: Anime-heading 1s linear;
               font-size: 40px;
          }

          @keyframes Anime-heading {
               from {
                    left: 100px;
               }

               to {
                    left: 0px;
               }
          }

          p {
               font-family: $font_pragph;
               position: relative;
               animation: Anime-heading 1s linear;
          }

          img {
               width: 350px;

               @media ($breakpoint_LG) {
                    width: 200px;
               }
          }
     }
}

.vision_section {
     padding: 100px 0;
     margin: auto;

     .vision_content {
          width: 80%;
          margin: auto;
          text-align: center;

          @media ($breakpoint_XL) {
               width: 90%;
          }

          @media ($breakpoint_SM) {
               width: 100%;
               margin: 0;
          }
     }

     h3 {
          margin-bottom: 40px;
          font-size: 35px;
          font-weight: 600;
          font-family: $font_heading;
     }

     .vision_topics {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;

          .vision_section_content {
               background: #ffffffe4;
               border-radius: 20px;
               display: flex;
               align-items: center;
               text-align: start;
               padding: 25px 25px;
               box-shadow: 0px 0px 10px 0px #100f0f1b;
               transition: all 0.3s ease-in-out;
               opacity: 0;
               transform: scale(0.5, 0.5);
               position: relative;
               overflow: hidden;
               transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

               &.animate {
                    opacity: 1;
                    transform: scale(1, 1);
               }

               &:hover img {
                    transform: translate(-20px, -20px);
               }

               .text_sec {
                    width: 70%;
               }

               img {
                    position: absolute;
                    margin-bottom: 20px;
                    width: 180px;
                    height: 180px;
                    bottom: -57px;
                    right: -9%;
                    transition: all 0.3s ease-in-out;
               }

               h4 {
                    color: black;
                    font-size: 22px;
                    font-family: $font_heading;
                    font-weight: 600;
               }

               p {
                    color: black;
                    font-family: $font_pragph;
               }
          }

          @media ($breakpoint_MD) {
               grid-template-columns: 1fr;
          }

          @media ($breakpoint_SM) {
               grid-template-columns: 1fr;
          }
     }

     @media ($breakpoint_SM) {
          padding: 40px 15px;
     }
}

.signup_section {
     background: black;
     padding: 100px 0;
     width: 100%;
     align-content: center;
     position: relative;
     z-index: 1;

     &::after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background-image: url(../new_images/background-singup.jpg);
          background-repeat: no-repeat;
          background-position: 0% 75%;
          background-size: cover;
          z-index: -1;
          opacity: 0.7;
     }

     @keyframes fadeInUp {
          from {
               opacity: 0;
               transform: translateY(20px);
          }

          to {
               opacity: 1;
               transform: translateY(0);
          }
     }

     div {
          text-align: center;

          h4 {
               font-size: 2.6rem;
               margin-bottom: 20px;
               font-family: $font_heading;
               opacity: 0;
               animation: fadeInUp 1s forwards;
               animation-delay: 0.5s;
               color: white;

               @media ($breakpoint_LG) {
                    font-size: 2rem;
               }

               @media ($breakpoint_LG) {
                    font-size: 1.5rem;
               }
          }

          p {
               font-size: 22px;
               margin-bottom: 30px;
               font-family: $font_pragph;
               opacity: 0;
               animation: fadeInUp 1s forwards;
               animation-delay: 0.8s;
               color: white;

               @media ($breakpoint_LG) {
                    font-size: 18px;
               }
          }

          a,
          button {
               display: inline-block;
               padding: 11px 39px;
               background-color: var(--primary-color);
               border: 1px solid transparent;
               text-decoration: none;
               border-radius: var(--commonRadius);
               color: var(--txt-white);
               font-size: 18px;
               transition: all 0.3s linear;
               opacity: 0;
               animation: fadeInUp 1s forwards;
               animation-delay: 1.3s;

               &:hover {
                    background-color: transparent;
                    border-color: var(--primary-color);
                    color: var(--primary-color);
               }
          }
     }

     @media ($breakpoint_LG) {
          padding: 100px 15px;
     }

     @media ($breakpoint_SM) {
          padding: 60px 15px;
     }
}

.roadmap_section {
     text-align: center;
     // padding: 100px 0px;
     background: white;

     @media ($breakpoint_MD) {
          padding: 60px 0px;
     }

     .heading_raodmap {
          margin-bottom: 52px;

          h3 {
               font-family: $font_heading;
               font-size: 45px;
               font-weight: 600;
               color: #029330;
          }
     }

     .roadmap_elem {
          max-width: 700px;
          margin: auto;
          padding: 0 16px;
     }
}

.roadmap_contant_section {
     display: flex;
     gap: 10px;
     margin: auto;
     margin-bottom: 40px;

     .about_rodmap_right {
          width: 40%;
          background: #21d75b17;
          border-radius: 10px;
          color: black;
          box-shadow: 0 0 8px 0rgb 114, 111, 111;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: end;
          padding-right: 8px;

          @media ($breakpoint_SM) {
               width: 100%;
          }

          // &::after {
          //   content: "";
          //   position: absolute;
          //   bottom: 0;
          //   right: -2%;
          //   width: 25px;
          //   top: 39%;
          //   height: 25px;
          //   z-index: 0;
          //   background: #21d75bbd;
          //   rotate: 45deg;
          // }

          h3 {
               margin: 0;
               font-size: 21px;
               font-weight: 700;
               margin-bottom: 1px;
          }

          h4 {
               font-size: 19px;
               position: relative;
               z-index: 1;
               font-weight: 600;
               font-family: $font_heading;
               margin: 0;
               margin-bottom: 2px;
          }

          p {
               font-family: $font_pragph;
               line-height: 18px;
               margin: 0;
          }
     }

     .about_rodmap_left {
          width: 40%;
          background: #21d75b17;
          border-radius: 10px;
          padding: 3px 6px;
          color: black;
          box-shadow: 0 0 8px 0rgb 114, 111, 111;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: start;
          padding-left: 8px;

          @media ($breakpoint_SM) {
               width: 100%;
          }

          // &::before {
          //   content: "";
          //   position: absolute;
          //   bottom: 0;
          //   left: -2%;
          //   width: 25px;
          //   top: 39%;
          //   height: 25px;
          //   z-index: 0;
          //   background: #21d75bbd;
          //   rotate: 45deg;
          // }

          h3 {
               margin: 0;
               font-size: 21px;
               font-weight: 700;
               margin-bottom: 1px;
          }

          h4 {
               font-size: 19px;
               position: relative;
               z-index: 1;
               font-weight: 600;
               font-family: $font_heading;
               margin: 0;
               margin-bottom: 2px;
          }

          p {
               font-family: $font_pragph;
               line-height: 18px;
               margin: 0;
          }
     }

     .icon_div {
          .about_rodmap_div {
               width: 110px;
               height: 110px;
               border-radius: 60px;
               background: #029330;
               border-style: solid;
               border-width: 7px;
               border-color: white;
               padding: 15px;
               box-shadow: inset 0 0 15px 0 #029330;
               position: relative;
               z-index: 1;

               p {
                    font-size: 43px;
                    color: white;
               }
          }

          .center_div {
               background: #21d75b40;
               height: 58px;
               width: 45px;
               margin-top: -10px;
               z-index: 0;
               position: absolute;
               margin-left: 31px;

               @media ($breakpoint_SM) {
                    display: none;
               }

               &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 76%;
                    width: 40px;
                    top: 2%;
                    height: 56px;
                    z-index: -1;
                    background: white;
                    border-radius: 53%;
               }

               &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    right: 76%;
                    width: 40px;
                    top: 2%;
                    height: 56px;
                    z-index: -1;
                    background: white;
                    border-radius: 53%;
               }
          }
     }
}

.blank_div {
     width: 40%;

     @media ($breakpoint_SM) {
          display: none;
     }
}

.line_div {
     height: 3px;
     width: 100%;
     background-color: #029330;
     margin: 3px;
     position: relative;
     z-index: 1;
     border-radius: 10px;
}

.comingsoon {
     text-align: start;
     margin: auto;
     color: white;
     text-align: center;
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: center;

     p {
          margin: 0;
          font-size: 28px;
          position: relative;
          color: black;
          width: 270px;

          &::after {
               content: "";
               position: absolute;
               bottom: 11px;
               width: 34px;
               height: 2px;
               background-color: #029330;
               margin-left: 5px;
          }

          &::before {
               content: "";
               position: absolute;
               bottom: 11px;
               width: 34px;
               height: 2px;
               background-color: #029330;
               left: 0px;
          }
     }

     h4 {
          font-size: 40px;
          font-weight: 600;
          color: #029330;
     }
}