.binarytree-sec {
    font-family: $font_heading;
    --tree-border: #706e6e;
    padding: 140px 20px 20px;


    .row {
        row-gap: 30px;
    }

    .__heading_sa {
        background-color: var(--card);
        border: 1px solid var(--card-border);
        border-radius: var(--card-radius);
        color: var(--txt-white);
        padding: 12px 20px;
        overflow: hidden;
        margin-top: 20px;
        max-width: 900px;
        width: 100%;
        margin: auto;
        text-align: center;

        .__top-heading{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            width: 100%;

            @media ($breakpoint_MD){
                flex-direction: column;
                gap: 10px;
            }

            p {
                margin-bottom: 0;
            }
    
            form{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;


                @media ($breakpoint_MD){
                    width: 100%;
                }
    
                .form-select,
                input{
                    font-size: 14px;
                    background-color: #f4fff40f;
                    border: 0;
                    outline: 0;
                    box-shadow: unset;
                    color: var(--txt-white);
                    padding: 10px 20px;
                    border-radius: 20px;
    
    
                    &::placeholder{
                        color: var(--txt-white);
                        opacity: 0.8;
                    }
                }

                .btn{
                    border-radius: 14px;
                    padding: 6px 14px;
                }
            }
        }

        .__bottom-heading{
            // background-color: #f4fff40f;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            height: 0;
            overflow: hidden;
            padding: 0;
            transition: all 0.2s linear;

            .details-1{
                width: 50%;

                svg{
                    margin-bottom: 14px;
                }

                p{
                    margin-bottom: 10px;
                    font-size: 14px;
                    opacity: 0.7;


                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }

        }

        &.show{
            
            .__bottom-heading{
                border-top: 1px solid #f4fff40f;
                height: 140px;
                padding: 10px 0;
                margin-top: 20px;
            }
        }


       

        .btn{
            padding: 0;
            svg{
                color: var(--txt-white);
            }
        }
    }

    .management-tree {
        padding: 30px;

        @media ($breakpoint_MD){
            padding: 0;
        }

        .mgt-container {
            overflow-y: hidden;
            overflow-x: auto;
            padding-bottom: 160px;


            
        }

    }






    .management-tree .person {
        text-align: center;
        position: relative;

        a{
            text-decoration: none;
            display: inline-block;
        }

        &:hover {

            img {
                border-color: var(--primary-color) !important;
                padding: 3px;
            }

            .name {
                border-color: var(--primary-color);
            }

            .__drophover {
                opacity: 1;
                visibility: visible;
                z-index: 111 !important;
                transform: translate(-50%, -15%);

            }

        }


        .btn{
            display: block;
            padding: 8px 15px;
            background-color: var(--primary-color);
            border: 1px solid transparent;
            color: var(--txt-black);
            margin-top: 20px;
            border-radius: 40px;
            transition: all 0.2s linear;
            font-size: 14px;
            white-space: nowrap;


            &:hover{
                background-color: transparent;
                border-color: var(--primary-color);
                color: var(--primary-color);
            }
        }

        .admin-name{
            padding: 10px 30px !important;
        }
    }

    .add-new{

        img{
            filter: contrast(0.4);
        }
    }

    .__drophover {
        position: absolute;
        bottom: -110px;
        transform: translate(-50%, 20%);
        left: 50%;
        width: 300px;
        background-color: var(--card);
        border: 1px solid var(--card-border);
        padding: 10px;
        border-radius: 20px;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s linear;

        @media ($breakpoint_MD){
            position: relative;
            opacity: 1;
            visibility: visible;
            bottom: 0;
            transform: translate(-50%, 6%) !important;
            z-index: 1;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            color: var(--txt-white);


            li {
                p {
                    margin-bottom: 5px;
                    font-size: 12px;
                    opacity: 0.8;
                }
            }
        }
    }

    .management-tree .person img {
        max-width: 80px;
        border: 2px solid #d7d7d7;
        border-radius: 50%;
        overflow: hidden;
        background-color: #f7f7f7;
        position: relative;
        z-index: 3;
        transition: all 0.3s linear;

    }

    // .activePerson img {
    //     background-color: var(--zs-totiya) !important;
    // }

    .management-tree .person p.name {
        background-color: var(--card);
        border: 1px solid var(--card-border);
        border-radius: 20px;
        padding: 10px 10px;
        font-size: 12px;
        font-weight: normal;
        color: var(--txt-white);
        margin: 0;
        position: relative;
        margin-top: 10px;

        @media ($breakpoint_LG){
            white-space: nowrap;
        }
    }

    .management-tree .person p.name:before {
        content: "";
        position: absolute;
        width: 2px;
        height: 5px;
        background-color: #d7d7d7;
        left: 50%;
        top: 0;
        transform: translateY(-100%);
    }

    .mgt-wrapper {
        display: flex;
    }

    .mgt-wrapper .mgt-item {
        display: flex;
        flex-direction: column;
        margin: auto;
    }

    .mgt-wrapper .mgt-item .mgt-item-parent {
        margin-bottom: 50px;
        position: relative;
        display: flex;
        justify-content: center;
    }

    // .__details-sec-left{
    //     position: absolute;
    //     left: 11%;
    //     background-color: var(--card);
    //     border: 1px solid var(--card-border);
    //     border-radius: var(--card-radius);
    //     color: var(--txt-white);
    //     padding: 14px 25px;
    //     width: 26%;

    //     &::after{
    //         content: "";
    //         position: absolute;
    //         top: 92%;
    //         right: -158px;
    //         transform: rotate(36deg);
    //         width: 100%;
    //         height: 3px;
    //         background-color: var(--tree-border);
    //         z-index: -1;        
    //     }

    //     p{
    //         margin-bottom: 5px;
    //         font-size: 14px;
    //         opacity: 0.7;
    //         text-align: right;

    //         &:last-child{
    //             margin-bottom: 0;
    //         }
    //     }
    // }

    // .__details-sec-right{
    //     position: absolute;
    //     right: 11%;
    //     background-color: var(--card);
    //     border: 1px solid var(--card-border);
    //     border-radius: var(--card-radius);
    //     color: var(--txt-white);
    //     padding: 14px 25px;
    //     width: 26%;

    //     &::after{
    //         content: "";
    //         position: absolute;
    //         top: 92%;
    //         left: -158px;
    //         transform: rotate(-36deg);
    //         width: 100%;
    //         height: 3px;
    //         background-color: var(--tree-border);
    //         z-index: -1;        
    //     }

    //     p{
    //         margin-bottom: 5px;
    //         font-size: 14px;
    //         opacity: 0.7;

    //         &:last-child{
    //             margin-bottom: 0;
    //         }
    //     }
    // }

    .mgt-wrapper .mgt-item .mgt-item-parent:after {
        position: absolute;
        content: "";
        width: 2px;
        height: 25px;
        bottom: 0;
        left: 50%;
        background-color: var(--tree-border);
        transform: translateY(100%);
    }

    .mgt-wrapper .mgt-item .mgt-item-children {
        display: flex;
        justify-content: center;
    }

    .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child {
        padding: 0 9px;
        position: relative;

        @media ($breakpoint_LG){
            padding: 0 15px;
        }
    }

    .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:before,
    .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:after {
        content: "";
        position: absolute;
        background-color: var(--tree-border);
        left: 0;
    }

    .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:before {
        left: 50%;
        top: 0;
        transform: translateY(-100%);
        width: 2px;
        height: 25px;
    }

    .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:after {
        top: -23px;
        transform: translateY(-100%);
        height: 2px;
        width: 100%;
    }

    .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:first-child:after {
        left: 50%;
        width: 50%;
    }

    .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:last-child:after {
        width: calc(50% + 1px);
    }
}