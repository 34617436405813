.menu__buttons {
  position: relative;
  z-index: 1;
  display: none;


  @media($breakpoint_LG) {
    display: block;
  }


  .dot_menu {


    padding: 10px;
    color: var(--txt-white);
    box-shadow: unset !important;
    outline: unset !important;
    border: 1px solid var(--border-color);
  }



  .react-tabs__tab-list {
    width: 200px;
    position: absolute;
    top: 46px;
    right: 20px;
    padding: 10px;
    background-color: var(--sidebar-bg);
    border-radius: 10px;
    border: 1px solid var(--border-color);

    p {
      margin: 0;
      font-size: 13px;
      opacity: 0.8;
      margin-bottom: 6px;
    }

    svg {
      font-size: 20px;
    }


    .react-tabs__tab {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      width: 100%;
      border-radius: 5px;
      font-size: 14px;
      margin-bottom: 5px;



      &::after {
        display: none;
      }
    }

    .react-tabs__tab--selected {
      background-color: var(--primary-color);

    }
  }

}


.kt_profile_top {
  overflow: hidden;
  margin-top: 20px;
  font-family: $font_heading;
  color: var(--txt-white);

  .kt_p_heading {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    svg {
      font-size: 20px;
    }
  }

  .__left_tab {
    padding-right: 50px;
    background-color: var(--card);
    border: 1px solid var(--card-border);
    border-radius: var(--card-radius);
    padding: 30px;
    color: var(--txt-white);

    .profile-infos {

      .name-pic {
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;

        @media($breakpoint_SM) {
          flex-direction: column;
          align-items: flex-start;
        }

        .outer_profile {
          padding: 10px;
          border-radius: 20%;

          @media($breakpoint_SM) {
            padding: 0;
            margin-top: 10px;
          }




          .left_profile {
            width: 80px;
            border: 0.7px solid var(--card-border);
            height: 80px;
            padding: 8px;
            background: var(--card);
            border-radius: 50%;
            position: relative;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              transform: scale(1.3);
            }


            .emptyPlaceholder {
              width: 100%;
              height: 100%;
              cursor: pointer;
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;

              h6 {
                font-size: 12px;
                margin-top: 5px;
                color: var(--primary-color);
              }

              svg {
                width: 25px;
                fill: var(--primary-color);
              }
            }
          }
        }


        .owner-pic {
          margin: 10px 0px;

          img {
            background: var(--light-blue);
            border-radius: 50%;
            padding: 7px;
            width: 82px;
          }
        }

        .name-post {
          h5 {

            &:last-child {
              margin-bottom: 0;
              font-weight: 200;
            }
          }
        }
      }

      .profile-follow {
        margin-bottom: 20px;
        display: flex;
        gap: 10px;


        .follows-btn {
          border: none;
          background-color: var(--primary-color);
          color: var(--txt-white);
          padding: 10px 30px;
          border-radius: 10px;
          font-size: 16px;
        }

        .message-btn {
          border: 1px solid var(--primary-color);
          background-color: var(--txt-white);
          color: var(--txt-black);
          padding: 10px 30px;
          border-radius: 10px;
          font-size: 16px;
        }
      }
    }

    .kt_heading__datas {
      margin-top: 40px;


      .react-tabs__tab-list {
        border: none;
        display: flex;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 0;

        @media($breakpoint_SM) {
          display: block;
        }

        .react-tabs__tab {
          border-radius: 0px;
          display: block !important;
          background-color: var(--card-in);
          color: var(--txt-white);
          margin-bottom: 0;
          padding: 12px 10px;
          bottom: 0 !important;
          border: none;
          border-radius: var(--card-radius);
          font-size: 15px;
          width: 100%;
          text-align: center;


          @media ($breakpoint_MD) {
            padding: 10p;
          }

          @media ($breakpoint_SM) {
            margin-bottom: 14px;
          }


        }

        .react-tabs__tab--selected {
          background: var(--primary-color);
          color: white;
          border: none;



          &::after {
            display: none;
          }
        }
      }
    }

  }





}


.kt_profile_datas {
  overflow: hidden;
  border-radius: 10px;
  font-family: $font_heading;
  background: transparent;
  color: var(--txt-white);
  position: relative;
  margin-bottom: 20px;
  




  // coustom_container

  .accout-seting {
    background-color: var(--card);
    margin-top: 20px;
    padding: 30px;
    border-radius: var(--card-radius);
    border: 1px solid var(--card-border);

    .default-block__title {
      background-color: var(--card-in);
      padding: 20px;
      margin-bottom: 20px;
      border-radius: var(--card-radius);
    }

    .form-control {
      background-color: transparent;
      padding: 10px 20px;
      border-radius: 20px;
      color: var(--txt-white);
      border-color: var(--card-border);
      box-shadow: none;
      width: 100%;
      display: block;

      &::placeholder {
        color: var(--txt-white);
        opacity: 0.6;
      }
    }


    .bottom_sec {
      .row {
        row-gap: 20px;
      }

    }

    .country_part {
      .form-select {
        padding: 11px;
        border: 1px solid var(--border-color);
        background-color: var(--bodybg) !important;
        background-image: var(--arrow-img);
        color: var(--txt-black);

        &:focus {
          box-shadow: none;
        }
      }
    }

    .react-tel-input {
      margin-bottom: 0 !important;
    }

    .react-tel-input {
      background: transparent;
      border: 1px solid var(--card-border);
      border-radius: var(--card-radius);
      z-index: 31;


      input {
        border: 0 !important;
        background-color: transparent;
        color: var(--txt-white) !important;
        height: 47px !important;
      }
    }

    .react-tel-input .country-list {
      width: 400px;
      border-radius: 20px;
      background-color: var(--card-in);
      padding: 0 10px 10px 10px;

      @media ($breakpoint_SM) {
        width: 300px;
      }

      .search {
        background-color: var(--card-in) !important;
      }

      .country {
        padding: 10px 20px !important;
        border-radius: 20px !important;

        &:hover {
          background-color: var(--primary-color) !important;
        }
      }

      .highlight {
        background-color: var(--primary-color) !important;
        color: var(--txt-black) !important;
      }



      .search-box {
        width: 90%;
      }
    }

    .react-tel-input .flag-dropdown.open {
      background-color: transparent !important;
    }

    .react-tel-input .flag-dropdown {
      border: 0 !important;
      border-right: 1px solid #cacaca6d !important;
      background-color: transparent !important;
    }

    .react-tel-input .selected-flag {
      background-color: transparent !important;
      width: 100% !important;
    }

    .react-tel-input .flag-dropdown.open .selected-flag {
      background-color: transparent;
    }



    .form__seciotn {

      .form {

        .form-label {
          font-size: 15px;
          color: var(--txt-white);
          opacity: 0.9;
        }

        .css-b62m3t-container {
          margin-bottom: 30px;
        }

        .css-t3ipsp-control:focus {
          box-sizing: 0 !important;
          border-color: var(--border-color) !important;
        }

        .css-t3ipsp-control,
        .css-13cymwt-control {
          padding: 5px 10px;
          z-index: 11;
          height: 100%;
          background: transparent;
          border: 1px solid var(--card-border) !important;
          box-shadow: unset !important;
          border-radius: 20px;


          &:hover {
            border-color: var(--primary-color);
          }
        }

        .css-166bipr-Input,
        .css-1jqq78o-placeholder,
        .css-qbdosj-Input,
        .css-1dimb5e-singleValue {
          color: #8f8f90 !important;
        }

        .css-1n6sfyn-MenuList {
          padding: 10px 10px !important;
        }

        .css-1nmdiq5-menu {
          border: none !important;
          border-radius: 16px !important;
          padding: 0;
          z-index: 11;
          background-color: var(--card-in);

        }

        .css-1n6sfyn-MenuList {
          &>div {
            transition: all 0.3s linear;
            cursor: pointer;
            padding: 7px 15px;
            border-radius: 20px;

            &:hover {
              background-color: var(--primary-color);
              color: var(--txt-black);
            }
          }
        }

        .css-1u9des2-indicatorSeparator {
          display: none !important;
        }

      }


    }

    .password__settings {

      label {
        margin-bottom: 0.5rem;
        color: var(--txt-white);
        opacity: 0.8;
      }




      .hide-pass {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 15px;

        .form-control {

          // &:-webkit-autofill {
          //   background-color: #e5c08533 !important;
          //   -webkit-text-fill-color: var(--txt-white);
          //   -webkit-box-shadow: 0 0 0px 1000px #e5c08533 inset;
          // }


          // &:focus {
          //   box-shadow: 0 0 0px 1000px #e5c08533 inset;
          //   border: 1px solid var(--border-color);
          //   background-color: #e5c08533;
          // }

          &:focus-visible {
            outline: none;
          }
        }

        button {
          border: none;
          font-size: 16px;
          position: absolute;
          background: none;
          right: 20px;
          top: 10px;


          &:focus {
            outline: none;
          }

          svg {
            font-size: 20px;
            color: #878181;
          }
        }
      }
    }

    .form-part3 {
      .authorize {
        .author-head {
          background-color: var(--primary-color);
          padding: 15px 0 15px 15px;
          color: var(--txt-white);
          border-radius: 7px 7px 0 0;
          margin-bottom: 30px;

          h4 {
            font-size: 16px;
            margin: 0;
          }
        }

        p {
          font-size: 16px;
          margin-bottom: 40px;
        }

        .scan_sec {
          display: flex;
          margin-top: 30px;

          .store-pic {
            align-self: center;

            img {
              width: 60%;
            }
          }

          .link1-copy {


            margin-bottom: 15px;

            input {
              font-size: 14px;
              margin-bottom: 0px;
              padding: 10px;
              border-radius: 5px 0 0 5px;
              border: 1px solid var(--border-color);
              width: 85%;

            }

            .icon1-copy {
              padding: 5px 11px;

              border-radius: 0 5px 5px 0;
              border-left: none;

              svg {
                font-size: 32px;
                margin-left: 10px;
                color: var(--sky-blue);
              }
            }
          }
        }

        .gogle-key {
          text-align: center;
        }


      }
    }
  }

  .auth__google {
    background-color: var(--card);
    margin: 20px 0;
    padding: 30px;
    border-radius: var(--card-radius);
    border: 1px solid var(--card-border);

    .default-block__title {
      background-color: var(--card-in);
      padding: 20px;
      margin-bottom: 20px;
      border-radius: var(--card-radius);
    }

    .qr__sec {
      width: 100%;

      .qr__sec___date {
        display: flex;
        align-items: center;
        justify-content: center;

        .qr___uijjd {

          img {
            width: 100%;
          }

        }



        .scan_modal {
          width: 200px !important;
          height: 200px !important;


        }

      }


      .input__sec {
        max-width: 500px;
        margin: 20px auto;
        width: 100%;

        label {
          text-transform: capitalize;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          color: var(--txt-white);

          p {
            margin-bottom: 0;

            &:last-child {
              color: var(--primary-color);
            }
          }
        }

        .inputs_auth{
          position: relative;
          z-index: 1;


          .btn-copy{
            position: absolute;
            top: 50%;
            right: 0;
            background-color: transparent;
            color: var(--txt-white);
            border: 0;
            outline: 0;
            transform: translate(-50%, -50%);
          }
          
        }

        .form-control{
          background-color: transparent;
          border-color: var(--card-border);
          padding: 10px 30px;
          border-radius: var(--card-radius);
          color: var(--txt-white);
          box-shadow: unset;
        }

        .btns__sec_auth {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;
          margin-top: 30px;

          .btn {
            display: inline-block;
            padding: 10px 20px;
            text-transform: capitalize;
            border-radius: 30px;
            width: 50%;

          }
        }

      }
    }
  }



}