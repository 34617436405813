.request_main {
    margin-top: 30px;


    .request_inner {
        overflow: hidden;
        font-family: $font_heading;
        color: var(--txt-white);
        position: relative;
        z-index: 1;
        max-width: 800px;
        width: 100%;
        margin: 50px auto;

        @media($breakpoint_MD) {
            padding: 15px;
        }

        @media ($breakpoint_SM){
            padding: 20px;
            background-color: var(--card);
            border-radius: var(--card-radius);
            border: 1px solid transparent;
            margin: 0;
        }

        .done{
            .active-50::after{
                width: 100% !important;
            }
        }

        .__steps {

            .titlecard {
                margin-bottom: 30px;
                text-align: center;
                font-size: 28px;

                @media ($breakpoint_SM){
                    font-size: 18px;
                    padding: 10px;
                }
            }

            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0;
                margin: 0;
                list-style-type: none;
                position: relative;
                z-index: 1;


                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 99%;
                    height: 3px;
                    background-color: transparent;
                    border: 3.5px dashed rgb(64 69 72);
                    z-index: -1;
                    transform: translate(-50%, -50%);
                }

                li {
                    width: 50px;
                    height: 50px;
                    background-color: var(--card);
                    border: 1px solid transparent;
                    padding: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: var(--card-radius);
                    font-size: 18px;

                    @media ($breakpoint_SM){
                        width: 35px;
                        height: 35px;
                        font-size: 15px;
                        background-color: var(--card-in);
                    }
                }
            }

            .active {
                li {
                    &:first-child {
                        border-color: var(--primary-color) !important;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0%;
                    width: 20%;
                    height: 3px;
                    background-color: transparent;
                    border: 3.5px solid rgba(var(--primary-rgb), 1);
                    border-radius: 10px;
                    z-index: -1;
                    transform: translate(0%, -50%);

                    animation: affter_a 2s linear infinite;
                }
            }

            .active-50 {

                li {

                    &:first-child {
                        border-color: var(--primary-color) !important;
                        background-color: var(--primary-color);
                        color: var(--txt-black);
                    }

                    &:nth-child(2) {
                        border-color: var(--primary-color) !important;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0%;
                    width: 50%;
                    height: 3px;
                    background-color: transparent;
                    border: 3.5px solid rgba(var(--primary-rgb), 1);
                    border-radius: 10px;
                    z-index: -1;
                    transform: translate(0%, -50%);
                    animation: none;
                }
            }

            .active-100 {

                li {

                    &:nth-child(1),
                    &:nth-child(2) {
                        background-color: var(--primary-color);
                        color: var(--txt-black);
                    }

                    &:nth-child(3) {
                        border-color: var(--primary-color) !important;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0%;
                    width: 70%;
                    height: 3px;
                    background-color: transparent;
                    border: 3.5px solid rgba(var(--primary-rgb), 1);
                    border-radius: 10px;
                    z-index: -1;
                    transform: translate(0%, -50%);
                    animation: affter_ab 1s linear infinite;


                }
            }

            @keyframes affter_a {
                0%{
                    width: 20%;
                }
                80%{
                    width: 28%;
                }
                100%{
                    width: 18%;
                }
                
            }

            @keyframes affter_ab {
                0%{
                    width: 70%;
                }
                80%{
                    width: 78%;
                }
                100%{
                    width: 70%;
                }
                
            }
        }


        .panel_section {
            max-width: 600px;
            width: 100%;
            margin: 50px auto;


            .income_card {
                color: var(--txt-white);
                background-color: var(--card);
                border: 0;
                border-radius: var(--card-radius);
                padding: 20px;
                border: 1px solid transparent;

                @media ($breakpoint_SM){
                    background-color: var(--card-in);
                }

                label {
                    cursor: pointer;
                    width: 100%;
                }

                .card-body {
                    display: flex;
                    align-items: end;
                    justify-content: space-between;
                    padding: 0;

                    .left-sec {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 20px;

                        @media ($breakpoint_SM){
                            flex-direction: column;
                            align-items: flex-start;
                        }

                        .icon__leds1 {
                            background-color: transparent;
                            border: 1px solid var(--border-color);
                            padding: 10px;
                            border-radius: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 45px;
                            height: 45px;
                        }

                        .total_income {

                            .info {
                                font-size: 22px;
                                color: var(--primary-color);
                                margin-bottom: 10px;
                            }

                            .total {
                                margin-bottom: 0;
                                opacity: 0.7;
                            }

                        }
                    }



                }

                .checkbox__boxs *,
                .checkbox__boxs *:after,
                .checkbox__boxs *:before {
                    box-sizing: border-box;
                }

                .checkbox__boxs {

                    .checkbox-input {
                        clip: rect(0 0 0 0);
                        -webkit-clip-path: inset(100%);
                        clip-path: inset(100%);
                        height: 1px;
                        overflow: hidden;
                        position: absolute;
                        white-space: nowrap;
                        width: 1px;
                    }

                    .checkbox-tile {
                        width: 100%;
                        min-height: 100%;
                        border-radius: 0.5rem;
                        cursor: pointer;
                        position: relative;
                        display: block;

                        &::before {
                            content: "";
                            position: absolute;
                            display: block;
                            width: 1.25rem;
                            height: 1.25rem;
                            border: 2px solid var(--card-border);
                            background-color: var(--card);
                            border-radius: 50%;
                            top: 50%;
                            right: 10px;
                            opacity: 0;
                            transform: scale(0) translate(-50%, -50%);
                            transition: 0.25s ease;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
                            background-size: 12px;
                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                        }

                        &:hover {
                            border-color: #2260ff;

                            &:before {
                                transform: scale(1) translate(-50%, -50%);
                                opacity: 1;
                            }
                        }
                    }

                    .checkbox-label {
                        color: #707070;
                        transition: 0.375s ease;
                        text-align: center;
                    }

                    .checkbox-input:checked+.checkbox-tile {
                        border-color: #2260ff;
                    }

                    .checkbox-input:checked+.checkbox-tile:before {
                        transform: scale(1) translate(-50%, -50%);
                        opacity: 1;
                        background-color: #2260ff;
                        border-color: #2260ff;
                    }

                    .checkbox-input:checked+.checkbox-tile .checkbox-icon,
                    .checkbox-input:checked+.checkbox-tile .checkbox-label {
                        color: #2260ff;
                    }

                    .checkbox-input:checked+.checkbox-tile {
                        border-color: #2260ff;
                        // box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 1px var(--primary-color);
                    }

                    .checkbox-input:focus+.checkbox-tile:before {
                        transform: scale(1) translate(-50%, -50%);
                        opacity: 1;
                    }

                }
            }

            .button_sec {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 50px;

                .btn {
                    padding: 10px 35px;
                    border: 1px solid transparent;
                    display: inline-block;
                    text-transform: capitalize;
                    background-color: var(--primary-color);
                    border-radius: var(--card-radius);
                    color: var(--txt-black);
                    font-size: 16px;
                    transition: all 0.3s linear;

                    &:hover {
                        background-color: transparent;
                        border-color: var(--primary-color);
                        color: var(--txt-white);
                    }

                    .color-ring{
                        margin-right: 10px;
                    }

                }
            }

            .panel_inner {
                margin-top: 15px;
                background-color: var(--card);
                border-radius: var(--card-radius);
                padding: 30px;

                .amount {
                    color: var(--primary-color);
                    opacity: 1 !important;
                }

                h6 {
                    opacity: 0.9;
                }

                .pay-req {
                    margin-bottom: 10px;

                    .payout-request {
                        background-color: var(--card-in);
                        padding: 8px 15px;
                        color: var(--txt-white);
                        display: block;
                        width: 100%;
                        box-shadow: unset;
                        outline: unset;
                        border: 0.6px solid var(--border-color);
                        border-radius: var(--card-radius);
                    }


                }

                .pay-req_charges {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 20px 0;

                    .pay-req {
                        width: 100%;

                        h6 {
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }


                    }
                }
            }
        }




    }

    .info_alert {
        padding: 20px;
        max-width: 800px;
        width: 100%;
        margin: auto;
        display: none;
    }
}

.request_modal {
    border-radius: 10px;
    width: 350px;
    border: 1px solid var(--sky-blue);
    background-color: var(--sidebar-bg) !important;

    @media ($breakpoint_SM) {
        width: 60%;
    }

    .request_inner {
        padding: 20px 0px;
        text-align: center;


        p {
            color: var(--txt-white);
        }


    }

    .react-responsive-modal-closeButton {
        display: none;
    }
}

.new-bar {
    align-items: center;
    background-color: #f0f0f3;
    border-radius: 10px;
    display: flex;
    padding: 10px;
    position: relative;
    width: 100%;
    margin-left: 100px;

    .range-slider_thumb {
        color: #000;
        color: var(--txt-black);
        font-size: 14px;
        font-size: 13px;
        height: 40px;
        padding: 10px;
    }

    .bar-out {
        align-items: center;
        display: flex;
        height: 100%;
        width: 100%;
    }

    input {
        width: 100%;
    }

}

.show__alert {
    display: block !important;
}