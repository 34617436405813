.Edge_sec {
    padding-top: 110px;

    .inner {

        .heading {
            text-align: center;
            color: white;

            h1 {
                font-size: 64px;
                font-weight: 600;
                line-height: 68px;
                font-family: $font_heading;


                @media ($breakpoint_LG) {
                    font-size: 48px;
                }

                @media ($breakpoint_MD) {
                    font-size: 42px;
                    line-height: 40px;
                    margin-bottom: 20px;
                }

                @media ($breakpoint_SM) {
                    font-size: 34px;
                    line-height: 34px;
                }

                @media ($breakpoint_450) {
                    font-size: 28px;
                    line-height: 34px;
                }

                @media ($breakpoint_ESM) {
                    font-size: 24px;
                    line-height: 34px;
                }


                span {
                    color: transparent;
                    background: var(--text_color);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            p {
                font-size: 18px;
                font-weight: 500;
                font-family: $font_pragph;


                @media ($breakpoint_450) {
                    font-size: 16px;
                    line-height: 34px;
                }

                @media ($breakpoint_ESM) {
                    font-size: 14px;
                    line-height: 24px;
                }
            }

        }

        .card {
            background-color: transparent;
            color: white;
            border: none;
            border-radius: var(--commonRadius);
            text-align: center;
            padding: 20px;
            transition: all .3s linear;
            min-height: 430px;

            @media ($breakpoint_MD) {
                min-height: auto;
                margin-bottom: 30px;
            }

            .inner {

                img {
                    height: 200px;
                    width: auto;
                    margin-bottom: 30px;

                    @media ($breakpoint_450) {
                        height: 166px;
                    }
                }

                .inner_info {
                    h2 {
                        font-weight: 600;
                        font-size: 32px;

                        @media ($breakpoint_450) {
                            font-size: 24px;
                        }

                        font-family: $font_pragph;
                    }

                    p {
                        margin: 0;
                        font-size: 14px;
                        font-family: $font_pragph;
                        font-weight: 300;

                        @media ($breakpoint_450) {
                            font-size: 13px;
                        }
                    }
                }
            }

            &:hover {
                box-shadow:
                    0 0 10px #e8cc5652, 0 0 20px #e8cc5652, 0 0 40px #e8cc5657, 0 0 80px #e8cc5657;

            }

        }
    }
}