.trading-section {
  margin-top: 30px;

  .trading-outer {
    padding: 20px;
    background-color: var(--card);
    border-radius: var(--card-radius);
    border: 1px solid var(--card-border);

    .trading-head {
      padding: 15px;

      border-radius: 7px;
      position: relative;
      z-index: 1;
      background: var(--light-blue);


      h4 {

        font-weight: 500;
        color: var(--primary-color);

        margin: 0;

        @media ($breakpoint_MD) {
          font-size: 25px;
        }

        @media ($breakpoint_SM) {
          font-size: 20px;
        }
      }
    }

    @include table_styles;
  }
}