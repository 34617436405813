:root {
    --commonRadius: 30px;
}

.pvt-header {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 20px 0;
    font-family: $font_heading;
    z-index: 9999;
    background-color: rgb(21, 21, 26);
    color: var(--txt-white);
    box-shadow: 1px 4px 20px 0px #e8cc5629;

    @media ($breakpoint_LG) {
        padding: 0 15px;
    }

    .container-index {

        @media ($breakpoint_LG) {
            padding: 0;
        }

    }



    .header-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        max-width: 1400px;
        width: 100%;
        margin: auto;

        @media ($breakpoint_XL) {
            padding: 15px 0;
        }

        @media ($breakpoint_LG) {
            padding: 15px 0;
        }

        .left-area-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .header-logo {

                a {
                    text-decoration: none;
                    color: var(--txt-white);
                    display: inline-block;

                    img {
                        width: 60px;


                        @media ($breakpoint_XL) {
                            width: 50px;
                        }
                    }
                }
            }

        }


        .lg__size {
            display: flex;
            align-items: center;
            justify-content: space-between;
            list-style-type: none;
            margin: 0;
            padding-left: 15px;

            @media ($breakpoint_LG) {
                display: none;
            }

            @media ($breakpoint_MD) {
                position: fixed;
                top: 11%;
                left: 0;
                width: 100%;
                background: var(--card);
                height: 89%;
                z-index: 9;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                padding: 20px 20px 0;
            }

            @media ($breakpoint_SM) {
                padding: 0 20px;

            }




            .nav-item {
                z-index: 1;

                .nav-link {
                    color: var(--txt-white);
                    text-decoration: none;
                    background-color: transparent !important;
                    transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
                    font-size: 14px;
                    padding: 15px 14px;


                    &:hover {
                        color: var(--primary-color);
                    }

                    @media ($breakpoint_XXL) {
                        padding: 15px 10px;
                        font-size: 13px;
                    }

                    @media ($breakpoint_XL) {
                        padding: 15px 6px;
                        font-size: 12px;
                    }
                }

                &:hover {

                    .__drop-outer {
                        opacity: 1;
                        visibility: visible;
                        transform: translate3d(0px, 40px, 0px) !important;

                    }

                }

                .__drop-outer {
                    width: 300px;
                    padding-top: 40px;
                    transform: translate3d(0px, -90px, 0px) !important;
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
                    z-index: -1;

                    @media ($breakpoint_XL) {
                        width: 250px;
                    }

                    @media ($breakpoint_MD) {
                        width: 100%;
                        padding-top: 0;
                        position: static;
                        transform: none !important;
                        opacity: 1;
                        visibility: visible;
                    }

                    .__dropdown {
                        background-color: #15151a;
                        width: 100%;
                        padding: 20px;
                        border-radius: 20px;

                        .dropdown-item {
                            padding: 10px 14px;
                            color: var(--txt-white);
                            background-color: transparent;
                            font-size: 15px;
                            transition: all 0.1s linear;

                            svg {
                                color: #dddddda1;
                                margin-right: 5px;
                            }

                            &:hover {
                                border-radius: 20px;
                                background-color: #1f1f25;

                                svg {
                                    color: var(--primary-color);
                                }
                            }
                        }
                    }
                }
            }

            .link-1,
            .link-5,
            .link-7,
            .link-8,
            .link-9 {
                display: none;
            }

            .link-8,
            .link-3 {
                @media ($breakpoint_LG) {
                    display: none;
                }
            }



            .btn_item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;

                .nav-button {
                    display: inline-block;
                    padding: 8px 30px;
                    background-color: var(--primary-color);
                    border: 1px solid transparent;
                    text-decoration: none;
                    border-radius: var(--commonRadius);
                    color: var(--txt-white);
                    font-size: 14px;
                    transition: all 0.3s linear;

                    &:nth-child(2) {
                        background-color: transparent;
                        border-color: var(--primary-color);
                        color: var(--primary-color);
                        margin-left: 10px;
                    }
                }

                .nav-swap {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    width: 2.5rem;
                    height: 2.5rem;
                    overflow: hidden;

                    img {
                        width: 100%;
                    }
                }
            }
        }

        .__end {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            @media ($breakpoint_LG){
                gap: 8px;
            }

            .__item {
                .Link {
                    // background-color: var(--card-in);
                    padding: 10px;
                    border-radius: 50%;
                    color: var(--txt-white);
                    transition: all 0.3s linear;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // border: 1px solid var(--card-border);

                    &:hover {
                        color: var(--primary-color);
                        border-color: var(--primary-color);
                    }

                    svg {
                        font-size: 20px;
                    }
                }

                .Link-btn {
                    border: 1px solid var(--primary-color) ;
                    padding: 10px 15px;
                    font-size: 12px;
                    text-decoration: none;
                    border-radius: 30px;
                    color: var(--primary-color);
                    background-color: var(--card-in);
                    transition: all 0.2s linear;
                    white-space: nowrap;

                    &:hover {
                        color: var(--txt-white);
                        border-color: var(--card-border);
                    }

                    @media ($breakpoint_XL){
                        padding: 10px 10px;
                    }

                    @media ($breakpoint_LG){
                        padding: 8px 10px;
                        font-size: 12px;
                    }

                    @media ($breakpoint_SM){
                        padding: 8px 14px;
                    }

                   
                }

            }

            .nav-item {
                z-index: 1;

                .nav-link {
                    color: var(--txt-white);
                    text-decoration: none;
                    background-color: transparent !important;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 10px;

                    &::after {
                        display: none;
                    }

                    .__avtar {
                        padding: 4px;
                        border-radius: var(--card-radius);
                        background-color: var(--primary-color);
                        overflow: hidden;

                        img {
                            width: 35px;
                            height: 35px;
                            transform: scale(1.3);

                            @media ($breakpoint_XL) {
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }

                    .__data {

                        @media ($breakpoint_SM) {
                            display: none;
                        }

                        h5 {
                            font-size: 14px;
                            margin-bottom: 0;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            width: 57px;
                        }
                    }

                }

                &:hover {

                    .__drop-outer {
                        opacity: 1;
                        visibility: visible;
                        transform: translate3d(0px, 30px, 0px) !important;

                    }

                }

                .__drop-outer {
                    width: 240px;
                    padding-top: 40px;
                    transform: translate3d(0px, 90px, 0px) !important;
                    position: absolute;
                    top: 0;
                    right: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
                    z-index: -1;

                    .__dropdown {
                        background-color: var(--card);
                        width: 100%;
                        padding: 16px;
                        border-radius: 20px;
                        box-shadow: 1px 4px 20px 0px #e8cc5629;
                        border: 1px solid var(--card-border);

                        .dropdown-item {
                            padding: 10px 14px;
                            color: var(--txt-white);
                            background-color: transparent;
                            font-size: 15px;
                            transition: all 0.1s linear;
                            border: 1px solid transparent;

                            svg {
                                color: var(--primary-color);
                                margin-right: 5px;
                            }

                            &:hover {
                                border-radius: var(--card-radius);
                                background-color: #1f1f25;
                                border-color: var(--card-border);
                            }
                        }

                        .lg-view {
                            display: none;

                            @media ($breakpoint_LG) {
                                display: block;
                            }
                        }

                        .logout {
                            border-radius: var(--card-radius);
                            margin-top: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                        }
                    }
                }
            }

            .toggleButton {
                background-color: transparent;
                outline: 0;
                border: 1px solid var(--card-border);
                border-radius: 10px;
                padding: 7px;
                color: var(--txt-white);
                align-items: center;
                justify-content: center;
                display: none;

                .--menu {
                    display: inline-block;
                }

                .--close {
                    display: none;
                }

                @media ($breakpoint_LG) {
                    display: flex;
                }

            }
        }

    }

}


.__subTab {
    background-color: var(--card);
    padding: 15px 10px;
    border-radius: calc(var(--card-radius) * 3);
    font-family: $font_heading;
    margin-top: 10px;
    border: 1px solid var(--card-border);

    @media ($breakpoint_SM) {
        padding: 10px;
    }

    .nav {
        position: relative;
        overflow-x: scroll;
        flex-wrap: nowrap;


        .nav-item {

            .nav-link {
                color: var(--txt-white);
                text-decoration: none;
                font-size: 14px;
                padding: 9px 15px;
                text-transform: capitalize;
                border-radius: var(--card-radius);
                margin-left: 5px;
                white-space: nowrap;

                @media ($breakpoint_SM) {
                    padding: 8px 10px;
                    font-size: 13px;
                }


                &:hover:not(.activeLink) {
                    background-color: #c7c7c733;
                }

            }

            .activeLink {
                background-color: var(--primary-color);
                color: var(--txt-black);
            }
        }
    }

    .nav::-webkit-scrollbar {
        display: none;
    }

    .__active {
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 100px;
        transition: left 0.3s ease;
    }
}

.__layout {
    font-family: $font_heading;
    color: var(--txt-white);
    background-color: var(--card);
    border: 1px solid var(--card-border);
    border-radius: var(--card-radius);
    padding: 30px;
    margin-top: 20px;

    .heading {
        background-color: var(--card-in);
        border-radius: var(--card-radius);
        padding: 20px;
        margin-bottom: 0;
        font-size: 20px;
    }

    .row {
        row-gap: 20px;
    }

    .list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        background-color: var(--card-in);
        padding: 13px 20px;
        border-radius: var(--card-radius);


        h4 {
            font-size: 17px;
            margin-bottom: 0;
        }
    }
}