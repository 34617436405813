.uni-level-outer {
  margin-top: 20px;

  .level_inner {
    padding: 20px;
    font-family: $font_heading;
    border: 1px solid var(--card-border);
    background: var(--card);
    color: var(--txt-white);
    border-radius: var(--card-radius);
    margin-bottom: 20px;

    .new_button_css_dashboard {

      @media ($breakpoint_SM) {
        padding: 10px 15px;
        text-align: center;
        font-size: 14px;
      }
    }

    .__levelHeading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      h5 {
        margin-bottom: 0;

        .value {
          color: var(--primary-color);
          display: inline-block;
          margin-left: 10px;
        }
      }
    }

    .__toptable {
      padding: 20px;
      background-color: var(--card-in);
      border-radius: var(--card-radius);

      @media ($breakpoint_SM) {
        padding: 15px;
      }
    }

    .top_section_unilevel {
      display: flex;
      justify-content: flex-start;
      gap: 15px;
      white-space: nowrap;
      align-items: flex-end;

      @media ($breakpoint_MD) {
        flex-wrap: wrap;
      }

      @media ($breakpoint_SM) {
        justify-content: flex-start;
      }

      .date {
        position: relative;
        z-index: 1;
        width: 20%;

        @media ($breakpoint_MD) {
          width: 43%;
        }


        .form_lable {
          opacity: 0.6;
        }

        .select_level {
          position: absolute;
          background: var(--sidebar-bg);
          bottom: 26px;
          left: 11px;
          font-size: 12px;
          background: var(--sidebar-bg);
          padding: -1px;
          padding: 3px 5px;
          border-radius: 5px;
          color: var(--txt-light);

        }

        .select_option {
          font-size: 13px;
          padding: 10px;
          background: transparent;
          color: var(--txt-white);
          border-radius: 20px;
        }

        span {
          color: var(--txt-black);
        }

        input {
          border: 1px solid var(--card-border);
          padding: 10px;
          border-radius: 20px;
          background: transparent !important;
          color: var(--txt-white);
          font-size: 13px;
          width: 100%;

          &:focus {
            outline: none;
          }
        }

        input[type="date"]::-webkit-calendar-picker-indicator {
          cursor: pointer;
          border-radius: 4px;
          margin-right: 2px;
          opacity: 0.6;
          filter: invert(0.4);
        }
      }

      .seach_btn {
        background-color: var(--primary-color);
        color: var(--txt-black);
        padding: 10px 20px;
        border-radius: var(--card-radius);
        outline: 0;
        box-shadow: none;
        border: 1px solid transparent;
        transition: all 0.3s linear;
        font-size: 14px;

        &:hover {
          background-color: transparent;
          border-color: var(--primary-color);
          color: var(--primary-color);
        }

        @media ($breakpoint_LG) {
          padding: 10px 15px;
        }
      }

      .level_open {
        margin-bottom: 0px;
        padding: 7px 10px;
        background: var(--bodybg);
        border-radius: 10px;
        color: var(--sky-blue);
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        @media ($breakpoint_450) {
          text-align: center;
          width: 100%;
          font-size: 12px;

        }

        @media (max-width:385px) {
          gap: 10px;
        }


        span {
          color: var(--primary-color);
          font-weight: 600;
          padding: 3px 10px;
          background: var(--light-blue);
          border-radius: 5px;
          margin-left: 5px;
        }
      }

      .form-control {
        appearance: auto;
        // width: auto !important;
        border: 1px solid var(--border-color);

        &:focus {
          box-shadow: none;
          border: 1px solid var(--border-color);
        }

        @media ($breakpoint_450) {
          text-align: center;
          width: 100% !important;
        }
      }

      .form-btn {
        display: flex;
        gap: 10px;

        .searchicons {
          background-color: var(--sky-blue);
          color: var(--txt-white);
          padding: 5px 7px;
          border: none;
          outline: none;
          border-radius: 7px;
        }
      }
    }

    .cash_flow {
      justify-content: space-between;
      padding: 10px;
      border-radius: 10px;
      background: var(--light-blue);

      h4 {
        color: var(--primary-color);
        margin: 0px;
      }

      .level_open {
        background: var(--sidebar-bg);
      }
    }

    .led-selected {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 20px;
      background-color: var(--card-in);
      border-radius: var(--card-radius);

      h4 {
        margin-bottom: 0;
      }

      .ledger-form {
        .ledger {
          width: 240px !important;
          padding: 10px 20px;
          text-align: left;
          border: 1px solid var(--card-border);
          background-color: transparent;
          color: var(--txt-white);
          border-radius: var(--card-radius);
          box-shadow: unset;
          outline: none;
          font-size: 15px;
          font-family: $font_heading;

          option {
            font-size: 15px !important;
            font-family: $font_heading;
          }

          @media ($breakpoint_50) {
            width: 100% !important;
          }
        }
      }
    }

    .rank-head {
      .rank-data {
        border: 1px solid var(--border-color);
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 7px;
        background: var(--sidebar-bg);

        p {
          margin: 0;
          border-right: 1px solid var(--border-color);
          padding-right: 10px;
          font-size: 16px;

          @media ($breakpoint_ESM) {
            font-size: 14px;
          }
        }

        h5 {
          margin: 0;
          text-align: center;
          padding-left: 10px;
          color: var(--sky-blue);

          @media ($breakpoint_ESM) {
            font-size: 14px;
          }

        }
      }
    }

    .rank-royal {
      color: var(--primary-color);
      margin: 0px;

      @media ($breakpoint_450) {
        font-size: 20px;
      }

      @media ($breakpoint_ESM) {
        font-size: 14px;
      }
    }

    .rank_table-head {
      text-align: center
    }

    .rank_table-body {
      text-align: center
    }

    .justi {
      justify-content: center;
    }


    @include table_styles;
  }
}

.__refreshButton {
  position: fixed;
  bottom: 50px;
  right: 60px;
  z-index: 9999;

  @media ($breakpoint_LG) {
    right: 20px;
  }

  @media ($breakpoint_MD) {
    right: 30px;
    bottom: 20px;
  }

  .btn {
    background-color: var(--primary-color);
    border-radius: var(--card-radius);
    padding: 10px;
  }


}