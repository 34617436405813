.__media {
    font-family: $font_heading;
    --body-hb:#191c1f;
    padding-bottom: 100px;

    .row {
        row-gap: 40px;
    }

    .__heading_sa {
        background-color: var(--card);
        border: 1px solid var(--card-border);
        border-radius: var(--card-radius);
        color: var(--txt-white);
        padding: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        overflow: hidden;
        margin-top: 20px;

        p {
            margin-bottom: 0;
        }
    }

    .card {
        padding: 20px;
        border-radius: 30px;
        background-color: var(--card);
        border-radius: var(--card-radius);
        color: var(--txt-white);
        border: 1px solid var(--card-border);
        overflow: hidden;
        z-index: 1;

        .stretched-link {
            display: none;

            @media ($breakpoint_MD) {
                display: none;
            }
        }

        .card-icon {
            margin-bottom: 20px;

            img {
                object-fit: contain;
                height: 70px;
            }
        }

        .card-desction {

            h3{
                text-transform: capitalize;
            }

            .btn {
                background-color: #4c4c4c3d;
                border-radius: var(--card-radius);
                color: var(--txt-white);
                border: 1px solid transparent;
                padding: 10px 30px;
                font-size: 14px;
                text-align: center;
                display: inline-block;
                margin-top: 20px;
                transition: all 0.3s linear;

                &:hover {
                    background-color: transparent;
                    border-color: var(--primary-color);
                    color: var(--primary-color);
                }
            }

            .verified-btn{
                background-color: var(--primary-color);
                color: var(--txt-black) ;

            }
        }

        .__verifie{
            position: absolute;
            top: 5px;
            right: -2px;
            width: 90px;
            height: 70px;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;

            svg{
                font-size: 2rem;
                // color: var(--zs-totiya);
                color: var(--primary-color);
            }

            .verified-icon{
                color: var(--primary-color);
            }

            .link-icon{
                color: #ffffff87;
            }

        }

    }
}