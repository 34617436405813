.logs-page {
     font-family: $font_heading;
     color: var(--txt-black);
     --y-card: #e9f7f0;



     main {
          padding-top: 0 !important;
          overflow: hidden;
          margin-bottom: 0 !important;
          min-height: auto !important;

     }

     .preview-sec {
          margin-top: 50px;
          background-color: var(--y-card);
          border-radius: 50px;
          padding: 40px;
          position: relative;
          z-index: 2;
          color: var(--txt-black);

          @media ($breakpoint_SM) {
               padding: 30px 20px;
          }

          .__img-sec {
               position: absolute;
               top: 10%;
               right: 100px;
               width: 200px;
               height: 200px;
               transform: translate(-50%, -50%);
               transition: all 0.1s linear;
               filter: drop-shadow(1px 1px 0 #fff);
               opacity: 0.8;

               @media ($breakpoint_MD) {
                    display: none;
               }


               img {
                    width: 100%;
                    height: 100%;
               }
          }

          .__tree-img {
               position: absolute;
               top: 0;
               right: 0;
               width: 50%;
               height: 100%;
               display: flex;
               justify-content: flex-end;


               img {
                    object-fit: contain;
                    height: 100%;

                    @media ($breakpoint_MD) {
                         opacity: 0.4;
                    }

                    @media ($breakpoint_MD) {
                         opacity: 0.2;
                    }
               }
          }

          .__card-data {
               width: 60%;

               @media ($breakpoint_MD) {
                    width: 100%;
               }

               h2 {
                    margin-bottom: 20px;
               }

               p {
                    opacity: 0.7;
                    margin-bottom: 5px;
                    font-size: 14px;
               }
          }


          .__logs {
               display: flex;
               align-items: center;
               justify-content: flex-start;
               gap: 20px;
               margin-top: 30px;

               @media ($breakpoint_SM) {
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;

               }

               .__inputs {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 80%;
                    border: 1px solid #0000002b;
                    border-radius: 30px;
                    padding: 5px 15px;
                    overflow: hidden;

                    @media($breakpoint_SM) {
                         width: 100%;
                    }



                    input {
                         background-color: transparent;
                         width: 100%;
                         border: 0;
                         box-shadow: unset;
                         outline: 0;
                         padding: 8px 10px 8px 7px;
                         height: auto;
                         color: var(--txt-black);
                         font-size: 14px;
                         text-transform: capitalize;
                    }

                    .btn {
                         background-color: var(--zs-totiya);
                         border: 1px solid transparent;
                         padding: 5px 35px;
                         border-radius: 50px;
                         white-space: nowrap;
                         transition: all 0.3s linear;
                         font-size: 14px;
                         text-transform: capitalize;
                         color: var(--txt-white);
                         z-index: 999;

                         &:hover {
                              background-color: transparent;
                              border-color: var(--zs-totiya);
                              color: var(--zs-totiya);
                         }
                    }
               }

               .check-btn {
                    background-color: var(--zs-totiya);
                    border: 1px solid transparent;
                    padding: 10px 20px;
                    border-radius: 50px;
                    white-space: nowrap;
                    transition: all 0.3s linear;
                    font-size: 14px;
                    text-transform: capitalize;

                    &:hover {
                         background-color: transparent;
                         border-color: var(--zs-totiya);
                    }
               }



          }
     }

     .infomation-sec {
          margin-top: 35px;
          padding: 40px 75px 200px;
          position: relative;
          z-index: 1;

          &::after {
               content: "";
               position: absolute;
               top: -10%;
               right: 0;
               width: 100%;
               height: 100%;
               background: radial-gradient(50% 50% at 50% 50%, #028144 0%, rgba(14, 234, 174, 0) 100%);
               background-position: bottom;
               background-repeat: no-repeat;
               background-size: contain;
               z-index: -1;

               @media($breakpoint_SM) {
                    top: 0;
               }

          }

          @media ($breakpoint_LG) {
               padding: 40px 15px 100px;
          }


          .__howit-1 {
               position: absolute;
               top: 12%;
               left: 20%;
               transform: rotate(290deg);

               img {
                    width: 150px;
                    object-fit: contain;
               }
          }

          .info-row-row {
               row-gap: 20px;
          }

          .heading-sec {
               text-align: center;

               h2 {
                    font-weight: 600;
               }

               .react-tabs__tab-list {
                    margin-bottom: 0;
                    border: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                    margin-top: 40px;


                    .react-tabs__tab {
                         background-color: var(--y-card);
                         border-radius: 30px;
                         padding: 10px 30px;
                         bottom: 0 !important;
                         border: 1px solid #fffefa2b;
                         font-size: 14px;


                         &::after {
                              display: none;
                         }
                    }

                    .react-tabs__tab--selected {
                         background-color: var(--zs-totiya);
                         color: var(--txt-white);
                    }
               }
          }

          .info-row {
               margin-top: 40px;
          }

          .__info-card {
               background-color: var(--y-card);
               padding: 30px;
               border-radius: 50px;
               height: 100%;

               .__top {
                    margin-bottom: 30px;
                    padding-bottom: 30px;
                    border-bottom: 1px solid #cbcbcb;

               }

               h6 {
                    margin-bottom: 10px;
               }

               h2 {
                    margin-bottom: 20px;
                    margin-bottom: 0;
                    font-size: 27px;

                    small {
                         font-size: 14px;

                         svg {
                              font-size: 19px;
                         }
                    }

                    .__profit {
                         color: var(--zs-totiya);
                    }

                    .__loss {
                         color: var(--bs-danger);
                    }
               }


          }

          .__info-card-shadow {

               .__address {

                    h6 {
                         font-size: 20px;
                    }

                    p {
                         font-size: 14px;
                         opacity: 0.8;
                    }

                    h1 {
                         font-size: 20px;
                         margin: 20px 0;

                         @media ($breakpoint_SM) {
                              font-size: 15px;
                              word-break: break-all;

                         }
                    }

                    .address-btn {
                         display: flex;
                         align-items: center;
                         justify-content: flex-start;
                         gap: 0;

                         .copy-btn {
                              background-color: transparent;
                              padding: 3px;
                              border: 0;
                              // border: 1px solid #ffffff1c;
                              color: var(--txt-black);
                              border-radius: 10px;
                              outline: 0;
                              box-shadow: unset;
                              transition: all 0.3s linear;

                              svg {

                                   @media ($breakpoint_SM) {
                                        font-size: 15px;
                                   }
                              }

                              &:hover {
                                   color: var(--zs-totiya);
                              }


                         }
                    }
               }
          }

          .table-sec {
               background-color: var(--y-card);
               padding: 30px;
               border-radius: 30px;
               overflow: scroll;


               .table-sec-responsive {
                    @media ($breakpoint_MD) {
                         width: 1200px;
                    }

                    @media ($breakpoint_SM) {
                         width: 700px;
                    }
               }

               .table-tr {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px;
                    border-bottom: 1px solid #9593932b;

                    &:last-child {
                         border-bottom: 0;
                    }

                    .info_coin {
                         display: flex;
                         align-items: center;
                         justify-content: flex-start;
                         gap: 10px;

                         .__img-circle {
                              width: 45px;
                              height: 45px;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              padding: 5px;
                              background-color: var(--zs-totiya);
                              border-radius: 50%;

                              svg {
                                   color: var(--txt-white);
                              }

                              img {
                                   width: 100%;
                                   height: 100%;
                              }
                         }

                         h6 {
                              margin-bottom: 0;
                         }
                    }

                    .price_coin {
                         p {
                              margin-bottom: 0;
                         }
                    }

                    .trade_coin {
                         display: flex;
                         align-items: center;
                         justify-content: flex-start;
                         gap: 10px;

                         a {

                              text-decoration: none;
                         }

                         p {
                              margin-bottom: 0;
                         }
                    }





               }



          }

          .table-btn-sec {
               display: flex;
               align-items: center;
               justify-content: center;
               margin-top: 50px;

               .btn {
                    background-color: var(--zs-totiya);
                    border: 1px solid transparent;
                    padding: 10px 20px;
                    border-radius: 50px;
                    white-space: nowrap;
                    transition: all 0.3s linear;
                    font-size: 14px;
                    text-transform: capitalize;
                    color: var(--txt-white);

                    &:hover {
                         background-color: transparent;
                         border-color: var(--zs-totiya);
                         color: var(--zs-totiya);
                    }
               }
          }
     }


}


.__register-page {
     height: calc(100vh - 129px);
     width: 100%;
     position: relative;
     z-index: 1;

     &::after {
          content: "";
          position: absolute;
          top: 10%;
          right: -70px;
          width: 70%;
          height: 100%;
          background: radial-gradient(50% 50% at 50% 50%, #028144 0%, rgba(14, 234, 174, 0) 100%);
          background-position: bottom;
          background-repeat: no-repeat;
          background-size: contain;
          z-index: -1;

          @media ($breakpoint_MD) {
               right: 0;
               top: 50%;
               width: 100%;

          }

     }

     @media ($breakpoint_MD) {
          height: 100vh;
     }

     .leaves-single-gh {
          position: absolute;
          bottom: 8%;
          right: 46%;
          transform: rotate(-63deg);

          @media ($breakpoint_LG){
               right: 10%;
               // z-index: -1;
          }


          @media ($breakpoint_MD) {
               display: none;
          }

          img {
               width: 200px;
               object-fit: contain;
          }
     }

     .__left-register {
          margin-top: 20px;
          // background: var(--y-card);
          // padding: 15px;
          // border-radius: 15px;

          h2 {
               font-size: 28px;
               padding-right: 100px;
               margin-bottom: 20px;

               span {
                    color: var(--zs-totiya) !important;
               }

               @media ($breakpoint_LG) {
                    padding-right: 0;
               }
          }

          form {
               width: 60%;

               @media ($breakpoint_MD) {
                    width: 100%;
               }

               .lists {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    gap: 10px;

                    label {
                         display: flex;
                         align-items: center;
                         justify-content: flex-start;
                         padding: 10px 15px;
                         background-color: #f4f3f2;
                         border: 1px solid #6fad86;
                         border-radius: 20px;
                         width: 100%;
                         cursor: pointer;
                         font-size: 14px;

                         input[type="radio"] {
                              appearance: none !important;
                              background-color: #ffffff14;
                              padding: 7px 10px;
                              inline-size: 25px;
                              block-size: 22px;
                              transition: all 0.3s linear;
                              margin-right: 20px;
                              border-radius: 20px !important;
                              margin-bottom: 0 !important;

                              &:hover,
                              &:focus {
                                   border-color: #01c047;
                              }

                              &:checked {
                                   background-color: var(--zs-totiya) !important;
                              }
                         }
                    }
               }
          }

          input,
          select {
               padding: 10px 20px;
               border-radius: 20px;
               width: 100%;
               display: block;
               background-color: transparent;
               border: 1px solid #6fad86;
               outline: 0;
               box-shadow: unset;
               color: var(--txt-black);
               font-size: 14px;
               transition: all 0.2s linear;
               margin-bottom: 25px;

               &:hover,
               &:focus {
                    border-color: var(--zs-totiya);
               }

               @media ($breakpoint_MD) {
                    width: 100%;
               }
          }

          .__conditions {
               margin-top: 20px;


               .checkbox-wrapper-24 {
                    margin-top: 15px;
                    text-transform: capitalize;

                    .checkbox {
                         display: table-cell;
                         width: 100%;
                         height: 100%;
                         vertical-align: middle;
                         text-align: center;
                    }

                    label {
                         display: inline-block;
                         color: var(--txt-white);
                         cursor: pointer;
                         position: relative;
                         font-size: 15px;

                         span {
                              display: inline-block;
                              position: relative;
                              background-color: transparent;
                              width: 28px;
                              height: 28px;
                              transform-origin: center;
                              border: 2px solid #333;
                              border-radius: 50%;
                              vertical-align: middle;
                              margin-right: 10px;
                              transition: background-color 150ms 200ms, transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
                         }
                    }

                    label span:before {
                         content: "";
                         width: 0px;
                         height: 2px;
                         border-radius: 2px;
                         background: #333;
                         position: absolute;
                         transform: rotate(45deg);
                         top: 12px;
                         left: 9px;
                         transition: width 50ms ease 50ms;
                         transform-origin: 0% 0%;
                    }

                    label span:after {
                         content: "";
                         width: 0;
                         height: 2px;
                         border-radius: 2px;
                         background: #333;
                         position: absolute;
                         transform: rotate(305deg);
                         top: 15px;
                         left: 11px;
                         transition: width 50ms ease;
                         transform-origin: 0% 0%;
                    }


               }


               .checkbox-wrapper-24 label:hover span:before {
                    width: 5px;
                    transition: width 100ms ease;
               }

               .checkbox-wrapper-24 label:hover span:after {
                    width: 10px;
                    transition: width 150ms ease 100ms;
               }

               .checkbox-wrapper-24 input[type=checkbox] {
                    display: none;
               }

               .checkbox-wrapper-24 input[type=checkbox]:checked+label span {
                    background-color: var(--zs-totiya);
                    transform: scale(1.25);
               }

               .checkbox-wrapper-24 input[type=checkbox]:checked+label span:after {
                    width: 10px;
                    background: #000;
                    transition: width 150ms ease 100ms;
               }

               .checkbox-wrapper-24 input[type=checkbox]:checked+label span:before {
                    width: 5px;
                    background: #000;
                    transition: width 150ms ease 100ms;
               }

               // .checkbox-wrapper-24 input[type=checkbox]:checked+label:hover span {
               //      background-color: #333;
               //      transform: scale(1.25);
               // }

               // .checkbox-wrapper-24 input[type=checkbox]:checked+label:hover span:after {
               //      width: 10px;
               //      background: #fff;
               //      transition: width 150ms ease 100ms;
               // }

               // .checkbox-wrapper-24 input[type=checkbox]:checked+label:hover span:before {
               //      width: 5px;
               //      background: #fff;
               //      transition: width 150ms ease 100ms;
               // }

          }

          .agree_terms {
               display: flex;
               align-items: center;
               gap: 15px;
               margin-top: 15px;
               margin-left: 15px;
          }

          .submit-btn {
               margin-top: 20px;
               background-color: var(--zs-totiya);
               border-radius: 30px;
               padding: 10px 40px;
               color: var(--txt-white);
               transition: all 0.3s linear;

               &:hover {
                    background-color: transparent;
                    border-color: var(--zs-totiya);
                    color: var(--zs-totiya);
               }
          }
     }

     .__img-sec {
          // overflow: hidden;
          filter: drop-shadow(4px 4px 0 #fff);
          transition: all 0.1s linear;
          position: relative;
          z-index: 1;

          .__tree-img {
               position: absolute;
               top: -20px;
               left: -10px;
               width: 100%;
               height: 100%;
               display: flex;
               justify-content: flex-end;
               z-index: -1;
               transform: scale(1);

               img {
                    object-fit: contain;
                    height: 100%;
                    width: 100%;
               }
          }

          @media ($breakpoint_LG) {
               display: none;
          }

          img {
               width: 90%;
               height: 90%;
          }
     }

}

.--register {
     .Home_footer {
          display: none;
     }
}