.presentation_main {
    margin-top: 20px;
    font-family: $font_heading;
    color: var(--txt-white);

    .present_inner {
        background-color: var(--card);
        padding: 20px;
        border-radius: var(--card-radius);

        .present_row {
            row-gap: 20px;

            .present_card {
                border: var(--card-border);
                background: var(--card-in);
                color: var(--txt-white);
                border-radius: var(--card-radius);
                padding: 10px 20px;

                .card-body {
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    ---padding-preg: 20px 16px;


                    .image_logo {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        @media($breakpoint_MD){
                            width: 100%;
                        }


                        h5 {
                            padding: var(---padding-preg);
                            margin-bottom: 0;
                            display: none;

                            @media($breakpoint_MD){
                                border: 0;
                            }
                        }

                        p {
                            margin-bottom: 0;
                            margin-left: 20px;
                        }

                        img {
                            width: 50px;
                        }
                    }

                    .country_sec {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        @media($breakpoint_MD){
                            display: none;
                        }

                        img {
                            width: 50px;

                            @media($breakpoint_MD){
                                width: 45px;
                            }
                        }

                        h6{
                            margin: 0;
                        }
                    }

                    .new_button_css_dashboard{
                        position: relative;

                        @media ($breakpoint_MD){
                            padding: 10px 20px !important;
                        }

                        @media ($breakpoint_SM){
                            padding: 10px 15px !important;
                            font-size: 14px;
                        }

                        span{

                            @media ($breakpoint_SM){
                                display: none;
                            }

                        }
                        

                        &::after{
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: transparent;
                            
                        }

                    }

                    .new_button_css_dashboard[type='button']{
                        cursor: not-allowed;
                    }

                }
            }
        }
    }
}