.all_reposrts_sec {
    margin: 30px 0;

    .dashboard__wrapper {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        padding: 0 0 3.2142857143rem;
    }

    .accounts-list {
        display: flex;
        flex-direction: column;
        gap: 1.4285714286rem;
    }

    .accounts-list__element {
        align-items: center;
        background: #f0f0f8;
        border-radius: 2.1428571429rem;
        box-shadow: 0 0 0 0.1428571429rem #14969900, 1.0714285714rem 1.0714285714rem 4.7142857143rem 0 #b4c1d578, -1.0714285714rem -1.0714285714rem 2.8571428571rem 0 #ffffff0d;
        display: grid;
        grid-template-columns: 3fr 3fr;
        min-height: 9.2857142857rem;
        padding: 1.4285714286rem 3rem;
        position: relative;
        text-decoration: none !important;
        transition: box-shadow .2s;
    }

    .accounts-list__element--name {
        align-items: center;
        color: #004141;
        display: flex;
        font-family: Montserrat, sans-serif;
        font-size: 1.6669285714rem;
        font-style: normal;
        font-weight: 700;
        gap: .4285714286rem;
        line-height: 142.623%;
    }

    .accounts-list__element--name>svg {
        height: 1.4285714286rem;
        width: 2rem;
    }

    .accounts-list__element--type {
        color: #7ba5a5;
        font-family: Montserrat, sans-serif;
        font-size: 1.1428571429rem;
        font-style: normal;
        font-weight: 500;
        line-height: 142.623%;
    }

    .accounts-list__element--info {
        align-items: center;
        display: flex;
        gap: 2rem;
        justify-content: flex-end;
    }

    .accounts-list__element--balance {
        background: linear-gradient(270deg, #4fd1d10d, #20acac0d);
        border-radius: .7142857143rem;
        color: #004141;
        font-family: Montserrat, sans-serif;
        font-size: 2.6481428571rem;
        font-style: normal;
        font-weight: 500;
        line-height: 142.623%;
        padding: .3571428571rem 1.7857142857rem 0;
    }

    .accounts-list__element--balance span {
        font-size: 1.6854285714rem;
        opacity: .5;
    }

    .accounts-list__element--arrow {
        background: #0000;
        border: none;
        cursor: pointer;
        padding: 0;
    }

    .accounts-list__element--arrow svg {
        float: left;
    }

    .accounts-list__footer {
        margin: auto 0 0 auto;
    }

    .accounts-list__total {
        align-items: center;
        background: #f0f0f8;
        border-radius: 30px;
        box-shadow: 1.0714285714rem 1.0714285714rem 4.7142857143rem 0 #b4c1d578, -1.0714285714rem -1.0714285714rem 2.8571428571rem 0 #ffffffc7;
        display: flex;
        gap: 7.1428571429rem;
        padding: 2.3571428571rem 3.0714285714rem;
    }

    .accounts-list__total b {
        color: #004141;
        font-family: Montserrat, sans-serif;
        font-size: 1.6669285714rem;
        font-style: normal;
        font-weight: 700;
        line-height: 142.623%;
    }

    .accounts-list__element--balance {
        background: linear-gradient(270deg, #4fd1d10d, #20acac0d);
        border-radius: .7142857143rem;
        color: #004141;
        font-family: Montserrat, sans-serif;
        font-size: 2.6481428571rem;
        font-style: normal;
        font-weight: 500;
        line-height: 142.623%;
        padding: .3571428571rem 1.7857142857rem 0;
    }

    .accounts-list__element--balance span {
        font-size: 1.6854285714rem;
        opacity: .5;
    }
}