.index-header {
    --commonRadius: 30px;

    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    padding: 30px 20px;
    font-family: $font_heading;
    z-index: 3;


    @media ($breakpoint_SM){
        padding: 0;
    }

    .container-index{
        @media ($breakpoint_SM){
            padding: 0;
        }
    }

    .header-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        padding-left: 94px;
        border-radius: var(--commonRadius);
        background-color: var(--zs-banner);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        color: var(--txt-white);
        position: relative;
        z-index: 1;

        @media ($breakpoint_LG) {
            justify-content: flex-end;
            gap: 30px;
        }

        @media ($breakpoint_SM){
            justify-content: space-between;
            border-radius: 0;
            gap: 0;
            padding: 10px;
        }


        .left-area-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .header-logo {

                a {
                    text-decoration: none;
                    color: var(--txt-white);
                    display: inline-block;

                    img {
                        width: 69px;
                        position: absolute;
                        top: 0px;
                        left: 0%;
                        height: auto;
                        border: 0px solid #ff000000;
                        border-radius: 50%;
                        outline: 12px solid black;
                        z-index: -1;
                        background-color: var(--zs-banner);

                        @media ($breakpoint_SM){
                            position: static;
                            background-color: transparent;
                            border: unset;
                            outline: 0;
                            width: 55px;
                        }
                    }
                }
            }
        }

        .lg__size {
            display: flex;
            align-items: center;
            justify-content: space-between;
            list-style-type: none;
            margin: 0;
            padding: 0;

            .nav-item {

                .nav-link {
                    color: var(--txt-white);
                    text-decoration: none;


                    @media ($breakpoint_LG) {
                        font-size: 14px;
                        padding: 5px 8px;

                    }

                    @media ($breakpoint_MD) {
                        display: none;

                    }
                }

                .toggle_btn {
                    display: none;
                    background: transparent;
                    border: none;
                    color: var(--primary-color);

                    @media ($breakpoint_MD) {
                        display: block;
                    }
                }
            }

            .btn_item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;

                .nav-button {
                    display: inline-block;
                    padding: 8px 30px;
                    background-color: var(--primary-color);
                    border: 1px solid transparent;
                    text-decoration: none;
                    border-radius: var(--commonRadius);
                    color: var(--txt-white);
                    font-size: 14px;
                    transition: all 0.3s linear;

                    @media ($breakpoint_LG) {
                        padding: 8px 24px;
                    }
                    @media ($breakpoint_SM) {
                        padding: 8px 16px;
                    }
                    @media ($breakpoint_50) {
                        padding: 8px 16px;
                    }

                    // @media ($breakpoint_SM) {
                    //     display: none;
                    // }

                    &:nth-child(2) {
                        background-color: transparent;
                        border-color: var(--primary-color);
                        color: var(--primary-color);
                        margin-left: 10px;

                        @media(max-width : 576px){
                            // display: none;
                        }
                    }

                    @media(max-width : 576px){
                        // display: none;
                    }
                }

                .for-position {
                    position: relative;

                    @media ($breakpoint_SM){
                        display: none;
                    }

                    .nav-swap {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        width: 2.5rem;
                        height: 2.5rem;
                        overflow: hidden;

                        @media ($breakpoint_LG) {
                            width: 30px;
                            height: auto;
                        }

                        img {
                            width: 100%;
                        }

                    }

                    .n-ppost-name {
                        padding: 15px;
                        border-radius: 20px;
                        position: absolute;
                        top: 110%;
                        left: -200%;
                        background: var(--primary-color);
                        text-align: center;
                        margin-top: 10px;
                        width: 314px;
                        opacity: 0;
                        -webkit-transform: translate3d(0, -15px, 0);
                        transform: translate3d(0, -15px, 0);
                        -webkit-transition: all 150ms linear;
                        -o-transition: all 150ms linear;
                        transition: all 150ms linear;
                        visibility: hidden;
                        backdrop-filter: blur(6px);
                        z-index: 3;

                        @media ($breakpoint_SM) {
                            right: -240%;
                            left: initial;
                        }

                        @media ($breakpoint_ESM) {
                            width: 200px;
                        }

                        h3 {
                            font-size: 18px;
                            color: black;
                            padding-bottom: 8px;
                            border-bottom: 1px solid rgba(0, 0, 0, 0.646);
                        }

                        p {
                            margin: 0;
                            font-size: 14px;
                            font-weight: 200;
                            color: black;
                        }
                    }


                    &:hover {
                        .n-ppost-name {
                            opacity: 1;
                            visibility: visible;
                            -webkit-transform: translate3d(0, 0, 0);
                            transform: translate3d(0, 0, 0);
                        }
                    }
                }


            }
        }
    }

}

//  Responsive header

.offcanvas {
    background-color: #171717;

    .btn-close {
        padding: 10px;
        font-size: 16px;
        color: white;
        background-color: var(--primary-color);
        border-radius: 10px;
        opacity: 1;
    }

    .offcanvas-body {
        .phone-View {
            padding: 0;

            li {
                list-style-type: none;
                margin-bottom: 14px;

                .nav-link {
                    padding: 8px 16px;
                    width: 100%;
                    background-color: transparent;
                    border: 1px solid transparent;
                    color: var(--primary-color);
                    font-size: 14px;
                    border-radius: var(--commonRadius);


                    &:hover {
                        background-color: #e8cc560d;
                        border: 1px solid #e8cc5647;
                    }
                }

                .nav-button {
                    display: inline-block;
                    padding: 8px 30px;
                    background-color: var(--primary-color);
                    border: 1px solid transparent;
                    text-decoration: none;
                    border-radius: var(--commonRadius);
                    color: var(--txt-white);
                    font-size: 14px;
                    transition: all 0.3s linear;
                    text-align: center;


                    @media ($breakpoint_LG) {
                        padding: 8px 24px;
                    }


                    &:nth-child(2) {
                        background-color: transparent;
                        border-color: var(--primary-color);
                        color: var(--primary-color);
                        margin-left: 10px;
                    }
                }
            }

            .nav-item_btn {
                display: none;
                align-items: center;
                justify-content: center;
                gap: 20px;

                @media ($breakpoint_SM) {
                    display: flex;
                }
            }
        }
    }
}