.support_main {
    margin-top: 20px;

    .support_inner {
        padding: 20px;
        overflow: hidden;
        font-family: $font_heading;
        border: 1px solid var(--card-border);
        background: var(--card);
        color: var(--txt-white);
        border-radius: var(--card-radius);
        width: 100%;
        height: 100%;

        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 15px;
            background-color: var(--card-in);
            margin-bottom: 20px;
            border: var(--card-border);
            border-radius: var(--card-radius);

            p {
                font-size: 18px;
                margin-bottom: 0;
                font-weight: 600;
                text-transform: capitalize;
            }
        }


        .support-section {
            padding: 0 20px;

            @media ($breakpoint_MD){
                padding: 0;
            }


            label {
                margin-bottom: 5px;
                opacity: 0.7;
                width: 100%;
                display: block;
                color: var(--txt-white);
                margin-left: 15px;

                @media ($breakpoint_MD){
                    margin-left: 0;
                }
            }

            .select_reason {
                width: 100%;

                .form-control {
                    background: transparent;
                    border: 1px solid var(--card-border);
                    border-radius: var(--card-radius);
                    color: #8f8f90;
                    font-size: 16px;
                    padding: 10px 20px;
                    box-shadow: unset !important;
                    transition: all 0.3s linear;

                    &:focus {
                        border-color: var(--primary-color);
                        color: var(--txt-white);
                    }

                    &::placeholder{
                        color: #8f8f90;
                    }

                    &:hover{
                        border-color: #ffffff69;
                    }

                   
                }

                input{
                    background: transparent;
                border: 1px solid var(--card-border);
                border-radius: var(--card-radius);
                color: #8f8f90;
                font-size: 16px;
                padding: 10px 20px;
                box-shadow: unset !important;
                transition: all 0.3s linear;
                width: 100%;

                &:focus {
                    border-color: var(--primary-color);
                    color: var(--txt-white);
                }

                &::placeholder{
                    color: #8f8f90;
                }

                &:hover{
                    border-color: #ffffff69;
                }
                }
            }

            .combo-sec{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-top: 20px;
                gap: 20px;

                @media ($breakpoint_MD){
                    flex-direction: column;
                    width: 100%;
                }

                .upload__data_tk {
                    width: 100%;
    
                    .h_1323 {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
    
                        .btn {
                            color: var(--primary-color);
                            cursor: pointer;
                            outline: 0;
                            box-shadow: unset;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 6px;
                            padding: 0;
                            margin-right: 10px;
    
                            svg {
                                font-size: 21px;
                            }
    
                        }
                    }
    
                    .uploads__heading {
                        display: block;
                    }
    
                    .uplodas__container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        flex-wrap: wrap;
                        padding: 0px 15px;
                        gap: 20px;
                        background: transparent;
                        border-radius: var(--card-radius);
                        border: 1px solid var(--card-border);
                        overflow: hidden;
                        opacity: 1;
                        cursor: pointer;
                        transition: all 0.3s linear;

                        &:hover{
                            border-color: #ffffff69;
                        }
    
                        span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin: 10px 0;
    
                            img {
                                border-radius: 10px;
                                width: 100%;
                                height: 109px;
                                object-fit: contain;
                            }
                        }
    
                        .browse {
                            opacity: 1 !important;
                        }
                    }
    
                    .main__show__images {
                        background: transparent;
                        border-radius: var(--card-radius);
                        border: 1px solid var(--card-border);
                        overflow: hidden;
                        height: 189px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 10px 0;
    
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            border-radius: 10px;
                        }
                    }
    
                }
            }

            .btn-sec{
                text-align: center;
                margin-top: 20px;
            }


           

        }
    }
}

.show__upload_images_modal {

    .show__23 {
        height: 100%;
        width: 100%;

        img {
            max-height: 500px;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}