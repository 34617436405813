.Investments_sec {

    .inner {
        text-align: center;
        color: white;

        img {
            height: 450px;
            width: auto;
            opacity: .8;

            @media ($breakpoint_LG) {
                height: 400px;
            }

            @media ($breakpoint_MD) {
                height: 334px;
            }

            @media ($breakpoint_SM) {
                height: 264px;
            }

            @media ($breakpoint_450) {
                height: 240px;
            }

            @media ($breakpoint_ESM) {
                height: 180px;
            }
        }

        h1 {
            font-size: 62px;
            font-weight: 600;
            margin: 0;
            font-family: $font_heading;

            @media ($breakpoint_LG) {
                font-size: 48px;
            }

            @media ($breakpoint_MD) {
                font-size: 42px;
                line-height: 40px;
                margin-bottom: 20px;
            }

            @media ($breakpoint_SM) {
                font-size: 34px;
                line-height: 34px;
            }

            @media ($breakpoint_450) {
                font-size: 28px;
                line-height: 34px;
            }

            @media ($breakpoint_ESM) {
                font-size: 24px;
                line-height: 34px;
            }

            svg {
                font-size: 62px;
                color: var(--primary-color);
                margin-right: 20px;

                @media ($breakpoint_MD) {
                    font-size: 38px;
                }

                @media ($breakpoint_SM) {
                    font-size: 30px;
                }
            }
        }

        span {
            color: transparent;
            background: var(--text_color);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    p {
        font-size: 18px;
        font-weight: 500;
        font-family: $font_pragph;

        @media ($breakpoint_450) {
            font-size: 16px;
            line-height: 34px;
        }

        @media ($breakpoint_ESM) {
            font-size: 14px;
            line-height: 24px;
        }
    }

    a {
        display: inline-block;
        padding: 8px 30px;
        background-color: var(--primary-color);
        border: 1px solid transparent;
        text-decoration: none;
        border-radius: var(--commonRadius);
        color: var(--txt-white);
        font-size: 14px;
        transition: all 0.3s linear;
        text-decoration: none;

        &:hover {
            background-color: transparent;
            border-color: var(--primary-color);
            color: var(--primary-color);
        }
    }
}