.__upload-form {

    .input-group {
        margin-bottom: 20px;
        width: 100%;

        .form-control {
            background: transparent;
            border: 1px solid var(--card-border);
            border-radius: var(--card-radius);
            color: #8f8f90;
            font-size: 14px;
            padding: 8px 15px;
            box-shadow: unset !important;
            transition: all 0.3s linear;
            border-radius: 20px !important;
            width: 100%;
        }

        .react-tel-input .selected-flag:hover,
        .react-tel-input .selected-flag:focus {
            background-color: transparent !important;
        }

        .react-tel-input {
            .flag-dropdown {
                background-color: transparent;
                border: 0 !important;

                .selected-flag {
                    width: 100px;
                    background-color: transparent !important;

                    .arrow {
                        left: 40px !important;
                    }
                }
            }
            .country-list .country .dial-code{
                color: var(--primary-color);
            }

            .country-list .country.highlight {
                background-color: #ebe7e729;
            }

            .country-list .country:hover {
                background-color: #f1f1f12e;
            }

            .react-tel-input .flag-dropdown.open .selected-flag {
                background-color: transparent !important;
            }

            .country-list {
                background-color: #2b2f32;
                width: 400px;
            }

          
        }

        .__boxupload{
            height: 150px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            border: 1px solid var(--card-border);
            border-radius: var(--card-radius) !important;
            width: 100%;

        }
    }

}