@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;1,9..40,100;1,9..40,200;1,9..40,300&family=Lato:wght@300;400;700;900&family=Outfit:wght@200;300;400;500;600;700;800;900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600&display=swap');
@import 'react-responsive-modal/styles.css';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


// font-family: 'DM Sans', sans-serif;
// font-family: 'Lato', sans-serif;
// font-family: 'Outfit', sans-serif;
// font-family: 'Titillium Web', sans-serif;



$font_heading: 'Titillium Web',
sans-serif;
$font_pragph: "Montserrat",
sans-serif;
$font_btn: 'Outfit',
sans-serif;




//Breakpoints....
$breakpoint_XXXL: 'max-width: 1500px';
$breakpoint_XXL: 'max-width: 1400px';
$breakpoint_XL: 'max-width: 1200px';
$breakpoint_LG: 'max-width: 992px';
$breakpoint_MD: 'max-width: 768px';
$breakpoint_SM: 'max-width: 576px';
$breakpoint_450: 'max-width: 450px';
$breakpoint_ESM: 'max-width: 385px';
$breakpoint_50: 'max-width: 350px';

$sidebar_width: 300px;
$body: var(--bodybg);

body {
     background-color: black;

}

main {
     min-height: calc(100vh - 100px);
     -webkit-transition: margin-left 0.3s ease;
     -o-transition: margin-left 0.3s ease;
     transition: margin-left 0.3s ease;
     overflow: hidden;
     padding: 100px 100px 0px;
     max-width: 1280px;
     width: 100%;
     position: relative;
     margin: 0 auto 20px;

     @media ($breakpoint_LG) {
          padding: 100px 80px 0;
     }

     @media($breakpoint_MD) {
          padding: 100px 20px 0;
     }

     @media($breakpoint_SM) {
          padding: 100px 15px 0;
     }
}

@mixin display($justify-content, $align-items) {
     display: flex;
     justify-content: $justify-content;
     align-items: $align-items;
}

.main_view {
     background-color: #000;
}

.button_style {
     display: inline-block;
     padding: 10px 30px;
     color: var(--txt-white);
     border-radius: 24px;
     border: none;
     font-size: 14px;
     background: var(--primary-color);
     border: 1px solid transparent;
     outline: none;
     text-decoration: none;
     white-space: nowrap;
     transition: all 0.4s linear;

     &:hover {
          background-color: transparent;
          color: var(--primary-color);
          border-color: currentColor;
     }
}


.coustom_container {
     max-width: 1600px;
     width: 100%;
     margin: 0 auto;
     position: relative;


     @media ($breakpoint_XL) {
          padding: 0 40px;
     }

     @media ($breakpoint_LG) {
          padding: 0 30px;
     }

     @media ($breakpoint_MD) {
          padding: 0 18px;
     }

     @media ($breakpoint_SM) {
          padding: 0 15px;
     }
}

.site_custom_container_new {
     max-width: 100%;
     width: 100%;
     margin: 0 auto;
     padding: 0 100px;

     @media ($breakpoint_XL) {
          padding: 0 50px;
     }

     @media ($breakpoint_LG) {
          padding: 0 30px;
     }

     @media ($breakpoint_LG) {
          padding: 0 18px;
     }

     @media ($breakpoint_SM) {
          padding: 0 15px;
     }
}

.margin_top {
     margin-top: 92px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
}

input[type=number] {
     -moz-appearance: textfield;
}

.next_sec {
     display: flex;
     align-items: center;
     justify-content: center;
     margin: 40px 0;

     img {
          height: 25px;
          width: auto;
          opacity: .4;

          @media ($breakpoint_MD) {
               height: 20px;
          }

          @media ($breakpoint_SM) {
               height: 16px;
          }

          @media ($breakpoint_450) {
               height: 13px;
          }

          @media ($breakpoint_ESM) {
               height: 10px;
          }
     }
}

@mixin table_styles {
     .table_section {

          .scrolling_table {
               overflow-x: scroll;
               overflow-y: scroll;

               .scroll_under {
                    width: 100%;
                    // height: 306px;




                    .support_table {
                         border-collapse: separate;
                         border-spacing: 0 0px;
                         margin-bottom: 0;

                         thead {
                              position: sticky;
                              top: 0;
                              z-index: 1;
                              border-radius: 0;


                              .table_heading_row {
                                   .table_heading {
                                        font-size: 16px;
                                        font-weight: 600;
                                        padding: 15px;
                                        background: none;
                                        color: var(--txt-white);
                                        white-space: nowrap;
                                        border-bottom: 1px solid var(--border-color);


                                        @media ($breakpoint_SM) {
                                             padding: 15px 10px;
                                        }
                                   }
                              }
                         }

                         tbody {

                              tr {

                                   &:last-child {
                                        td {
                                             border-bottom: 0 !important;
                                        }
                                   }

                                   td {
                                        background-color: transparent !important;
                                        color: var(--txt-white);
                                        font-size: 14px;
                                        white-space: nowrap;
                                        padding: 15px 14px;
                                        border-color: var(--border-color);

                                   }
                              }


                              .table_data_row {
                                   transition: all 0.2s linear;
                                   border-bottom: var(--sky-blue);

                                   &:hover {
                                        background: #2b2f3259;
                                   }



                                   .table_data {

                                        @media ($breakpoint_SM) {
                                             padding: 15px 10px;
                                        }

                                        a {
                                             text-decoration: none !important;

                                             @media ($breakpoint_SM) {
                                                  padding: 5px 15px;
                                             }

                                        }

                                        .pic_text {
                                             display: flex;
                                             gap: 10px;

                                             img {
                                                  width: 25px;
                                             }
                                        }
                                   }

                                   .payment_fee {
                                        color: var(--primary-color);

                                        img {
                                             width: 100px;
                                        }
                                   }
                              }
                         }
                    }
               }
          }
     }
}

.acct-follow {
     display: flex;
     gap: 10px;


     .update-btn {
          border: none;
          background-color: var(--primary-color);
          color: var(--txt-white);
          padding: 10px 30px;
          border-radius: 10px;
          font-size: 16px;
     }

     .btn-cancel {
          background: var(--sidebar-bg) !important;
          color: var(--primary-color);
          border: 1px solid var(--sky-blue);
     }
}





// spinner css styles start

.spinner-main {
     width: 100%;
     height: 300px;
     background-color: transparent;
     display: flex;
     justify-content: center;
     align-items: center;
     position: absolute;
}

.spinner-main .spinnerWrap {
     width: 100%;
}

.spinner_ds {
     height: 300px;
}

.spinner-container>div {
     width: 100%;
     aspect-ratio: 1/1;
     display: flex;
     align-items: center;
     justify-content: center;
}

.spinner {
     margin: auto;
     width: 50px;
     height: 50px;
     background: var(--spinner-bg);
}


#spinner5 {
     position: relative;
     animation: deform 2s linear infinite;
}

@keyframes deform {
     0% {
          border-radius: 0%;
          transform: rotate(0deg) scale(1);
     }

     50% {
          border-radius: 100%;
          transform: rotate(180deg) scale(0.8);
     }

     100% {
          border-radius: 0%;
          transform: rotate(360deg) scale(1);
     }
}


.content .spinner-main {
     background-color: transparent;
     display: flex;
     justify-content: center;
     align-content: center;
     height: auto;
     width: auto;
     position: absolute;
     z-index: 99999;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
}

.spinner-main .spinnerWrap {
     width: 100%;
}

.spinner-main .spinnerWrap p {
     margin-top: 10px;
     font-size: 16px;
     font-weight: 600;
     display: flex;
     justify-content: center;
     align-items: flex-end;
}

.spinner-main .spinnerWrap p span {
     position: relative;
     top: -4px;
     left: -3px;
     display: block;
     width: 4px;
     height: 4px;
     border-radius: 3px;
     visibility: hidden;
     margin: 0 2px;
     animation: opecity 1s linear infinite;
     background-color: #000;
}

.spinner-main .spinnerWrap p .dot-1 {
     animation-delay: 0.3s;
}

.spinner-main .spinnerWrap p .dot-2 {
     animation-delay: 0.4s;

}

.spinner-main .spinnerWrap p .dot-3 {
     animation-delay: 0.5s;

}

.spinner-main .spinnerWrap p .dot-4 {
     animation-delay: 0.6s;
}



@keyframes opecity {
     0% {
          visibility: hidden;
     }

     100% {
          background: var(--liner-bg);
          visibility: visible;
     }

     /* 100%{
      visibility: hidden;
    } */

}

// spinner css styles end 
.loader_main {
     position: relative;
     z-index: 1;

     .spinner_bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #f2eeeea1;
          z-index: 2;
          border-radius: 10px;

          .color_ring {
               position: absolute;
               left: 50%;
               top: 50%;
               height: 100%;
               width: 100%;
               transform: translate(-50px, -50px);
          }
     }

}


.Loading__ {
     height: 100%;
     width: 100%;
     top: 0;
     left: 0;
     position: absolute;
     background-color: var(--Loading_body);
     display: flex;
     align-items: center;
     justify-content: center;
     z-index: 9999;
     backdrop-filter: blur(3px);




     .color_ring {
          width: 20%;

          p {
               text-align: center;
               color: var(--txt-white);
               font-family: $font_heading;
               font-size: 22px;
               margin-top: 20px;
          }
     }

     .palestTulip {
          fill: #f1ede700;
     }

     .paleTulip {
          fill: #f4d3a040;
     }

     .tulip {
          fill: #f4d3a0cc;
     }

     .darkTulip {
          fill: #e4ba78cc;
     }

     .darkestTulip {
          fill: var(--primary-color);
     }

     .mouse {
          fill: transparent;
     }

     .turn-01,
     .turn-02,
     .turn-03,
     .turn-04,
     .turn-05,
     .turn-06 {
          animation-duration: 8s;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;
          transform-origin: 50% 50%;
     }

     .turn-01 {
          animation-name: turnOne;
     }

     .turn-02 {
          animation-name: turnTwo;
     }

     .turn-03 {
          animation-name: turnThree;
     }

     .turn-04 {
          animation-name: turnFour;
     }

     .turn-05 {
          animation-name: turnFive;
     }

     @keyframes turnOne {
          0% {
               transform: rotate(0deg);
          }

          100% {
               transform: rotate(180deg);
          }
     }

     @keyframes turnTwo {
          0% {
               transform: rotate(0deg);
          }

          100% {
               transform: rotate(360deg);
          }
     }

     @keyframes turnThree {
          0% {
               transform: rotate(0deg);
          }

          100% {
               transform: rotate(540deg);
          }
     }

     @keyframes turnFour {
          0% {
               transform: rotate(0deg);
          }

          100% {
               transform: rotate(720deg);
          }
     }

     @keyframes turnFive {
          0% {
               transform: rotate(0deg);
          }

          100% {
               transform: rotate(900deg);
          }
     }
}



.pagination__2 {
     display: flex;
     list-style: none;
     align-items: center;
     justify-content: center;
     gap: 10px;
     margin-top: 30px;

     li {
          display: block;


          a[aria-disabled="true"] {
               cursor: not-allowed !important;
          }

          a[aria-label="Next page"] {
               cursor: e-resize;
          }

          a[aria-label="Previous page"] {
               cursor: w-resize;
          }

          a {
               display: flex;
               justify-content: center;
               align-items: center;
               width: 100%;
               height: 100%;
               background-color: var(--card-in);
               border: 1px solid transparent;
               padding: 10px 14px;
               border-radius: 10px;
               text-decoration: none;
               color: var(--txt-white);
               transition: all 0.3s linear;
               font-size: 16px;

               &:hover {
                    border-color: var(--card-border);
               }
          }
     }

     .active {
          a {
               border-color: var(--primary-color);
               background-color: #e8cc5652;
          }
     }
}

// marquee section work...

.banner-news {
     display: flex;
     align-items: center;
     margin-bottom: 10px;

     p {
          margin-bottom: 0;
          color: var(--txt-white);
     }

     .marquuqqq {
          // background-color: salmon;

          .rfm-marquee {
               min-width: 80% !important;
          }

     }

}

.banner_modal {
     text-align: center;
     color: var(--txt-black);


     .modal-heading {
          font-size: 20px;
          margin: 15px 0;
     }

}

.btn-square {
     background-color: transparent !important;
}

.otp-button {
     background-color: var(--primary-color);
     color: var(--txt-black);
     white-space: nowrap;
     padding: 9px 15px;
     transition: all 0.4s linear;
     border: 1px solid transparent;

     &:hover {
          background-color: transparent;
          color: var(--primary-color);
          border-color: currentColor;
     }
}


.color-ring {
     display: flex;
     justify-content: center;
     align-items: center;
     text-align: center;

     .loader-section {
          top: 0 !important;
          left: 0 !important;
          height: 20px !important;
          width: 20px !important;
          transform: scale(0.4);
          // margin-right: 20px;
     }


}



.btn_new {
     position: relative;
     padding: 7px 30px;
     font-size: 16px;
     color: white;
     transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
     border-top: 1px solid transparent !important;
     border-bottom: 1px solid transparent !important;
     border: 1px solid var(--primary-color);
     border-radius: 0;
     background-color: #f9da8b3d;
     white-space: nowrap;
}

.btn_new:before,
.btn_new:after {
     content: '';
     position: absolute;
     transition: inherit;
}

.btn_new:hover {
     color: white;
     border: 1px solid transparent;
     background-color: transparent;
     transition-delay: .080s;
     z-index: 1;
}

.btn_new:focus {
     box-shadow: none;
}

.btn_new:hover:before {
     transition-delay: 0s;
}

.btn_new:hover:after {
     background: var(--primary-color);
     transition-delay: .35s;
     border-radius: 5px;
     z-index: -1;
}

.from-right:before,
.from-right:after {
     top: 0;
     width: 0;
     height: 100%;
}

.from-right:before {
     left: 0;
     border: 1px solid var(--primary-color);
     border-left: 0;
     border-right: 0;
}

.from-right:after {
     right: 0;
}

.from-right:hover:before,
.from-right:hover:after {
     width: 100%;
}

.from-right:hover:before {
     width: 70%;
     left: 20px;
}




.border-top-left {
     position: absolute;
     top: 0;
     left: 0;
     width: 120px;
     height: 3px;
     background-color: var(--primary-color);
     z-index: -1;
     border-radius: 5px;
}

.border-top-left::after {
     content: "";
     position: absolute;
     height: 120px;
     width: 3px;
     background-color: var(--primary-color);
     top: 0;
     left: 0;
     border-radius: 5px;
}

.border-bottom-right {
     position: absolute;
     bottom: 0;
     right: 0;
     width: 120px;
     height: 3px;
     background-color: var(--primary-color);
     z-index: -1;
     border-radius: 5px;
}

.border-bottom-right::after {
     content: "";
     position: absolute;
     height: 120px;
     width: 3px;
     background-color: var(--primary-color);
     bottom: 0;
     right: 0;
     border-radius: 5px;
}

.border-top-right {
     position: absolute;
     top: 0;
     right: 0;
     width: 120px;
     height: 3px;
     background-color: var(--primary-color);
     z-index: -1;
     border-radius: 5px;
}

.border-top-right::after {
     content: "";
     position: absolute;
     height: 120px;
     width: 3px;
     background-color: var(--primary-color);
     top: 0;
     right: 0;
     border-radius: 5px;
}


.for_space {
     width: 2px;
     height: 76px;
     background-color: #f8da8b21;
}


@keyframes customEnterModalAnimation {
     0% {
          transform: translateX($sidebar_width);
     }

     100% {
          transform: translateX(0px);
     }
}

@keyframes customLeaveModalAnimation {
     0% {
          transform: translateX(0px);
     }

     100% {
          transform: translateX($sidebar_width);
     }
}






// commen css for inner dashboard



.table> :not(caption)>*>* {
     background-color: transparent;
}


.table_responsive {
     @media ($breakpoint_XL) {
          overflow-y: scroll;
          overflow-x: scroll;
     }
}

.all_tables {
     color: var(--txt-white);

     @media ($breakpoint_XL) {
          width: 1100px;
     }

     .thead_dashboard {

          th {
               background-color: #2b2b2b;
               color: var(--txt-white) !important;
               padding: 10px 20px;
               border-bottom: 0 !important;
               white-space: nowrap;

               @media ($breakpoint_SM) {
                    font-size: 14px;
                    padding: 10px;
               }

               &:first-child {
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
               }

               &:last-child {
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
               }


          }
     }

     .tbody_dashboard {

          tr {
               &:last-child {
                    td {
                         border: 0 !important;
                    }
               }
          }

          td {
               padding: 10px 10px;
               color: var(--txt-white);
               border-color: #fbfbfb29;
               vertical-align: middle;
               font-size: 15px;

               .btn {
                    border-radius: 20px;
                    padding: 6px 12px;
                    font-size: 14px;
               }

          }
     }
}



.new_button_css_dashboard {
     background: var(--primary-color);
     border: 1px solid var(--card-border);
     border-radius: var(--card-radius);
     color: var(--txt-black);
     font-family: $font_heading;
     padding: 10px 40px;
     position: relative;
     text-decoration: none !important;
     transition: 0.3s linear;
     white-space: nowrap;
     font-size: 16px;

     &:hover {
          background-color: transparent;
          color: var(--primary-color);
          border-color: var(--primary-color);
     }

}


.form_lable {
     font-size: 14px !important;
     font-weight: 500 !important;
     margin-left: 10px;
}

// .form-control {
//   background: #f4f5fb;
//   border: var(--dashboard_border);
//   border-radius: var(--dashboard_border_radius);
//   color: #8f8f90;
//   font-family: Roboto, sans-serif;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 100%;
//   padding: .8571428571rem 1.8571428571rem;
//   transition: .2s;

//   &:focus {
//     background: #f0f0f8;
//     box-shadow: 0 0 0 0.1428571429rem #e1ffff00, 0.9285714286rem 0.9285714286rem 2.1428571429rem 0 #b4c1d552, -1.0714285714rem -1.0714285714rem 2.8571428571rem 0 #ffffffe6, inset 0.5714285714rem 0.5714285714rem 1.1428571429rem 0 #b4c1d500, inset -0.5714285714rem -0.5714285714rem 1.1428571429rem 0 #fff0;
//   }
// }

// .form-control[type=text]:not(:placeholder-shown),
// .form-control[type=text]:not(:placeholder-shown):focus {
//   background: transparent;
//   box-shadow: 0 0 0 0.1428571429rem #119fff, 0.9285714286rem 0.9285714286rem 2.1428571429rem 0 #b4c1d552, -1.0714285714rem -1.0714285714rem 2.8571428571rem 0 #ffffffe6, inset 0.5714285714rem 0.5714285714rem 1.1428571429rem 0 #b4c1d500, inset -0.5714285714rem -0.5714285714rem 1.1428571429rem 0 #fff0;
//   color: var(--primary-color);
// }


.new_heading {
     background: #f0f0f8;
     box-shadow: 0 0 0 0.1428571429rem #e1ffff00, 0.9285714286rem 0.9285714286rem 2.1428571429rem 0 #b4c1d552, -1.0714285714rem -1.0714285714rem 2.8571428571rem 0 #ffffffe6, inset 0.5714285714rem 0.5714285714rem 1.1428571429rem 0 #b4c1d500, inset -0.5714285714rem -0.5714285714rem 1.1428571429rem 0 #fff0;
     border-radius: .7142857143rem;
     font-size: 16px;
     font-style: normal;
     font-weight: 500;
     height: 3.714286rem;
     line-height: 100%;
     padding: .8571428571rem 1.8571428571rem;
     transition: .2s;
}

.no_data_tr {
     td {
          border: 0;
     }
}

.no_result_found_sec {
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     text-align: center;
     color: var(--txt-white);
     margin-top: 40px;

     td {
          border: 0 !important;
     }

     .__icon-img {
          color: var(--primary-color);
          margin-bottom: 20px;

          svg {
               font-size: 44px;
          }
     }

     p {
          opacity: 0.8;
     }
}

.sub-header-flex {
     display: flex;
     align-items: center;
     justify-content: space-between;

     p {
          margin-bottom: 0;
          font-size: 22px;


          @media($breakpoint_MD) {
               font-size: 14px;
          }

     }
}

.__waitingScreen {
     height: 100vh;
     width: 100%;
     display: flex;
     align-items: center;
     justify-content: center;

     .__card {

          img {
               mix-blend-mode: darken;
               width: 70%;
               height: 70%;
               object-fit: contain;
               display: block;
               margin: auto;

               @media ($breakpoint_SM) {
                    height: 40%;
                    width: 40%;
               }
          }

     }
}



.ScratchCard__Container {


     .ScratchCard__Canvas {
          background-color: #cfced6;
          background-image: linear-gradient(to right, #cfced6, #e0dfe6, #efeef3, #e0dfe6, #cfced6);
     }
}

.scratch-card-container {
     width: 320px;
     height: 226px;
     position: relative;
     border-radius: 10px;
     overflow: hidden;
     margin: 20px auto;
}

.scratch-card {
     position: relative;
     width: 100%;
     height: 100%;

     img {
          width: 100%;
          height: 100%;
          object-fit: cover;
     }
}

canvas {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     cursor: crosshair;
}

.reward {
     display: flex;
     align-items: center;
     justify-content: center;
     width: 100%;
     height: 100%;
     background-color: #ffffff;
     font-size: 1.5rem;
     font-weight: bold;
     color: #2f4653;
     text-align: center;
}