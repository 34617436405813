.react-responsive-modal-container {
    @media ($breakpoint_LG) {

        display: flex;
        place-items: center;
        justify-content: center;
    }
}



// mobile view header 


@keyframes customEnterModalAnimation {
    0% {
        transform: translateX($sidebar_width);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes customLeaveModalAnimation {
    0% {
        transform: translateX(0px);
    }

    100% {
        transform: translateX($sidebar_width);
    }
}


.Header__sidebar__right {
    .right_header-sidebar {
        h4 {
            color: var(--txt-white);
            padding: 15px;
        }
    }

    .react-responsive-modal-closeButton {
        svg {
            fill: var(--txt-white);
        }
    }
}




.react-responsive-modal-overlay,
.Trading__coinLIst_overlay {
    background-color: #18191ac7 !important;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(3px);
}

.react-responsive-modal-closeButton {
    svg {
        fill: var(--primary-color) !important;

        &:focus {
            border: none;
            outline: none;
        }
    }


    &:focus {
        border: none;
        outline: none;
    }
}



$HEaderSIdebarWidth: 300px;

.Header__sidebar__right {
    width: 60%;
    max-width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    z-index: 9999999999999999999;
    background-color: #0f2e4e !important;

    @media ($breakpoint_MD) {
        width: 80%;
    }

    // overflow-y: hidden !important;
    .right_header-sidebar {
        .right_header {

            display: none !important;

            @media ($breakpoint_SM) {
                display: flex !important;
                flex-direction: column;
            }

            .for_space {
                @media ($breakpoint_SM) {
                    display: none;
                }
            }
        }
    }

    .public_logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 18px;

        a {
            text-decoration: none;
        }

        .web-logo {
            &:focus-visible {
                outline: none;
            }

            img {
                width: auto;
                height: 80px;
            }

            @media($breakpoint_ESM) {
                margin-right: 0px;

                p {
                    font-size: 12px !important;
                }
            }

            @media($breakpoint_SM) {
                margin-right: 0px;

                p {
                    font-size: 12px !important;
                }
            }




            @media ($breakpoint_SM) {
                width: 50px;
            }

            p {
                font-size: 1.5vw;
                display: inline-block;
                white-space: nowrap;
            }
        }
    }



    .right_header-sidebar {
        ol {
            margin-top: 40px;
            padding: 0;

            li {
                position: relative;
                list-style-type: none;

                // overflow: hidden;
                .drop-link {
                    color: var(--txt-white);
                    text-decoration: none;
                    width: 100%;
                    display: block;
                    font-size: 14px;
                    box-shadow: unset;
                    outline: none;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    background-color: transparent;
                    padding: 10px;
                    margin: 0 0 10px 0;
                    border-radius: 6px;
                    overflow: hidden;
                    height: 45px;
                    border: 1px solid var(--primary-color);

                    .drop-link-data {
                        @include display(left, center);

                        .text-overflow {
                            width: calc($HEaderSIdebarWidth - 50%);
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        svg {
                            margin-right: 10px;
                            transition: all 0.3s linear;
                        }
                    }

                    svg {
                        transition: all 0.3s linear;
                    }

                    .inner {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 20px;

                        svg {
                            transform: rotate(0deg);
                            font-size: 20px;
                        }

                        .nft {
                            fill: var(--primary-color);

                        }
                    }
                }

                .header_mobail {
                    height: 0;
                    transition: all 0.4s linear;
                    overflow: hidden;

                    .nav__dropa {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        flex-direction: column;
                        padding: 0;
                        margin: -10px 0 20px 0;

                        .nav-link {
                            background-color: transparent;
                            padding: 10px;
                            display: block;
                            width: 100%;

                            color: var(--txt-white);
                        }
                    }
                }

                .show {
                    transition: all 0.1s linear;

                    svg {
                        transform: rotate(90deg);
                    }
                }
            }

            // .drop-itemOnclick{
            //     .drop-link{
            //         margin: 0 ;
            //     }
            // }

            .nav__item_dropw {
                .header_mobail {
                    display: block;
                    position: relative;
                    margin: 10px 0;
                }
            }
        }
    }
}

.react-responsive-modal-closeButton {
    top: 38px !important;
    right: 23px !important;
}


.react-responsive-modal-overlay {}


.lang_modal {
    max-width: 1000px !important;
    width: 100%;
    background: #f0f0f8 !important;
    border-radius: var(--commonRadius);
    box-shadow: 0 0 4.7142857143rem 0 #b4c1d578, 0 0 2.8571428571rem 0 #ffffff7a !important;
    text-align: center !important;
    padding: 40px !important;

    .react-responsive-modal-closeButton {
        background-color: transparent;
        border: 3px solid var(--primary-color);
        border-radius: 50%;
        padding: 8px;

        svg {
            fill: var(--primary-color);
            width: 32px;
            height: 32px;
        }
    }

    .heading {
        h1 {
            color: #8f8f90;
            display: block;
            font-family: $font_heading;
            font-size: 1.8821428571rem;
            font-weight: 500;
            line-height: 140%;
            margin-bottom: 3.5714285714rem;
            text-align: center;
        }
    }

    .bottom_links {
        .sign-modal__list--item {
            background: #0000;
            border: none;
            border-bottom: 1px solid #e4ba7975;
            color: var(--primary-color);
            cursor: pointer;
            display: block;
            font-family: $font_heading;
            font-size: 3.9644285714rem;
            font-weight: 700;
            line-height: 118.243%;
            padding: 2rem 0;
            position: relative;
            text-align: center;
            transition: color .2s;
            width: 100%;

            &:hover {
                span {
                    opacity: 1;
                    translate: 0 -50%;
                }
            }

            span {
                background: linear-gradient(268deg, #0000, #0000 52.74%), linear-gradient(270deg, #ffe2b4, #e4ba79);
                border-radius: 10px;
                box-shadow: 4px 4px 20px 0 #6f8cb066, -6px -6px 20px 0 #fff, 2px 2px 4px 0 #728eab30;
                opacity: 0;
                padding: .4285714286rem 1.4285714286rem;
                position: absolute;
                right: 0;
                top: 50%;
                transition: .2s;
                translate: -20% -50%;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    fill: white;
                }
            }
        }
    }
}

.wallet_modal {
    max-width: 550px !important;
    width: 100%;
    background: #242526 !important;
    border-radius: var(--commonRadius);
    box-shadow: 0 0 4.7142857143rem 0 #0000008a, 0 0 2.8571428571rem 0 #000000b8 !important;
    text-align: center !important;
    padding: 30px !important;

    .react-responsive-modal-closeButton {
        background-color: transparent;
        border-radius: 50%;
        padding: 4px;
        top: 14px !important;
        right: 14px !important;

        svg {
            fill: var(--primary-color);
            width: 32px;
            height: 32px;
        }
    }

    .heading {
        h2 {
            color: white;
            display: block;
            font-family: "Titillium Web", sans-serif;
            font-size: 28px;
            font-weight: 500;
            margin-bottom: 30px;
            text-align: start;
        }
    }

    .inner_info {
        .bot-bot {
            align-items: center;
            background-color: #4c4c4d;
            border-radius: 15px;
            color: #fff;
            display: flex;
            gap: 20px;
            margin-bottom: 20px;
            padding: 20px 15px;
            position: relative;
            transition: all .3s linear;
            text-align: start;
            cursor: pointer;
            overflow: hidden;

            &:hover {
                img {
                    right: 0;
                    opacity: 1;
                }
            }

            img {
                height: auto;
                width: 86px;
                position: absolute;
                right: -30px;
                opacity: .5;
                bottom: 0;
                transition: all .3s linear;
            }

            h4 {
                font-size: 23px;
            }

            h6 {
                color: #b5b5b5;
                font-size: 14px;
                font-weight: 300;
            }
        }

        .trust_wallet:hover {
            background-color: #3271b5;
        }

        .metaMask:hover {
            background-color: #f78824;
        }
    }

}