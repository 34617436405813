.team-section {
  margin-top: 30px;

  .card_heading__df {
    border: none;
    overflow: hidden;
    border-radius: 10px;
    font-family: $font_heading;
    background: var(--sidebar-bg);
    box-shadow: var(--kt_card-shadow);
    color: var(--txt-black);
    padding: 15px 20px;
    margin-bottom: 15px;

    .accordion {
      background-color: transparent;

      .accordion-item {
        background-color: transparent;
        border-color: var(--border-color) !important;

        .accordion-header {
          button {
            background-color: transparent;
            color: var(--txt-white);
            box-shadow: unset !important;
            border-bottom: 1px solid var(--border-color);

            &::after {
              color: var(--sky-blue);
              background-image: var(--arrow-img);
              transform: scale(0.7) rotate(0);
              transition: all 0.3s linear;
            }

          }

          button[aria-expanded="false"] {
            border-color: transparent;
            transition: all 0.3s linear;
          }

          button[aria-expanded="true"] {

            &::after {
              transform: scale(0.9) rotate(180deg);
            }
          }
        }

        .accordion-body {
          color: var(--txt-white);

          p {

            svg {
              margin-right: 10px;
              font-size: 18px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }


  .team-outer {
    padding: 20px;
    overflow: hidden;
    font-family: $font_heading;
    background: var(--card);
    color: var(--txt-white);
    border: 1px solid var(--card-border);
    border-radius: var(--card-radius);

    .team_inner_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: var(--card-in);
      border-radius: var(--card-radius);
      padding: 15px 20px;

      @media($breakpoint_MD) {
        flex-direction: column;
        align-items: flex-start;
      }


      .hg2333 {
        margin-bottom: 0;

        @media($breakpoint_MD) {
          display: none;
        }
      }

      .serch-form {
        display: flex;
        align-items: center;
        gap: 10px;

        select,
        input {
          background-color: transparent;
          border: 1px solid var(--card-border);
          color: var(--txt-white);
          box-shadow: unset !important;
          outline: unset !important;
          padding: 8px 10px;
          border-radius: 10px;
          width: 100%;

          &::placeholder{
            color: var(--txt-white);
            opacity: 0.8;
          }
        }
      }

    }

    @include table_styles();

  }

  .heading__team_tree {
    background-color: var(--bodybg);
    padding: 15px 0;
    text-align: center;
    border-radius: 10px;
    color: var(--txt-white);
    margin: 20px 130px;

    @media ($breakpoint_SM) {
      margin: 20px 10px;
    }

    h4 {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .tree__sec {

    .main-tree {
      background-color: transparent;
      border: 0 !important;
    }
  }

  .team__sec {
    overflow: hidden;

    .card-body {
      overflow: scroll;

    }

    .tree {
      $color: var(--txt-white);
      $border-color: #959595;
      $border-weight: 1px;
      $margin: 15px;
      $img_width: 50px;

      width: 100% * 1.4;
      margin: $margin * 1.5;
      padding: 0;

      @media ($breakpoint_XL) {
        width: 1200px;

      }

      @media ($breakpoint_LG) {
        width: 1200px;

      }

      @media ($breakpoint_SM) {
        width: 1300px;

      }


      .admin {

        .admin-img {
          display: flex;
          align-items: center;
          justify-content: center;

          .img-sec {
            width: $img_width;
            border: 1px solid var(--primary-color);
            border-radius: 50%;
            overflow: hidden;
          }
        }

        p {
          font-size: 14px;
          text-align: center;



        }
      }


      .user-main-sec {
        position: relative;
        transition: all 0.4s linear;

        &:hover {

          .dropdown-sec {
            display: block;

          }
        }



        .user {


          .user-img {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;

            img {
              width: $img_width;
              margin: auto;
              border: 1px solid var(--primary-color);
              border-radius: 50%;
              overflow: hidden;
            }


          }

          p {
            text-align: center;
            font-size: 14px;
            margin: 10px 0;
          }
        }

        .user__name {
          p {
            text-align: center;
          }
        }


        .show__details {
          position: absolute;
          top: 0;
          right: 0;
          width: 44px;
          margin: 0 !important;
          padding: 10px;
          cursor: pointer;
          z-index: 1;
          color: #00000091;
          display: none !important;

          svg {
            background-color: #eeeeee;
            border-radius: 50%;
          }

          &::after {
            content: "";
            position: absolute;
            top: 54%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            width: 30%;
            height: 30%;
            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0px -1px 0 10px var(--primary-color);
            z-index: -1;
            animation: njidsfdsf 3s linear infinite;
          }

          @keyframes njidsfdsf {
            0% {
              transform: translate(-50%, -50%) scale(0);
            }

            70% {
              opacity: 0.7;
            }

            100% {
              transform: translate(-50%, -50%) scale(0.9);
              opacity: 0.3;
            }

          }
        }


        .cads {
          padding: 0 !important;
          margin: 0 !important;
          height: 100%;

          p {
            font-size: 12px;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {

              &:last-child {
                text-align: right;
              }
            }
          }
        }

        .dropdown-sec {
          position: absolute;
          top: 0;
          left: 0;
          width: 200px;
          height: inherit;
          background-color: #fff;
          box-shadow: 0px 0px 10px 10px #ddd;
          padding: 10px;
          border-radius: 10px;
          transition: all 0.4s linear;
          z-index: 11;
          display: none;

          p {
            font-size: 13px;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

      }

      &:not(:empty):before,
      &:not(:empty):after,
      ul:not(:empty):before,
      ul:not(:empty):after,
      li:not(:empty):before,
      li:not(:empty):after {
        display: block;
        position: absolute;
        content: "";
      }

      ul,
      li {
        position: relative;
        margin: 0;
        padding: 0;
      }

      li {
        list-style: none;
      }

      li>div {
        background-color: #2b2f32;
        border: 1px solid transparent;
        color: #ffffffd5;
        padding: 5px 10px;
        border-radius: 20px;
        display: inline-block;
        width: 170px;
        transition: all 0.3s linear;

        &:hover{
          border-color: var(--card-border);
        }
      }

      &.cascade {
        li {
          margin-left: 2 * $margin;

          div {
            margin-top: $margin;
          }

          &:before {
            border-left: $border-weight solid $border-color;
            height: 100%;
            width: 0;
            top: 0;
            left: -$margin;
          }

          &:after {
            border-top: $border-weight solid $border-color;
            width: $margin;
            left: -$margin;
            top: $margin * 2;
          }

          &:last-child:before {
            height: $margin * 2;
            top: 0;
          }
        }

        &>li {

          &:first-child:before {
            top: $margin * 2;
          }

          &:only-child {
            margin-left: 0;
          }

          &:only-child:before,
          &:only-child:after {
            content: none;
          }
        }
      }

      &.horizontal {
        li {
          display: flex;
          align-items: center;
          margin-left: $margin * 2;

          div {
            margin: $margin/2 0;
          }

          &:before {
            border-left: $border-weight solid $border-color;
            height: 100%;
            width: 0;
            top: 0;
            left: -$margin;
          }

          &:first-child:before {
            height: 50%;
            top: 50%;
          }

          &:last-child:before {
            height: 50%;
            bottom: 50%;
            top: auto;
          }

          &:after,
          ul:after {
            border-top: $border-weight solid $border-color;
            height: 0;
            width: $margin;
            top: 50%;
            left: -$margin;
          }

          &:only-child:before {
            content: none;
          }

          ul:after {
            left: 0;
          }
        }

        &>li {
          &:only-child {
            margin-left: 0;
          }

          &:only-child:before,
          &:only-child:after {
            content: none;
          }
        }
      }

      &.vertical {

        ul {
          display: flex;
          justify-content: center;
        }

        li {
          display: flex;
          flex-direction: column;
          align-items: center;

          div {
            margin: $margin $margin/2;
          }

          &:before {
            border-left: $border-weight solid $border-color;
            height: $margin;
            width: 0;
            top: 0;
          }

          &:after {
            border-top: $border-weight solid $border-color;
            height: 0;
            width: 100%;
          }

          &:first-child:after {
            border-top: $border-weight solid $border-color;
            height: 0;
            width: 50%;
            left: 50%;
          }

          &:last-child:after {
            border-top: $border-weight solid $border-color;
            height: 0;
            width: 50%;
            right: 50%;
          }

          &:only-child:after {
            content: none;
          }

          ul:before {
            border-left: $border-weight solid $border-color;
            height: $margin;
            width: 0;
            top: -$margin;
          }
        }

        &>li {

          &:only-child:before,
          &:only-child:after {
            content: none;
          }
        }
      }

      %subcascade {
        flex-direction: column;
        align-items: start;
        padding: 0 $margin;

        &:before {
          left: 2*$margin;
        }

        &:after {
          left: 0;
        }

        &:first-child:after {
          left: 2*$margin;
          width: 100%;
        }

        &:last-child:after {
          left: 0;
          width: 2*$margin;
        }

        ul,
        li {
          display: block;
        }

        ul:before,
        ul:after,
        li:before,
        li:after {
          border: none;
        }

        div {
          margin: 0;
          margin-top: $margin;
        }

        li {
          margin-left: 2 * $margin;

          &:before {
            border-left: $border-weight solid $border-color;
            height: 100%;
            width: 0;
            top: 0;
            left: -$margin;
          }

          &:after {
            border-top: $border-weight solid $border-color;
            width: $margin;
            height: 0;
            left: -$margin;
            top: $margin * 2;
            content: "";
          }

          &:last-child:before {
            height: $margin * 2;
            top: 0;
          }
        }
      }

      &.vertical .cascade {
        @extend %subcascade;
      }

      &.vertical.cascade-1 {
        &>li {
          @extend %subcascade;
        }
      }

      &.vertical.cascade-2 {
        &>li>ul>li {
          @extend %subcascade;
        }
      }

      &.vertical.cascade-3 {
        &>li>ul>li>ul>li {
          @extend %subcascade;
        }
      }

      &.vertical.cascade-4 {
        &>li>ul>li>ul>li>ul>li {
          @extend %subcascade;
        }
      }
    }
  }

  .soon_s {
    overflow: hidden;
    user-select: none;

    img {
      object-fit: contain;
      height: 400px;
      transform: scale(1.4);
      user-select: none;

      @media($breakpoint_LG) {
        transform: scale(1);
      }
    }
  }
}

// 