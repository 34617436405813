@import './homeheader.scss';

body {
      background-color: #191c1f;
      // background-color: #2b2f32;

}

.__home-yt {
      background-color: #fff !important;
}


.container-index {
      max-width: 1300px;
      width: 100%;
      margin: auto;
      padding: 0 16px;

      @media(max-width : 360px) {
            padding: 0px;
      }
}


.__container-ax {
      max-width: 1200px;
      width: 100%;
      margin: auto;
      padding: 0 20px;
}

.__heading_zs {
      text-align: center;
      padding: 0 150px;
      margin-bottom: 30px;

      @media ($breakpoint_MD) {
            padding: 0;
      }

      h1 {
            font-weight: 600;
            text-align: center;
            color: var(--zs-totiya);
            font-weight: 600;

      }

      p {
            margin-bottom: 0;
      }
}

.popover {
      background-color: #171717;
      font-family: $font_heading;
      color: var(--txt-white);

      .popover-header {
            background-color: transparent;
      }

      .popover-body {
            color: var(--txt-white) !important;

            .submit-btn {
                  display: inline-block;
                  margin-left: auto;
                  margin-top: 10px;
                  color: var(--txt-white);
                  background-color: var(--primary-color);
                  padding: 10px 20px;
                  border-radius: 30px;
                  text-decoration: none;
            }
      }
}

* {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
}

:root {
      --zs-banner: #1f1d0c;
      --zs-totiya: #028144;
}




.__g-banner {
      height: 85vh;
      width: 100%;
      background-image: linear-gradient(180deg, #fff 0%, #fff 4.13%, #fff 13.26%, #fff 19.96%, #fff 28.34%, #fff 35.49%, #f5e0c2 45.7%, #f1ce9d 59.55%, #e8b061 68.69%, #eaa561 76.3%, #DC924B 84.52%, #B5B9B8 100%);
      font-family: $font_heading;
      position: relative;
      z-index: 2;
      overflow-y: hidden;
      border-bottom: 5px solid var(--zs-banner);

      @media ($breakpoint_MD) {
            height: 600px;
      }


      &::after {
            content: "";
            position: absolute;
            bottom: -29px;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(../new-theme/tree-background.png);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            z-index: -1;
            opacity: 0.8;
            background-attachment: fixed;

      }

      &::before {
            content: "";
            position: absolute;
            bottom: -29px;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(../new-theme/tree-bottom.png);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            z-index: -1;
            opacity: 0.8;

      }


      .__ga-outer {
            height: 100%;
            width: 100%;

            position: relative;
            z-index: 1;



      }





      .__center {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding: 0px 100px 0;
            backdrop-filter: blur(3px);

            @media ($breakpoint_MD) {
                  padding: 0px 15px 0;
            }

            h6 {
                  font-size: 22px;
                  margin-bottom: 0;
                  line-height: 0.9;
                  color: var(--zs-totiya);
                  text-shadow: 1px 1px 1px var(--txt-white);
            }


            h1 {
                  font-size: 8vw;
                  font-weight: 600;
                  text-shadow: 1px 1px 1px var(--txt-white);
                  font-family: $font_heading;

                  @media ($breakpoint_SM) {
                        font-size: 10vw;
                  }
            }

            p {
                  font-size: 22px;
            }

            .btn {
                  background-color: var(--zs-banner);
                  display: inline-block;
                  padding: 10px 80px;
                  border-radius: 30px;
                  color: var(--txt-white);
                  border: 1px solid transparent;
                  transition: all 0.3s linear;

                  &:hover {
                        background-color: transparent;
                        border-color: var(--zs-banner);
                        color: var(--zs-banner);
                  }
            }

            .input_field {
                  border-radius: 60px;
                  background-color: var(--zs-banner);
                  width: 50%;
                  margin: auto;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 10px 10px 10px 15px;
                  margin-top: 22px;

                  @media ($breakpoint_LG) {
                        width: 80%;
                  }

                  input {
                        padding: 5px;
                        font-size: 18px;
                        background-color: transparent;
                        border: none;
                        color: var(--txt-white);
                        width: 100%;
                        font-size: 15px;

                        &:focus {
                              box-shadow: none;
                              outline: none;
                        }
                  }

                  a {
                        padding: 6px 16px;
                        background: var(--primary-color);
                        border-radius: 25px;
                        text-decoration: none;
                        color: black;
                        white-space: nowrap;
                        background-color: #c98a42;

                        @media ($breakpoint_SM) {
                              padding: 6px 10px;
                        }
                  }
            }
      }
}



.__about-sec {
      font-family: $font_heading;
      padding: 130px 0;
      position: relative;

      .__leafce {
            position: absolute;
            top: 7%;
            left: 30%;

            img {
                  width: 200px;
                  object-fit: contain;
            }
      }


      .__center-about {
            max-width: 800px;
            width: 100%;
            margin: auto;
            padding: 30px 30px 40px;
            text-align: center;


            .__imgs {
                  height: 90px;
                  margin: 0 auto 20px;

                  img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                  }
            }

            p {
                  opacity: 0.9;
                  font-size: 20px;
            }

            h3 {
                  font-weight: 600;
                  font-size: 30px;
            }


      }


      .creative-card {
            border-radius: 40px;
            inset: 0;
            background-color: var(--zs-banner);
            backdrop-filter: blur(3px);
            color: var(--txt-white);
            padding: 30px;
            height: 100%;

            .__icon {
                  background-color: var(--primary-color);
                  padding: 10px;
                  border-radius: 10px;
                  width: 60px;
                  height: 60px;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  margin-bottom: 20px;

                  p {
                        margin-bottom: 0;
                        opacity: 1 !important;
                  }
            }

            p {
                  opacity: 0.7;
            }
      }

}

.__focus {
      padding: 100px 0 50px;
      background-color: rgb(238, 237, 237);
      font-family: $font_heading;
      color: var(--txt-black);
      position: relative;
      z-index: 1;

      &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(../new-theme/bg-overly.png);
            z-index: -1;
            background-position: center;
            background-size: cover;
      }

      .__leafce {
            position: absolute;
            top: 20%;

            img {
                  width: 200px;
                  object-fit: contain;
            }
      }

      .__leafce2 {
            position: absolute;
            top: 10%;
            right: 20%;
            transform: rotate(90deg);

            img {
                  width: 150px;
                  object-fit: contain;
            }
      }

      .heading {
            text-align: center;
            margin-bottom: 40px;
            color: var(--txt-black);


            h2 {
                  margin-bottom: 5px;
                  font-weight: 600;
            }

      }


      .__img {
            // display: flex;
            // align-items: center;
            // justify-content: center;
            position: relative;
            z-index: 1;
            height: 500px;
            width: 100%;

            img {
                  width: 68%;
                  height: 270px;
                  border-radius: 40px;
                  overflow: hidden;
                  object-fit: cover;
            }


            .__1st {
                  position: relative;
                  top: 180px;

            }

            .__2sd {
                  position: absolute;
                  z-index: 1;
                  bottom: 0;
                  right: 20px;
            }
      }

      .__txt-sec {

            .accordion {
                  background-color: transparent;

                  .accordion-item {
                        background-color: transparent;
                        border: 0;
                        margin-bottom: 20px;
                  }

                  .accordion-button {
                        box-shadow: unset;
                        border-radius: 20px !important;
                        // background-color: var(--zs-banner);
                        color: var(--txt-black);
                        border: 1px solid var(--zs-banner);
                        background-color: transparent;
                  }

                  .accordion-button:not(.collapsed) {
                        background-color: var(--zs-banner) !important;
                        color: var(--txt-white) !important;

                        &::after {
                              background-image: url(../Image/down_2.png);
                        }
                  }
            }
      }
}


.__howit {
      padding: 100px 0;
      font-family: $font_heading;
      transition: all 0.3s linear;
      position: relative;
      z-index: 1;

      // &::after{
      //       content: "";
      //       position: absolute;
      //       top: 0;
      //       right: 0;
      //       height: 100%;
      //       width: 70%;
      //       background-image: url(../new-theme/crypto-bg.png);
      //       background-repeat: no-repeat;
      //       background-size: cover;
      //       background-position: top;
      //       z-index: -1;
      //       opacity: 0.1;
      // }


      .__howit-1{
            position: absolute;
            bottom: 0%;
            transform: rotate(290deg);

            img {
                  width: 150px;
                  object-fit: contain;
            }
      }

      .__howit-2{
            position: absolute;
            top: 15%;
            right: 20%;
            transform: rotate(90deg);

            img {
                  width: 150px;
                  object-fit: contain;
            }
      }


      .row {
            row-gap: 20px;
      }


      .__heading-howit {
            text-align: center;
            margin-bottom: 50px;

            h2 {
                  margin-bottom: 20px;
                  color: var(--zs-totiya);
                  font-weight: 600;
            }

            h6 {
                  margin-bottom: 0;
                  opacity: 0.8;
                  padding: 0 100px;

                  @media ($breakpoint_MD) {
                        padding: 0;
                  }
            }
      }

      .workit {
            margin-bottom: 20px;
            padding: 20px;
            border: 1px solid var(--zs-banner);
            position: relative;
            z-index: 1;

            &::after {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
            }


            &:last-child {
                  margin-bottom: 0;
            }

            h4 {
                  color: var(--zs-totiya);
                  font-weight: 600;
            }
      }

}

.Tokenomics {
      padding: 100px 0;
      font-family: $font_heading;
      position: relative;
      z-index: 1;

      &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(../new-theme/bg-overly.png);
            z-index: -1;
            background-position: center;
            background-size: cover;
      }

      &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(../new-theme/token-bg.png);
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 1;
            opacity: 0.2;

      }





      .__outer-tx {
            height: 700px;
            width: 100%;
            background-image: url(../new-theme/Bitmap2.0.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 70% 110%;
            position: relative;
            z-index: 1;
            text-transform: capitalize;

            --tk-position: absolute;
            transform: scale(0.9);

            @media ($breakpoint_SM) {
                  transform: scale(1);
                  background-image: none;
                  height: auto;

            }


            .__outer-tx_tree {
                  display: none;

                  @media ($breakpoint_SM) {
                        display: block;
                  }

                  img {
                        width: 100%;
                        height: 100%;
                  }
            }

            h4 {
                  font-size: 18px;
                  margin-bottom: 0;
            }

            h5 {
                  border-bottom: 1px solid #cdcaca54;
                  padding-bottom: 10px;
                  font-size: 20px;

                  @media ($breakpoint_SM) {
                        padding-bottom: 0;
                        border-bottom: 0;
                        margin-bottom: 0;
                  }
            }

            .__left-top {
                  position: var(--tk-position);
                  top: 10.8%;
                  left: 10%;
                  background-color: var(--zs-totiya);
                  padding: 20px;
                  color: var(--txt-white);
                  text-align: center;
                  width: 200px;
                  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);

                  @media ($breakpoint_LG) {
                        left: 5%;
                  }

                  @media ($breakpoint_MD) {
                        left: 0;
                  }

                  @media ($breakpoint_SM) {
                        position: static;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        clip-path: none;
                        width: 100%;
                        background-color: transparent;
                        color: var(--txt-black);
                        transform: none;
                        background-color: #eeeeee;
                        margin-bottom: 10px;
                  }

            }

            .__left-center {
                  position: var(--tk-position);
                  top: 50%;
                  left: 10%;
                  width: 200px;
                  background-color: var(--zs-totiya);
                  transform: translate(30%, -31%);
                  padding: 20px;
                  color: var(--txt-white);
                  text-align: center;
                  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);


                  @media ($breakpoint_LG) {
                        left: 5%;
                  }

                  @media ($breakpoint_MD) {
                        left: 0;
                        transform: translate(10%, -31%);
                  }

                  @media ($breakpoint_SM) {
                        position: static;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        clip-path: none;
                        width: 100%;
                        background-color: transparent;
                        color: var(--txt-black);
                        transform: none;
                        background-color: #eeeeee;
                        margin-bottom: 10px;

                  }
            }

            .__left-bottom {
                  position: var(--tk-position);
                  bottom: 0;
                  left: 10%;
                  width: 200px;
                  background-color: var(--zs-totiya);
                  padding: 20px;
                  color: var(--txt-white);
                  text-align: center;
                  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
                  transform: translate(-13%, -47%);

                  @media ($breakpoint_LG) {
                        left: 5%;
                  }

                  @media ($breakpoint_MD) {
                        left: 5%;
                  }

                  @media ($breakpoint_SM) {
                        position: static;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        clip-path: none;
                        width: 100%;
                        background-color: transparent;
                        color: var(--txt-black);
                        transform: none;
                        background-color: #eeeeee;
                        margin-bottom: 10px;

                  }
            }

            .__right-top {
                  position: var(--tk-position);
                  top: 10.8%;
                  right: 10%;
                  width: 200px;
                  background-color: var(--zs-totiya);
                  padding: 20px;
                  color: var(--txt-white);
                  text-align: center;
                  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
                  transform: translate(10%, 3%);


                  @media ($breakpoint_LG) {
                        right: 5%;
                  }

                  @media ($breakpoint_SM) {
                        position: static;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        clip-path: none;
                        width: 100%;
                        background-color: transparent;
                        color: var(--txt-black);
                        transform: none;
                        background-color: #eeeeee;
                        margin-bottom: 10px;

                  }

            }

            .__right-center {
                  position: var(--tk-position);
                  top: 50%;
                  right: 10%;
                  width: 200px;
                  background-color: var(--zs-totiya);
                  padding: 20px;
                  color: var(--txt-white);
                  text-align: center;
                  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
                  transform: translate(-40%, -30%);

                  @media ($breakpoint_LG) {
                        right: 5%;
                        transform: translate(10%, -31%);
                  }

                  @media ($breakpoint_SM) {
                        position: static;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        clip-path: none;
                        width: 100%;
                        background-color: transparent;
                        color: var(--txt-black);
                        transform: none;
                        background-color: #eeeeee;
                        margin-bottom: 10px;

                  }


            }

            .__right-bottom {
                  position: var(--tk-position);
                  bottom: 0;
                  right: 10%;
                  width: 200px;
                  background-color: var(--zs-totiya);
                  padding: 20px;
                  color: var(--txt-white);
                  text-align: center;
                  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
                  transform: translate(10%, -51%);

                  @media ($breakpoint_LG) {
                        right: 5%;
                        // transform: translate(10%, -31%);
                  }

                  @media ($breakpoint_SM) {
                        position: static;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        clip-path: none;
                        width: 100%;
                        background-color: transparent;
                        color: var(--txt-black);
                        transform: none;
                        background-color: #eeeeee;

                  }
            }
      }


}


.Tokenomics-none {
      margin-top: -100px;

      &::after {
            display: none;
      }
}

.roadmap-section {
      width: 100%;
      padding: 100px 50px;
      overflow: hidden;
      font-family: $font_heading;
      position: relative;
      z-index: 1;
      max-width: 1600px;
      width: 100%;
      margin: auto;

      @media ($breakpoint_SM) {
            padding: 100px 15px;
      }

      .__roadmpa-animatoin {
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, 0%);
            z-index: -1;

            img {
                  width: 200px;
                  object-fit: contain;
            }
      }

      .__heading_zs {
            margin-bottom: 20px;
      }

      .__inout {
            overflow-y: hidden;
            overflow-x: scroll;
            padding: 10px 20px 40px 100px;

            @media ($breakpoint_LG) {
                  padding-left: 0px;
            }


      }

      .__inout::-webkit-scrollbar{
            height: 10px;
            background-color: transparent;
      }

      .__inout::-webkit-scrollbar-thumb{
            background-color: #d5d5d5;
            border-radius: 20px;
      }


      .__roadmaop-in {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            height: 580px;
            margin-top: 80px;
            width: calc(100% * 3.5);


            @media ($breakpoint_XL) {
                  width: calc(100% * 3.9);
            }

            @media ($breakpoint_LG) {
                  width: calc(100% * 5);
            }

            @media ($breakpoint_MD) {
                  width: calc(100% * 7);
                  height: 500px;
            }

            @media ($breakpoint_SM) {
                  width: calc(100% * 8);
            }

            @media (max-width: 400px) {
                  width: calc(100% * 12);
            }



            .__f1 {
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: flex-end;
                  gap: 20px;
                  position: relative;
                  z-index: 1;
                  width: 300px;


                  &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 20%;
                        height: 60%;
                        width: 30px;
                        background-color: var(--zs-totiya);
                        transform: translate(41px, 40%) rotate(46deg);
                        z-index: -1;
                        border-radius: 50px;

                        @media ($breakpoint_SM) {
                              right: 20%;
                              height: 57%;
                              background-color: var(--zs-totiya);
                              transform: translate(203%, 32%) rotate(49deg);
                        }
                  }


                  &:nth-child(even) {
                        flex-direction: column-reverse;

                        &::after {
                              transform: translate(260%, 31%) rotate(-50deg);

                              @media ($breakpoint_SM){
                                    transform: translate(184%, 27%) rotate(-54deg);
                              }
                        }
                  }

                  &:last-child {
                        &::after {
                              display: none;
                        }
                  }

                  .__icon-roadmap {
                        width: 120px;
                        height: 120px;
                        background-color: #fff;
                        border: 10px solid var(--zs-totiya);
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        z-index: 1;
                        box-shadow: inset -2px 1px 0px 1px #ffffff, inset -16px -8px 0px 1px #86eaba;
                        outline: 15px solid #fff;


                        @media ($breakpoint_MD) {
                              height: 90px;
                              width: 90px;
                              outline-width: 14px;
                        }

                        svg {
                              font-size: 2.5rem;
                              color: var(--zs-totiya);

                              @media ($breakpoint_MD) {
                                    font-size: 2rem;
                              }
                        }
                  }

                  .__descreptions {
                        p {
                              font-size: 18px;
                        }

                        .title {
                              margin-bottom: 0;
                              padding-bottom: 5px;
                              border-bottom: 3px solid var(--zs-totiya);
                        }

                        .event-date {
                              margin-bottom: 0;
                              font-size: 15px;
                        }
                  }
            }

      }

      .see-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;


            .btn {
                  padding: 10px 40px;
                  background-color: var(--zs-totiya);
                  border-radius: 30px;
                  color: var(--txt-white);
                  text-align: center;
                  font-size: 17px;
                  transition: all 0.3s linear;

                  &:hover {
                        background-color: transparent;
                        border-color: var(--zs-totiya);
                        color: var(--zs-totiya);
                  }
            }
      }
}


.move-section {
      position: absolute;
      left: 20%;
      bottom: 0;

      img {
            width: 140px;
            object-fit: contain;
      }
}

.move-section-right {
      position: absolute;
      right: 20%;
      top: -10%;

      img {
            width: 140px;
            object-fit: contain;
      }
}