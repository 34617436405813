.footer-main {
    height: auto;
    min-height: auto;
    padding-top: 0;
}

.footer {
    padding: 20px;
    position: relative;
    background: var(--card);
    border: 1px solid var(--card-border);
    border-radius: var(--card-radius);
    font-family: $font_heading;
    margin-bottom: 10px;

    p {
        margin-bottom: 0px;
        font-size: 15px;
        color: var(--txt-white);
        opacity: 0.8;

        @media($breakpoint_ESM) {
            font-size: 15px;
        }

        a {
            color: var(--primary-color);
            text-decoration: none;

        }
    }
}





.Home_footer {
    background-color: black;
    color: white;
    padding-top: 80px;
    position: relative;
    z-index: 1;
    overflow: hidden;


    .__overly_footer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        div{
            height: 800px;
        }
    }

    .heading {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        gap: 18px;

        @media ($breakpoint_SM) {
            flex-direction: column;
            text-align: center;
        }

        h1 {
            color: transparent;
            background: var(--text_color);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 56px;
            font-weight: 700;
            margin: 0;
            font-family: $font_heading;
            line-height: 44px;
        }

        span {
            font-size: 13px;
            font-weight: 300;
            line-height: 14px;
            font-family: $font_heading;
        }

        img {
            height: 120px;
            width: auto;

            @media ($breakpoint_SM) {
                margin-bottom: 20px;
            }
        }

        p {
            font-weight: 200;
            font-family: $font_pragph;
            margin: 0;
            text-align: left;

            @media ($breakpoint_SM) {
                text-align: center;
            }
        }
    }

    .input_side {

        @media ($breakpoint_SM) {
            margin-top: 30px;
        }


        h3 {
            font-size: 24px;
            color: var(--primary-color);
            text-align: end;
            border-bottom: 1px solid #e8cc5670;
            padding-bottom: 6px;
            padding-right: 18px;
            margin-bottom: 16px;

            @media ($breakpoint_SM) {
                display: none;
            }
        }


        .input_field {
            border-radius: 60px;
            background-color: #1f1d0c;
            border: 1px solid #b49b3c36;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 7px 5px 18px;
            margin-top: 22px;

            @media ($breakpoint_LG) {
                width: 100%;
            }

            input {
                padding: 8px 5px;
                font-size: 18px;
                background-color: transparent;
                border: none;
                color: white;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }

                @media ($breakpoint_ESM) {

                    width: 64%;
                }
            }

            a {
                padding: 9px 16px;
                background: var(--primary-color);
                border-radius: 25px;
                text-decoration: none;
                color: black;

                @media ($breakpoint_450) {
                    padding: 12px 10px;
                    font-size: 11px;
                }
            }
        }
    }

    // .links {
    //     margin: 30px 0;

    //     h3 {
    //         color: var(--primary-color);
    //         text-align: center;
    //         border-bottom: 1px solid #e8cc5670;
    //         padding-bottom: 6px;
    //         margin-bottom: 16px;
    //     }

    //     ul {
    //         margin-bottom: 30px;
    //         padding: 0;
    //         text-align: center;

    //         @media ($breakpoint_ESM) {
    //             gap: 18px;
    //             margin: 0;
    //         }

    //         li {
    //             list-style-type: none;
    //             margin-bottom: 8px;

    //             .page_links {
    //                 text-decoration: none;
    //                 color: var(--primary-color);
    //                 padding: 7px 12px;
    //                 position: relative;
    //                 padding-bottom: 0;
    //                 border: 1px solid transparent;
    //                 font-size: 14px;

    //                 @media ($breakpoint_ESM) {
    //                     padding: 7px 6px;
    //                     font-size: 13px;
    //                 }

    //                 &::after {
    //                     content: "";
    //                     position: absolute;
    //                     bottom: -5px;
    //                     left: 40px;
    //                     width: 0;
    //                     height: 1px;
    //                     border-radius: 10px;
    //                     background-color: var(--primary-color);
    //                     transition: all .2s linear;
    //                 }

    //                 &:hover {

    //                     &::after {
    //                         width: 100%;
    //                         left: 0;
    //                     }
    //                 }
    //             }

    //         }
    //     }


    // }

    .links_bottom {
        margin-top: 10px;

        ul {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 28px;
            padding: 0;



            @media ($breakpoint_SM) {
                justify-content: center;
                gap: 18px;
                margin: 0;
            }

            li {
                list-style-type: none;

                .page_links {
                    text-decoration: none;
                    color: var(--primary-color);
                    padding: 7px 12px;
                    position: relative;
                    padding-bottom: 0;
                    border: 1px solid transparent;

                    @media ($breakpoint_ESM) {
                        padding: 7px 6px;
                        font-size: 13px;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: -5px;
                        left: 40px;
                        width: 0;
                        height: 1px;
                        border-radius: 10px;
                        background-color: var(--primary-color);
                        transition: all .2s linear;
                    }

                    &:hover {

                        &::after {
                            width: 100%;
                            left: 0;
                        }
                    }
                }

                .social_media {

                    svg {
                        font-size: 38px;
                        padding: 7px;
                        border-radius: 50%;
                        text-decoration: none;
                        color: var(--primary-color);
                        transition: all .3s linear;
                        border: 1px solid transparent;
                    }

                    &:hover {

                        svg {
                            background: #e8cc5633;
                            border: 1px solid #e8cc5675;
                        }
                    }
                }

            }
        }


    }

    .bottom_ca {
        margin-top: 65px;
        padding: 20px 0;
        border-top: 1px solid #e8cc564f;

        .four-index-container {
            display: flex;
            align-items: center;
            justify-content: space-between;


            @media ($breakpoint_SM) {
                flex-direction: column-reverse;
            }

        }

        p {
            font-size: 13px;
            font-weight: 300;
            font-family: $font_pragph;
            margin: 0;

            @media ($breakpoint_SM) {
                margin-top: 30px;
            }
        }

        ul {
            margin-bottom: 0px;
            padding: 0;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 28px;

            @media ($breakpoint_ESM) {
                gap: 18px;
                margin: 0;
            }

            li {
                list-style-type: none;
                margin-bottom: 8px;

                .page_links {
                    text-decoration: none;
                    color: var(--primary-color);
                    padding: 7px 12px;
                    position: relative;
                    padding-bottom: 0;
                    border: 1px solid transparent;
                    font-size: 14px;

                    @media ($breakpoint_ESM) {
                        padding: 7px 6px;
                        font-size: 13px;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: -5px;
                        left: 40px;
                        width: 0;
                        height: 1px;
                        border-radius: 10px;
                        background-color: var(--primary-color);
                        transition: all .2s linear;
                    }

                    &:hover {

                        &::after {
                            width: 100%;
                            left: 0;
                        }
                    }
                }

            }
        }
    }
}