.Crypto_Assets_sec {
    .inner {
        color: white;

        @media ($breakpoint_SM) {
            text-align: center;
        }

        h1 {
            font-size: 70px;
            font-weight: 600;
            line-height: 68px;
            font-family: $font_heading;

            @media ($breakpoint_LG) {
                font-size: 48px;
            }

            @media ($breakpoint_MD) {
                font-size: 42px;
                line-height: 40px;
                margin-bottom: 20px;
            }

            @media ($breakpoint_SM) {
                font-size: 34px;
                line-height: 34px;
            }

            @media ($breakpoint_450) {
                font-size: 28px;
                line-height: 34px;
            }

            @media ($breakpoint_ESM) {
                font-size: 24px;
                line-height: 34px;
            }

            svg {
                font-size: 62px;
                color: var(--primary-color);
                margin-right: 20px;
            }

            span {
                color: transparent;
                background: var(--text_color);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        p {
            font-size: 18px;
            font-weight: 500;
            font-family: $font_pragph;

            @media ($breakpoint_450) {
                font-size: 16px;
                line-height: 34px;
            }

            @media ($breakpoint_ESM) {
                font-size: 14px;
                line-height: 24px;
            }
        }

        a {
            display: inline-block;
            padding: 8px 30px;
            background-color: var(--primary-color);
            border: 1px solid transparent;
            text-decoration: none;
            border-radius: var(--commonRadius);
            color: var(--txt-white);
            font-size: 14px;
            transition: all 0.3s linear;
            text-decoration: none;

            &:hover {
                background-color: transparent;
                border-color: var(--primary-color);
                color: var(--primary-color);
            }
        }
    }

    .swiper_sec {
        height: 100%;
        background: radial-gradient(50% 50% at 50% 50%, #e8cc5663 0%, #0eeaae00 100%);

        @media ($breakpoint_MD) {
            margin-top: 30px;
        }

        .swiper {
            width: 100%;
            height: 100%;

            .swiper-wrapper {

                .swiper-slide {
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transform: scale(.9);
                    filter: brightness(.2);

                    @media ($breakpoint_SM) {
                        filter: initial;
                        transform: unset;
                    }

                    .card {
                        padding: 18px;
                        background-color: black;
                        border-radius: var(--commonRadius);
                        border: none;
                        height: auto;
                        width: 100%;

                        @media ($breakpoint_SM) {
                            background-color: #e8cc561c;
                        }

                        .inner {
                            img {
                                height: 50px;
                                width: auto;
                                margin: auto;
                            }

                            h3 {
                                font-size: 22px;
                                font-weight: 500;
                                margin: 0;
                                margin-top: 10px;
                            }

                            p {
                                font-size: 14px;
                                font-weight: 300;
                            }

                            .button_div {
                                padding: 6px;
                                background: var(--text_color);
                                border-radius: 18px;

                                span {
                                    font-size: 14px;
                                    font-weight: 300;
                                }

                                p {
                                    font-size: 18px;
                                    font-weight: 600;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }

                .swiper-slide-next {
                    transform: scale(1.1);
                    filter: brightness(1);
                }

            }
        }


        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }


    }
}