.smart_trading_sec {
    position: relative;
    z-index: 1;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-image: url("../new_images/smart_trading.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        opacity: .4;
        z-index: -1;
    }

    .inner {
        padding: 40px 0;
        height: 550px;
        color: white;
        position: relative;
        z-index: 1;
        overflow: hidden;

        @media ($breakpoint_MD) {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
        }

        @media ($breakpoint_SM) {
            height: 500px;
        }

        @media ($breakpoint_450) {
            height: 434px;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 600px;
            height: 600px;
            background-image: url("../new_images/smart_trading_mobile.png");
            background-repeat: no-repeat;
            background-position: left center;
            background-size: contain;
            z-index: -1;

            @media ($breakpoint_MD) {
                top: 0;
                width: 540px;
                height: 390px;
                background-position: top;
            }

            @media ($breakpoint_SM) {
                width: 420px;
                height: 256px;
            }

            @media ($breakpoint_450) {
                width: 348px;
                height: 186px;
            }

            @media ($breakpoint_ESM) {
                width: 264px;
                height: 142px;
            }

        }

        .info {
            width: 44%;

            @media ($breakpoint_LG) {
                width: 70%;
            }

            @media ($breakpoint_SM) {
                width: 100%;
            }

        }

        p {
            font-size: 14px;
            font-weight: 300;
            margin: 0;
            color: var(--primary-color);

            span {
                margin-right: 8px;
            }
        }

        h1 {
            text-transform: capitalize;
            padding-bottom: 10px;
            font-weight: 700;
            margin: 0;
            font-family: $font_heading;
        }

        h5 {
            font-family: $font_pragph;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 30px;
        }

        a {
            display: inline-block;
            padding: 8px 30px;
            background-color: var(--primary-color);
            border: 1px solid transparent;
            text-decoration: none;
            border-radius: var(--commonRadius);
            color: var(--txt-white);
            font-size: 14px;
            transition: all 0.3s linear;
            text-decoration: none;

            &:hover {
                background-color: transparent;
                border-color: var(--primary-color);
                color: var(--primary-color);
            }
        }
    }
}

.smart_trading_feature_sec {
    padding-top: 80px;

    .inner {

        .heading {
            text-align: center;
            color: white;

            h1 {
                font-size: 78px;
                font-weight: 600;
                line-height: 68px;
                font-family: $font_heading;

                @media ($breakpoint_LG) {
                    font-size: 48px;
                }

                @media ($breakpoint_MD) {
                    font-size: 42px;
                    line-height: 40px;
                    margin-bottom: 20px;
                }

                @media ($breakpoint_SM) {
                    font-size: 34px;
                    line-height: 34px;
                }

                @media ($breakpoint_450) {
                    font-size: 28px;
                    line-height: 34px;
                }

                @media ($breakpoint_ESM) {
                    font-size: 24px;
                    line-height: 34px;
                }

                span {
                    color: transparent;
                    background: var(--text_color);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            p {
                font-size: 18px;
                font-weight: 500;
                font-family: $font_pragph;

                @media ($breakpoint_450) {
                    font-size: 16px;
                    line-height: 34px;
                }

                @media ($breakpoint_ESM) {
                    font-size: 14px;
                    line-height: 24px;
                }
            }

        }

        .features {
            color: white;
            margin-top: 20px;

            .inner_info {
                margin-bottom: 26px;

                @media ($breakpoint_MD) {
                    padding-left: 24px;
                }

                h3 {
                    font-size: 18px;
                    font-weight: 800;
                    position: relative;
                    font-family: $font_pragph;

                    svg {
                        position: absolute;
                        top: 0;
                        left: -28px;
                        color: var(--primary-color);
                        font-size: 22px;
                    }
                }

                p {
                    font-size: 13px;
                    font-weight: 300;
                    font-family: $font_pragph;

                    span {
                        font-weight: 600;
                        font-size: 15px;
                        border-bottom: 1px solid #e8cc56a3;
                    }
                }
            }
        }

        .feature_chart {
            height: 100%;
            width: 100%;
            background: radial-gradient(50% 50% at 50% 50%, #e8cc56 0%, rgb(14 234 174 / 0%) 100%);

            .left {
                margin-top: 60px;
                height: 500px;
                width: 100%;
                position: relative;
                z-index: 1;

                @media ($breakpoint_SM) {
                    margin-top: 0;
                }

                @media ($breakpoint_450) {
                    height: 424px;
                }

                @media ($breakpoint_ESM) {
                    height: 278px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    background-image: url("../new_images/trading_feature_laptop.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    z-index: -1;

                }

            }
        }
    }
}