.app-sidebar {
    --sidebar-width: 250px;
    position: fixed;
    top: 0;
    bottom: 100px;
    left: 0;
    color: #8a98ac;
    width: var(--sidebar-width);
    height: 100vh;
    z-index: 11;
    background-color: rgb(21, 21, 26);
    -webkit-transition: left 0.3s ease, width 0.3s ease;
    -o-transition: left 0.3s ease, width 0.3s ease;
    transition: left 0.3s ease, width 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 50px;
    display: none;





    @media($breakpoint_LG) {
        display: block;
        --sidebar-width: 270px;
        top: 0;
        bottom: 0;
        height: 100vh;
        left: -270px;
        border-radius: 0 !important;
        justify-content: center;
    }

    .sidbar-overly {
        display: none;
        width: var(--sidebar-width);
        -webkit-transition: left 0.3s ease, width 0.3s ease;
        -o-transition: left 0.3s ease, width 0.3s ease;
        transition: left 0.3s ease, width 0.3s ease;
    }


    .app-sidebar__logo {
        padding: 10px 15px;
        text-align: center;
        width: var(--sidebar-width);
        background: transparent;
        -webkit-transition: left 0.3s ease, width 0.3s ease;
        -o-transition: left 0.3s ease, width 0.3s ease;
        transition: left 0.3s ease, width 0.3s ease;
        background-color: #ffffff08;



        @media ($breakpoint_XL) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }


        .header-brand {
            text-decoration: none;
            color: var(--txt-white);
            font-family: $font_heading;
            width: 100%;

            .mode {
                display: flex;
                align-items: center;
                justify-content: space-between;

                span {
                    font-size: 22px;
                    margin-left: 10px;
                }

                .lang_button {
                    background-color: transparent;
                    border: none;
                    font-family: Montserrat, sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    color: var(--primary-color);
                }
            }
        }

        .close-btns {
            display: none;
            color: var(--txt-black);

            @media ($breakpoint_XL) {
                display: inline-block;
                background-color: transparent;
                border: 0;
                padding: 0;
                outline: 0;
                box-shadow: unset;
            }

        }

        .header-brand {

            @media ($breakpoint_XL) {
                .mode {
                    img {
                        margin-left: 17px;
                    }
                }

            }

            .header-brand-img {
                height: 60px;
                vertical-align: bottom;
                margin-right: 0;
                width: auto;
            }
        }

        .dark-logo {
            display: none;
        }
    }

    .app-sidebar3 {

        .sidebar {
            height: 100%;
            overflow: hidden;

            .sidebar_menu {
                margin-bottom: 0;
                padding: 14px 0;
                height: calc(90vh - 10px);
                overflow-y: scroll !important;
                overflow-x: hidden;
                -webkit-overflow-scrolling: touch;
                scroll-behavior: smooth;
                padding-bottom: 60px;

                @media ($breakpoint_MD) {
                    height: calc(80vh - 10px);
                    margin-top: 10px;

                }

                @media ($breakpoint_MD) {
                    height: calc(85vh - 10px);
                    margin-top: 10px;
                }


                .accordion {

                   
                    .accordion-item {
                        margin-bottom: 0;
                        border: none;
                        background: transparent;

                        &:last-child{

                            a:hover{
                                background-color: var(--bs-danger) !important;
                            }
                        }

                        .accordion-header {
                            .accordion-button {
                                padding: 0px;
                                background-color: transparent;
                                box-shadow: none;
                                --a__tag__padding: 15px 25px;
                                --a__tag__radius: 0;
                                transition: all .2s linear;
                                border-radius: var(--a__tag__radius);
                                position: relative;
                                z-index: 1;

                                &::before{
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    width: 5px;
                                    height: 100%;
                                    background-color: var(--primary-color);
                                    border-radius: 10px 0 0 10px;
                                    background-color: transparent;
                                }
                                


                                a,
                                span {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                    text-decoration: none;
                                    width: 100%;
                                    padding: var(--a__tag__padding);
                                    color: var(--txt-white);
                                    font-size: 14px;
                                    font-weight: 500;
                                    font-family: $font_heading;
                                    border-radius: var(--a__tag__radius);
                                   

                                    svg {
                                        font-size: 24px;
                                        color: #96a0af;
                                    }
                                }

                                .drop_link {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                    text-decoration: none;
                                    width: 100%;
                                    padding: var(--a__tag__padding);
                                    color: var(--txt-white);
                                    font-size: 14px;
                                }

                                &:hover {
                                    background-color: #82828c17;
                                }

                                &::after {
                                    position: absolute;
                                    top: 50%;
                                    right: 30px;
                                    color: var(--sky-blue);
                                    transform: scale(0.9) rotate(-90deg) translate(50%, 0%);
                                    background-image: url(../Icon/white-angle.png);
                                }
                            }
                            button[aria-expanded="true"]{

                                &::after{
                                    transform: scale(0.9) rotate(0deg) translate(50%, 0%) !important;
                                }
                            }


                        }

                        .only_button {

                            .accordion-button {
                                padding: 0px;

                                &::after {
                                    display: none;
                                }
                            }
                        }

                        .accordion-body {
                            padding: 0 10px 10px;

                            ul {
                                padding-left: 0px;
                                list-style: none;

                                li {
                                    margin: 5px 0px 5px 0px;

                                    a {
                                        text-decoration: none;
                                        background-color: transparent;
                                        width: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
                                        gap: 10px;
                                        padding: 10px 20px;
                                        color: #fff;
                                        font-size: 14px;
                                        border-radius: 4px;
                                        font-weight: 500;
                                        font-family: $font_heading;

                                        i{
                                            transition: all 0.3s linear;
                                            position: relative;
                                            top: 55%;
                                            left: 0;
                                            transform: translate(0 ,0) rotate(46deg);


                                            svg{
                                                font-size: 12px;
                                            }
                                        }

                                        &:hover {
                                            border-radius: 10px;
                                            color: var(--primary-color);

                                            i{
                                                transform: translate(0 ,0) rotate(-46deg);
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }

                    .active-Item{
                        background-color: #fad4431f;

                        button{

                            &::before{
                                background-color: var(--primary-color) !important;
                            }

                            &::after{
                                transform: scale(0.9) rotate(0deg) translate(50%, -30%);
                            }

                            span{
                                background-color: #82828c17 ;

                            }
                           
                            a{
                                color: var(--primary-color);
    
                                svg {
                                    color: var(--primary-color);
                                }
                            }
                        }
                        
                    }
                }
            }

            .sidebar_menu::-webkit-scrollbar {
                width: 0;
            }

            .sidebar_menu::-webkit-scrollbar-thumb {
                background-color: var(--primary-color);
            }
        }
    }


}


.show-sidebar {
    overflow: hidden;


    .sidbar-overly {
        display: block !important;
        background-color: #2b2f3236;
        position: fixed;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100vh;
        z-index: 10;
        backdrop-filter: blur(3px);
    }


    .app-sidebar {
        left: 0;
    }

    .pvt-header .header-area .__end .toggleButton{
        .--menu{
            display: none;
        }
        .--close{
            display: inline-block;
        }
    }
}